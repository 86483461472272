import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectionOptions = {
  order: [
    'path',
    'cookie',
    'navigator',
    'localStorage',
    'subdomain',
    'queryString',
    'htmlTag',
  ],
  lookupFromPathIndex: 0,
};

i18n
  // // load translation using xhr -> see /public/locales
  // // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // we init with resources
    detection: detectionOptions,
    resources: {
      es: {
        //ESPAÑOL, NO HACE FALTA INGRESAR DATOS DE TRADUCCION.
        translations: {
          'negocios-inmobiliarios-primer-parrafo_html':
            'Cazenave, representada por su socio Fernando Elizalde, se ha asociado con Fitz Gerald & Harte representada por su socio Eduardo Fitz Gerald, y Gómez Romero Propiedades  representada por su socio Augusto Gómez Romero formando ARGEN CAMPOS. El objetivo es potenciar nuestros servicios para la  compra y venta de campos, arrendamientos, tasaciones y divisiones de importantes establecimientos agropecuarios.',
        },
      },
      en: {
        translations: {
          //Nav EN
          Historia: 'About us',
          '¿Qué hacemos?': 'What we do',
          'Casa Tech': 'Tech Platform',
          Única: 'Única Platform',
          Invertí: 'Invest',
          Prensa: 'Press',
          Contacto: 'Contact us',
          'Tel +54 11 4763-5500': 'Phone No. +54 11 4763-5500',
          'Seleccioná idioma': 'Choose language',

          //HOME EN
          'El puente hacia': 'A bridge to',
          'el futuro del agro': 'the Future of Agribusiness',
          'Nuestro Objetivo': 'Our Goal',
          'Desarrollamos negocios en la cadena de la agroindustria, buscando promover el crecimiento económico y humano en un marco sustentable.':
            'We do business in the agroindustrial chain. We aim at fostering human and economic growth within a sustainable framework.',
          'Somos la empresa líder de Argentina de servicios del Agro, con más de 50 años de historia, adaptada a las ':
            'We are a leading agricultural service provider in Argentina with more than 50 years of experience adapted to ',
          'NUEVAS TECNOLOGíAS EXPONENCIALES': 'NEW EXPONENTIAL TECHNOLOGIES',
          'con ': 'and focused on achieving ',
          EXCELENCIA: 'EXCELLENCE',
          'Nuestra Historia': 'Our History',
          Innovación: 'Innovation',
          Confianza: 'Trust',
          'Trabajo en equipo': 'Teamwork',
          Excelencia: 'Excellence',
          'En Originación': 'In Origination',
          Fideicomisos: 'Trusts',
          'Fondos de Siembra': 'Sowing Funds',
          'HAS.': 'HECTARES',
          'Valor Producto Originado': 'Value of Originated Product',
          'Valor Producto Vendido': 'Value of Sold Product',
          'Nuestro equipo': 'Our team',
          Presidente: 'Chairman',
          'Director de Producción': 'Production Director',
          'Director de Gestión y Finanzas': 'Management & Finance Director',
          'Gerente de Administración y Finanzas':
            'Administration & Finance Manager',
          'Gerente de Comercialización y Logística':
            'Commercialization and Logistics Manager',
          'Gerente de Originación': 'Origination Manager',
          'Recursos Humanos': 'Human Resources',
          'Compliance Ambiental': 'Environmental Compliance',
          'Ing. en Producción Agropecuaria': 'Farming Production Engineer',
          'Ing.': 'Eng.',
          'Director y Presidente de Cazenave. Director de Gensus S.A. Responsable de Dirección de NUEVOS NEGOCIOS DE INNOVACIÓN.':
            'Cazenave Director and President. Director of Gensus S.A. Responsible for managing NEW INNOVATION BUSINESS.',
          'Ing. Agrónomo': 'Agricultural Engineer',
          'Director y Ex Presidente de Cazenave. Director de Gensus SA. Responsable del programa de ORIGINACIÓN.':
            'Cazenave Director and Ex-President. Director of Gensus SA. Responsible for the ORIGINATION program.',
          'Director y Ex Presidente de Cazenave. Ex Presidente de ASAGIR. Responsable del programa de SIEMBRAS.':
            'Cazenave’s Director and Ex-President. ASAGIR Ex-President. Responsible for the SOWING program.',
          'Director de Cazenave. Asesor de Negocios inmobiliarios.':
            'Cazenave Director. Real Estate Business Advisor.',
          'CEO de Cazenave': 'Cazenave CEO',
          'Alta Dirección Regional y Global; Managing Director en Fondos de inversión de Private Equity en toda la cadena de Agribusiness. Founder de Startups en proyectos greenfields con innovación tecnológica disruptiva.':
            'Top Regional and Worldwide Management; Managing Director of Private Equity Investment Funds throughout the Agribusiness chain. Startup Founder on greenfields projects based on disruptive technology innovation.',
          'Ing. en Producción Agropecuaria. M.Sc. en Agronegocios y Alimentos (2001)':
            'Farming Production Engineer. M.Sc. in Food and Agribusiness (2001)',
          'Dirección de los programas de Siembras.':
            'Management of Sowing Programs.',
          'Gerente de Administración y Finanzas de Cazenave y Asociados y de Gensus.':
            'Administration & Finance Manager at Cazenave y Asociados and at Gensus.',
          'Área de Comercialización y Logística.':
            'Commercialization and Logistics Area.',
          'Área de Originación. Cereales de invierno.':
            'Origination Area. Winter cereals.',
          'Lic. Comunicación Social': 'Bachelor in Social Communication',
          'Comunicación y Prensa de Cazenave.':
            'Responsible for Communication and Media at Cazenave.',
          'En Cazenave estamos en proceso de tener toda nuestros manejos productivos, registrados con trazabilidad, para ir camino a agricultura certificada. Con total cumplimiento de las buenas prácticas agrícolas, y cuidado del medioambiente.':
            'At Cazenave, we are in the path of causing our total productive management  to be registered with traceability, in order to obtain a certification on agriculture, in full compliance with the good agricultural practices and taking care of the environment.',

          //Historia EN
          'Nuestra historia': 'Our history',
          'Comenzamos con las siembras en el año 1978; más de cuarenta campañas y un eficiente trabajo en red nos permitieron crecer hacia otros esquemas de producción y exportar el modelo hacia otros países.':
            'We started sowing in 1978. After more than forty campaigns and an efficient networking, we were able to grow towards other production schemes and to export the model to other countries.',
          'Actualmente, producimos commodities y specialties bajo diferentes modalidades para otras compañías; para fondos/fideicomisos; a través de un esquema de siembras asociadas. Brindamos, también, un servicio integral de administración agropecuaria.':
            'We currently produce commodities and specialties under different methods: for other companies; for public trusts/funds; by means of an associated seeding scheme. We also render a comprehensive farming administration service.',
          'Fundación de Cazenave': 'Fundation of Cazenave',
          'Adopción de tecnología de Siembra Directa.':
            'Implementation of Direct Seeding technology.',
          'Primeras 400 has de producción en arrendamiento.':
            'First 400 leased production hectares.',
          'Girasol en Venezuela.': 'Sunflower in Venezuela.',
          'Programa de Girasol Alto Oleico con DOW.':
            'High Oleic Sunflower Oil Program with DOW.',
          '1er Fondo Agrícola con Aprobación de la CNV y Oferta Pública.':
            '1st Agricultural Fund approved by Argentina’s Securities and Exchange Commission (the CNV) and Public Offering',
          'Programa de Trigo Candeal y Trigo Pan de Calidad.':
            'Quality Wheat Bread and Durum Wheat Program.',
          'IFC (Banco Mundial) Inversión en FAID (Primer fondo Plurianual).':
            'IFC (World Bank) Investment in the Direct Investment Agricultural Fund (FAID) (First Pluriannual Fund)',
          'Utilización de Silo Bolsa en gran escala. Desarrollo de programa de Reciclado de Envases.':
            'Large-scale use of Silo Bags. Development of Container Recycling Program.',
          'Desarrollo y puesta en el mercado de Agrositio. Producción de Popcorn.':
            'Development and market positioning of Agrositio website. Popcorn production.',
          'Desarrollo y puesta en el mercado de Agrositio.':
            'Development and market positioning of Agrositio website.',
          'Producción de Popcorn.': 'Popcorn production.',
          'Programa de siembras asociadas. Creación de CM para Cobro de Regalías.':
            'Associated Seeding Program. Creation of CM for Royalty Collection purposes.',
          'Fideicomiso Arándanos en Tucumán. Proyecto Biodiesel / Proyecto Jatropha. Ante-proyecto de producción.':
            'Arándanos Trust in Tucumán. Biodiesel Project / Jatropha Project. Preliminary Production Project.',
          'Proyecto de Intersiembras. Creación de Patagonia Bioenergía. Proyecto Colza.':
            'Interseeding Project. Creation of Patagonia Bioenergía corporation. Colza Project.',
          'Proyecto de Intersiembras.': 'Interseeding Project.',
          'Creación de Patagonia Bioenergía.':
            'Creation of Patagonia Bioenergía corporation.',
          'Proyecto Colza.': 'Colza Project.',
          'Proyecto y Desarrollo de Producción en Colombia. Integración con Black River. Consultoría en Ucrania.':
            'Production Development and Project in Colombia. Integration with Black River. Consulting Services in Ukraine.',
          'Proyecto y Desarrollo de Producción en Colombia.':
            'Production Development and Project in Colombia.',
          'Integración con Black River.': 'Integration with Black River.',
          'Consultoría en Ucrania.': 'Consulting Services in Ukraine.',
          'Programa de producción para Sojitz Co.':
            'Production Program for Sojitz Co.',
          'Proyecto y Desarrollo de Producción en Angola. Consultoría en Paraguay.':
            'Production Development and Project in Angola. Consulting Services in Paraguay.',
          'Proyecto y Desarrollo de Producción en Angola.':
            'Production Development and Project in Angola.',
          'Consultoría en Paraguay.': 'Consulting Services in Paraguay.',
          'Proyecto de Producción en Brasil.': 'Production Project in Brazil.',
          'Desarrollo de área Inmobiliaria.': 'Real Estate Development.',
          'Desarrollo de Plataforma UNICA (Ex PUMA) en Asociación con CODES SA.':
            'Development of ÚNICA Platform (Ex PUMA Platform) in Association with CODES SA.',
          'Programa de Cebada Cerificada.': 'Certified Barley Program.',
          'Creación de GENSUS.': 'Creation of GENSUS.',
          'Una plataforma que potencia al productor agropecuario con los beneficios que genera la sustentabilidad':
            'A platform that empowers the agricultural producer with the benefits generated by sustainability',
          'Producción de cebada cervecera certificada.':
            'Production of certified brewing barley.',
          'Desarrollo de Plataforma Casa Tech para empresas de modernizacion del Agro.':
            'Development of the Casa Tech Platform for Agro modernization companies.',
          'Servicios para la compra y venta de campos, arrendamientos, tasaciones y divisiones.':
            'Services for the purchase and sale of fields, leases, appraisals and divisions.',

          CONEXIÓN: 'CONNECTION',
          'Apertura al mundo': 'Opening to the World',
          EMPRESAS: 'COMPANIES',
          'Trabajamos con': 'We work with',

          //Que hacemos EN
          Siembra: 'Sowing',
          Originación: 'Origination',
          Consultoría: 'Consulting Services',
          'Producción e Inversión Agrícola':
            'Agricultural Investment and Production',
          'Empezamos en el negocio de siembras en el año 1978. Más de cuarenta campañas y un eficiente trabajo en red nos permitieron crecer hacia otros esquemas de producción y exportar el modelo hacia otros países.':
            'We started the seeding business in 1978. After more than forty campaigns and an efficient networking, we were able to grow towards other production schemes and to export the model to other countries.',
          'Nuestro principal foco es lograr la plena satisfacción del cliente y/o inversor tanto en la rentabilidad como en el logro de la producción deseada en calidad y cantidad. Al mismo tiempo, respetamos y fomentamos las normas de cuidado ambiental y el cuidado de las personas involucradas en el proceso productivo. Dicha producción está basada en la alta tecnología, el conocimiento local, y una red de ingenieros capacitados para cumplimentar estos procesos y avanzar hacia la digitalización.':
            'Nosso principal foco é atingir a plena satisfação do cliente e/ou investidor tanto na rentabilidade quanto na obtenção da produção desejada em qualidade e quantidade. Ao mesmo tempo, respeitamos e promovemos as normas de cuidado ambiental e o cuidado das pessoas envolvidas no processo produtivo. Essa produção é baseada em alta tecnologia, conhecimento local e uma rede de engenheiros treinados para concluir esses processos e avançar para a digitalização.',
          'Producimos commodities y specialties bajo diferentes modalidades dando servicios para otras compañías; administrando fondos/fideicomisos públicos; a través de un esquema de siembras asociadas.':
            'We produce commodities and specialties under different methods: we render services to other companies; we manage public trusts/funds; by means of an associated seeding scheme.',
          'Brindamos un servicio integral de administración agropecuaria. Sumamos más de 1 millón y medio de Hectáreas Sembradas y 1.300 millones de usd de valor de producto vendido.':
            'We render comprehensive farming administration services. We have sowed more than 1 million and a half hectares, and we have sold our products for an aggregate value equal to USD 1,300 million.',
          'Alquilá tu Campo': 'Offer your land for rent',
          'Invertir en Fideicomiso': 'Invest in',
          'de Siembras': 'Seeding Trusts',
          'Nuestros Ingenieros': 'Our Engineers',
          'Sudoeste de Córdoba': 'South Western Córdoba',
          'Norte de la Pampa': 'Northern La Pampa',
          'Sur de Córdoba': 'Southern Córdoba',
          'Norte y Centro': 'Northern and Central',
          'de BsAs': 'Buenos Aires',
          'Sudoeste de Córdoba Noroeste de BsAs':
            'South Western Córdoba North Western Buenos Aires',
          'Norte de BsAs': 'Northern Buenos Aires',
          'Sur de Sta Fé': 'Southern Santa Fé',
          'Originación de Granos y Especialidades':
            'Origination of Grains and Specialties',
          'Es un programa de cultivos especiales donde asociamos productores y empresas. Para la producción de trigo candeal y semilla, trigos especiales de alta calidad, cebada cervecera y semilla, girasol Alto Oleico, y maíz pisingallo.':
            'It is a special crop program whereby we relate produces and companies. For the production of seed and durum wheat, high-quality special wheat, seed and malting barley, High Oleic Sunflower Oil, and pop corn.',
          'Hasta el momento en los últimos años, hemos totalizado  más de 2 millones de Hectáreas Originadas 1.200MM de has de originación, en los distintos programas.':
            'So far in recent years, we have totaled more than 2 million Hectares Originated, 1.200MM hectares of origination, in the different programs.',
          'Negocios Inmobiliarios': 'Real Estate Business',
          'negocios-inmobiliarios-primer-parrafo_html':
            'Cazenave, represented by his partner Fernando Elizalde, has partnered with Fitz Gerald & Harte, represented by his partner Eduardo Fitz Gerald, and Gómez Romero Propiedades, represented by his partner Augusto Gómez Romero, forming ARGEN CAMPOS. The objective is to enhance our services for the purchase and sale of fields, leases, appraisals and divisions of important agricultural establishments.',
          'Ofrecemos desde el análisis del mercado, valuación, proyectos de inversión, detección de potenciales contrapartes, negociación hasta el cierre del acuerdo.':
            'We provide several services, such as market analysis, assessment, investment projects, identification of prospective counterparties, negotiation until the agreement is executed.',
          'Hacemos tasaciones de propiedades rurales para distintos fines y divisiones especialmente de empresas familiares.':
            'We do appraisals of rural properties for different purposes and divisions, especially family businesses.',
          'Realizamos el trabajo utilizando las mejores y más modernas tecnologías disponibles, con imágenes satelitales, cartas de suelo, cartas topográficas, índice verde, etc.':
            'We work with the best cutting-edge technologies available, based on satellite images, soil charters, topographical letters, Green Index, etc.',
          'Realizamos asesoramientos en inversiones inmobiliarias, su rentabilidad, evolución del precio de la tierra, aspectos impositivos, restricciones ambientales, etc.':
            'We carry out advice on real estate investments, their profitability, evolution of the price of land, tax aspects, environmental restrictions, etc.',
          'Tenemos un equipo muy profesional, de vasta experiencia y conocimiento en la actividad.':
            'We have a very professional team, with vast experience and knowledge in the activity.',
          'Quiero Vender mi Campo': 'I would like to sell my field',
          'Contactate con Fernando Elizalde': 'Contact Fernando Elizalde',
          Asunto: 'Subject',
          'Ingresa tu descripción aquí': 'Write your description here',
          'Único semillero que produce semilla fiscalizada de algodón en Argentina con el máximo estándar de calidad.  Ofrece siete variedades de diferentes ciclos adaptadas a las distintas zonas algodoneras y a diferentes condiciones de producción. Desarrolla en asociación con Bioheuris nuevas tecnologías de resistencia a herbicidas a través de la edición génica. Defendemos la Propiedad Intelectual como único camino para aumentar la competencia y lograr mejores materiales genéticos que contribuyan con el productor, el ambiente y la comunidad.':
            'The only seedbed that produces certified cotton seed in Argentina with the highest quality standard. It offers seven varieties of different cycles adapted to the different cotton areas and to different production conditions. It develops in association with Bioheuris new herbicide resistance technologies through gene editing. We defend Intellectual Property as the only way to increase competition and achieve better genetic materials that contribute to the producer, the environment and the community.',
          'Cazenave Consultoría': 'Cazenave Consulting Services',
          'Brindamos los servicios de consultoría, auditoría y análisis de negocios en distintos sectores del agribusiness.':
            'We render business analysis, audit, and consulting services to different  agribusiness sectors',
          'Ofrecemos asesoramiento estratégico sobre la producción, comercialización y tecnologías exponenciales aplicadas al agro tanto a empresas argentinas y como de la región.':
            'We provide strategic advisory services to Argentine companies and also to other foreign companies in the region, on production, commercialization, and exponential technologies applied to agriculture.',

          //CasaTech EN
          CasaTech: 'Tech Platform',
          'El negocio tradicional del agro se enfrenta al gran desafío de adaptarse a los constantes cambios disruptivos de la tecnología.':
            'Traditional agribusiness is facing a huge challenge in getting adapted to the permanent ongoing disruptive changes of technology.',
          'Exponenciamos la industria del agro aplicando nuevas tecnologías que multiplican y mejoran los resultados de nuestro trabajo.':
            'We boost the agro industry by means of the implementation of new technologies which multiply and improve the output of our work.',
          'Somos la Plataforma sólida para empresas de modernización del Agro generando Ciencia y Tecnología.':
            'We offer a solid Platform for agro-industry modernization companies by generating Science & Technology.',
          'NUESTROS AG TECH': 'AG TECH',
          'Almacenamos y visualizamos toda la información técnica por campaña, lote y ambiente, de gestión agrícola y georreferenciada.':
            'We store and view all our agricultural management and geo-referenced technical information per company, batch, and site.',
          'Ver más': 'Read more',
          'La plataforma digital para la producción de cultivos basados en la descentralización de productores e inversores apoyada en la tecnología Blockchain y las buenas practicas agrícolas.':
            'The e-platform for the production of crops based on the decentralization of farmers and investors is powered by Blockchain technology and backed by good agricultural practices.',
          'Cazenave es la empresa líder de Argentina de servicios del Agro, con más de 50 años de historia, adaptada a las NUEVAS TECNOLOGIAS EXPONENCIALES con EXCELENCIA':
            'We are a leading agricultural service provider in Argentina adapted to NEW EXPONENTIAL TECHNOLOGIES and focused on achieving EXCELLENCE, with more than 50 years of experience.',
          'Sumate a la plataforma de Cazenave Tecnológico y forma parte de nuestro equipo con tu Startup.':
            'Join Cazenave’s Tech Platform and cause your Startup to become part of our team.',
          'Nombre del startup': 'Name of Startup',
          'email@ejemplo.com': 'email@example.com',
          'Problema a resolver': 'Problem to be tackled',
          'Escribe una breve descripción del problema':
            'Write a brief description of the problem.',
          Contexto: 'Background',
          'Danos un contexto que se relaciona con el proyecto':
            'Provide a context that is related to the project.',
          '¿Cómo lo resuelven?': 'How is it tackled?',
          'Escribe un breve detalle de cómo resuelven el problema':
            'Write a short description about how to solve the problem.',
          'Todos estos cultivos se desarrollan en diferentes modelos que van desde la siembras 100% por cuenta y orden de cada cliente hasta las siembras asociadas con productores, todo bajo nuestra dirección y supervisión.':
            'These crops are developed under different methods ranging from seeding methods depending 100% on each client to associated seeding systems with producers, under our full direction and monitoring.',
          'Estamos innovando para incorporar a todos estos cultivos en un sistema de Blockchain, utilizando la agtech Ucrop.it  tanto para su certificación futura como también para eficientizar los procesos y toma de decisiones. Dicha certificación de los eventos realizados a campo, en forma simple y con uso de IOT. Los consumidores de nuestro país y del mundo entero están demandando cada vez alimentos sanos, que ayuden a una buena nutrición y que se produzcan protegiendo los recursos naturales y el medioambiente.  Los sistemas productivos de nuestro país se encuentran entre los más sustentables de todo el mundo y nos encontramos trabajando en este tema hace más de 30 años utilizando producción con siembra directa, rotación de cultivos y hoy también cultivos de servicios y agricultura de precisión.  Certificar todo esto utilizando Ucrop.it es el desafío que hemos incorporado a los  sistemas productivos dirigidos por Cazenave. Seguimos creciendo en los servicios de originación de granos  y especialidades incorporando nuevos clientes e inversores':
            'We are innovating to incorporate all these crops in a Blockchain system, using the agtech Ucrop.it both for their future certification as well as to streamline processes and decision-making. Said certification of the events carried out in the field, in a simple way and with the use of IOT. Consumers in our country and around the world are increasingly demanding healthy foods, which support good nutrition and are produced while protecting natural resources and the environment. The productive systems of our country are among the most sustainable in the world and we have been working on this issue for more than 30 years using production with direct sowing, crop rotation and today also service crops and precision agriculture. Certifying all this using Ucrop.it is the challenge that we have incorporated into the production systems managed by Cazenave. We continue to grow in grain origination services and specialties, incorporating new clients and investors.',

          //Unica EN
          'La REVOLUCIÓN de la agricultura por ambientes.':
            'The REVOLUTION of site-specific agriculture.',
          'Plataforma GIS con soluciones prácticas para monitoreos, muestreos georreferenciados, estimación de rindes, ambientación de suelos, prescripciones de siembra y fertilización variable, mapas de rinde, mpas de tosca, NDVI. Almacenamos y visualizamos toda la información técnica por campaña, lote y ambiente, de gestión agrícola y georreferenciada.':
            'GIS platform with practical solutions for monitoring, georeferenced sampling, yield estimation, soil setting, variable planting and fertilization prescriptions, yield maps, tosca mpas, NDVI. We store and visualize all the technical information by campaign, lot and environment, of agricultural and georeferenced management.',
          'Mejorar la logística de cosecha': 'Improve harvesting logistics',
          'Hacer un control efectivo de eficiencia de labores':
            'Perform an effective control of labor efficiency',
          'Fidelizar Contratistas': 'Generate Contractors Loyalty',
          'Obtener el mapa de ÚNICA con un sólo click':
            'Gaining access to ÚNICA Platform’s map is just a click away',
          Ingresar: 'Sign in',

          //Inverti EN
          'Invertí con nosotros': 'Invest with us',
          'Aprovecha la oportunidad de invertir en los fondos del principal operador de siembras de Argentina.':
            'Take advantage of this chance to invest in the funds of the main sowing operator in Argentina.',
          'Quiero alquilar mi campo': 'I would like to offer my field for rent',
          'Quiero invertir en fideicomiso':
            'I would like to make a trust investment',
          Organización: 'Organization',
          'Ingresa el nombre de tu organización aquí':
            "Type your Organization's name here",
          Teléfono: 'Phone No.',
          '+549 11 0000 0000': 'Phone No.',
          País: 'Country',
          'Ingresa tu país aquí': 'Type your country here.',

          //Contacto EN
          'Contactate con nosotros': 'Contact Us',
          '¿En qué te podemos ayudar?': 'What can we do for you?',
          'Completa el formulario, déjanos tu mensaje junto tu correo electrónico o escribinos a info@e-cazenave.com.ar. Te responderemos en la brevedad.':
            'Fill in the form below, leave us a message together with your e-mail or e-mail us at info@e-cazenave.com.ar. We will answer your request ASAP.',
          Nombre: 'Name',
          Email: 'E-mail',
          Mensaje: 'Message',
          Enviar: 'Send',
          'Ingresa tu nombre aquí': 'Type your name here',
          'Ingresa el nombre aquí': 'Type the name here',
          'Ingresa tu email aquí': 'Type your e-mail here',
          'Escribe aquí tu mensaje': 'Write your message here',
          '¡Dejanos tu CV!': 'Leave us your CV!',
          'Archivo subido': 'File uploaded',
          'Subi tu archivo': 'Upload your file',
        },
      },
      por: {
        translations: {
          //Nav POR
          Historia: 'História',
          '¿Qué hacemos?': 'O que fazemos?',
          'Casa Tech': 'Casa Tech',
          Única: 'Única',
          Invertí: 'Invista',
          Prensa: 'Imprensa',
          Contacto: 'Contato',
          'Tel +54 11 4763-5500': 'Telefone +54 11 4763-5500',
          'Seleccioná idioma': 'Escolha o idioma',

          //HOME POR
          'El puente hacia': 'A ponte rumo',
          'el futuro del agro': 'ao futuro do agro',
          'Nuestro Objetivo': 'Nosso Objetivo',
          'Desarrollamos negocios en la cadena de la agroindustria, buscando promover el crecimiento económico y humano en un marco sustentable.':
            'Desenvolvemos negócios na cadeia da agroindústria, procurando promover o crescimento econômico e humano em um contexto sustentável.',
          'Somos la empresa líder de Argentina de servicios del Agro, con más de 50 años de historia, adaptada a las ':
            'Somos a empresa líder da Argentina de serviços para o Agro, com mais de 50 anos de história, adaptada às ',
          'NUEVAS TECNOLOGíAS EXPONENCIALES': 'NOVAS TECNOLOGIAS EXPONENCIAIS',
          'con ': 'com ',
          EXCELENCIA: 'EXCELÊNCIA',
          'Nuestra Historia': 'Nossa História',
          Innovación: 'Inovação',
          Confianza: 'Confiança',
          'Trabajo en equipo': 'Trabalho em equipe',
          Excelencia: 'Excelência',
          'En Originación': 'Em Originação',
          Fideicomisos: 'Relações de confiança',
          'Fondos de Siembra': 'Fundos de Semeadura',
          'HAS.': 'HA',
          'Valor Producto Originado': 'Valor Produto Originado',
          'Valor Producto Vendido': 'Valor Produto Vendido',
          'Nuestro equipo': 'Nossa equipe',
          'BOARD OF DIRECTORS': 'DIRETORIA',
          'EXECUTIVE TEAM': 'EQUIPE EXECUTIVA',
          Director: 'Diretor',
          'Director de Producción': 'Diretor de Produção',
          'Director de Gestión y Finanzas':
            'Diretor de Gerenciamento e Finanças',
          'Gerente de Administración y Finanzas':
            'Gerente de Administração e Finanças',
          'Gerente de Comercialización y Logística':
            'Gerente de Marketing e Logística',
          'Gerente de Originación': 'Gerente de Originação',
          'Ing. en Producción Agropecuaria': 'Eng. de Produção Agropecuária',
          'Ing.': 'Eng.',
          'Director y Presidente de Cazenave. Director de Gensus S.A. Responsable de Dirección de NUEVOS NEGOCIOS DE INNOVACIÓN.':
            'Diretor e Presidente da Cazenave. Diretor da Gensus S.A. Responsável da Direção de NOVOS NEGÓCIOS DE INOVAÇÃO',
          'Ing. Agrónomo': 'Eng. Agrônomo',
          'Director y Ex Presidente de Cazenave. Director de Gensus SA. Responsable del programa de ORIGINACIÓN.':
            'Diretor e Ex-Presidente da Cazenave. Diretor da Gensus S.A. Responsável do programa de ORIGINAÇÃO',
          'Director y Ex Presidente de Cazenave. Ex Presidente de ASAGIR. Responsable del programa de SIEMBRAS.':
            'Diretor e Ex-Presidente da Cazenave. Ex-Presidente da ASAGIR. Responsável do programa de SEMEADURAS',
          'Director de Cazenave. Asesor de Negocios inmobiliarios.':
            'Diretor da Cazenave. Assessor de Negócios Imobiliários',
          'CEO de Cazenave': 'CEO da Cazenave',
          'Alta Dirección Regional y Global; Managing Director en Fondos de inversión de Private Equity en toda la cadena de Agribusiness. Founder de Startups en proyectos greenfields con innovación tecnológica disruptiva.':
            'Alta Direção Regional e Global; Managing Director de Fundos de investimento de Private Equity em toda a cadeia de Agribusiness. Founder de Startups em projetos greenfields com inovação tecnológica disruptiva',
          'Ing. en Producción Agropecuaria. M.Sc. en Agronegocios y Alimentos (2001)':
            'Eng. Produção Agropecuária. M.Sc. em Agronegócios e Alimentos (2001)',
          'Dirección de los programas de Siembras.':
            'Direção dos programas de Semeaduras',
          'Gerente de Administración y Finanzas de Cazenave y Asociados y de Gensus.':
            'Gerente de Administração e Finanças da Cazenave y Asociados e da Gensus',
          'Área de Comercialización y Logística.':
            'Área de Marketing e Logística',
          'Área de Originación. Cereales de invierno.':
            'Área de Originação, Cereais de inverno',
          'Lic. Comunicación Social': 'Bel. Comunicação Social',
          'Comunicación y Prensa de Cazenave.':
            'Comunicação e Imprensa da Cazenave',
          'En Cazenave estamos en proceso de tener toda nuestros manejos productivos, registrados con trazabilidad, para ir camino a agricultura certificada. Con total cumplimiento de las buenas prácticas agrícolas, y cuidado del medioambiente.':
            'Na Cazenave, estamos no caminho de fazer com que nossa gestão produtiva total seja registrada com rastreabilidade, a fim de obter uma certificação em agricultura, em total conformidade com as boas práticas agrícolas e com o cuidado do meio ambiente.',

          //Historia POR
          'Nuestra historia': 'Nossa história',
          'Comenzamos con las siembras en el año 1978; más de cuarenta campañas y un eficiente trabajo en red nos permitieron crecer hacia otros esquemas de producción y exportar el modelo hacia otros países.':
            'Começamos com as semeaduras no ano de 1978; mais de quarenta campanhas e um eficiente trabalho em rede nos permitiram crescer para outros esquemas de produção e exportar o modelo para outros países.',
          'Actualmente, producimos commodities y specialties bajo diferentes modalidades para otras compañías; para fondos/fideicomisos; a través de un esquema de siembras asociadas. Brindamos, también, un servicio integral de administración agropecuaria.':
            'Atualmente produzimos commodities e specialties sob diferentes modalidades: para outras companhias, para fundos/fideicomissos; através de um esquema de semeaduras associadas. Oferecemos, também, um serviço integral de administração agropecuária.',
          'Fundación de Cazenave': 'Fundação da Cazenave',
          'Adopción de tecnología de Siembra Directa.':
            'Adoção de tecnologia de Semeadura Direta.',
          'Primeras 400 has de producción en arrendamiento.':
            'Primeiros 400 hectares de produção em arrendamento.',
          'Girasol en Venezuela.': 'Girassol na Venezuela.',
          'Programa de Girasol Alto Oleico con DOW.':
            'Programa de Girassol Alto Oleico com DOW.',
          '1er Fondo Agrícola con Aprobación de la CNV y Oferta Pública.':
            'Primeiro Fundo Agrícola com Aprovação da CNV e Oferta Pública',
          'Programa de Trigo Candeal y Trigo Pan de Calidad.':
            'Programa de Trigo Duro e Trigo Pão de Qualidade',
          'IFC (Banco Mundial) Inversión en FAID (Primer fondo Plurianual).':
            'IFC (Banco Mundial) Investimento no FAID (Primeiro Fundo Plurianual).',
          'Utilización de Silo Bolsa en gran escala. Desarrollo de programa de Reciclado de Envases.':
            'Utilização de Silo-Bolsa em grande escala. Desenvolvimento de programa de Reciclagem de Vasilhames.',
          'Desarrollo y puesta en el mercado de Agrositio. Producción de Popcorn.':
            'Desenvolvimento e introdução no mercado de Agrositio. Produção de Pipoca.',
          'Desarrollo y puesta en el mercado de Agrositio.':
            'Desenvolvimento e introdução no mercado de Agrositio.',
          'Producción de Popcorn.': 'Produção de Pipoca.',
          'Programa de siembras asociadas. Creación de CM para Cobro de Regalías.':
            'Programa de semeaduras associadas. Criação de CM para Cobrança de Royalties',
          'Fideicomiso Arándanos en Tucumán. Proyecto Biodiesel / Proyecto Jatropha. Ante-proyecto de producción.':
            'Fideicomisso Cranberries em Tucumán. Projeto Biodiesel / Projeto Jatropha. Anteprojeto de produção.',
          'Proyecto de Intersiembras. Creación de Patagonia Bioenergía. Proyecto Colza.':
            'Projeto de Intersemeaduras. Criação da Patagônia Bioenergia. Projeto Colza.',
          'Proyecto de Intersiembras.': 'Projeto de Intersemeaduras.',
          'Creación de Patagonia Bioenergía.':
            'Criação da Patagônia Bioenergia.',
          'Proyecto Colza.': 'Projeto Colza.',
          'Proyecto y Desarrollo de Producción en Colombia. Integración con Black River. Consultoría en Ucrania.':
            'Projeto e Desenvolvimento de Produção na Colômbia. Integração com a Black River. Consultoria na Ucrânia.',
          'Proyecto y Desarrollo de Producción en Colombia.':
            'Projeto e Desenvolvimento de Produção na Colômbia.',
          'Integración con Black River.': 'Integração com a Black River.',
          'Consultoría en Ucrania.': 'Consultoria na Ucrânia.',
          'Programa de producción para Sojitz Co.':
            'Programa de produção para a Sojitz Co.',
          'Proyecto y Desarrollo de Producción en Angola. Consultoría en Paraguay.':
            'Projeto e Desenvolvimento de Produção na Angola. Consultoria no Paraguai.',
          'Proyecto y Desarrollo de Producción en Angola.':
            'Projeto e Desenvolvimento de Produção na Angola.',
          'Consultoría en Paraguay.': 'Consultoria no Paraguai.',
          'Proyecto de Producción en Brasil.': 'Projeto de Produção no Brasil.',
          'Desarrollo de área Inmobiliaria.':
            'Desenvolvimento da Área Imobiliária.',
          'Desarrollo de Plataforma UNICA (Ex PUMA) en Asociación con CODES SA.':
            'Desenvolvimento da Plataforma ÚNICA (ex PUMA) em Parceria com a CODES S.A.',
          'Programa de Cebada Cerificada.': 'Programa de Cevada Certificada.',
          'Creación de GENSUS.': 'Criação da GENSUS.',
          'Una plataforma que potencia al productor agropecuario con los beneficios que genera la sustentabilidad':
            'Uma plataforma que capacita o produtor agrícola com os benefícios gerados pela sustentabilidade',
          'Producción de cebada cervecera certificada.':
            'Produção de cevada para cerveja certificada.',
          'Desarrollo de Plataforma Casa Tech para empresas de modernizacion del Agro.':
            'Desenvolvimento da Plataforma Casa Tech para empresas de agromodernização.',
          'Servicios para la compra y venta de campos, arrendamientos, tasaciones y divisiones.':
            'Serviços de compra e venda de campos, arrendamentos, avaliações e divisões.',

          CONEXIÓN: 'CONEXÃO',
          'Apertura al mundo': 'Abertura ao mundo',

          'Trabajamos con': 'Trabalhamos com',

          //Que hacemos POR

          'Producción e Inversión Agrícola': 'Produção e Investimento Agrícola',
          'Empezamos en el negocio de siembras en el año 1978. Más de cuarenta campañas y un eficiente trabajo en red nos permitieron crecer hacia otros esquemas de producción y exportar el modelo hacia otros países.':
            'Começamos com as semeaduras no ano de 1978; mais de quarenta campanhas e um eficiente trabalho em rede nos permitiram crescer para outros esquemas de produção e exportar o modelo para outros países.',
          'Nuestro principal foco es lograr la plena satisfacción del cliente y/o inversor tanto en la rentabilidad como en el logro de la producción deseada en calidad y cantidad. Al mismo tiempo, respetamos y fomentamos las normas de cuidado ambiental y el cuidado de las personas involucradas en el proceso productivo. Dicha producción está basada en la alta tecnología, el conocimiento local, y una red de ingenieros capacitados para cumplimentar estos procesos y avanzar hacia la digitalización.':
            'Nosso principal foco é atingir a plena satisfação do cliente e/ou investidor tanto na rentabilidade quanto na obtenção da produção desejada em qualidade e quantidade. Ao mesmo tempo, respeitamos e promovemos as normas de cuidado ambiental e o cuidado das pessoas envolvidas no processo produtivo. Essa produção é baseada em alta tecnologia, conhecimento local e uma rede de engenheiros treinados para concluir esses processos e avançar para a digitalização.',
          'Producimos commodities y specialties bajo diferentes modalidades dando servicios para otras compañías; administrando fondos/fideicomisos públicos; a través de un esquema de siembras asociadas.':
            'Produzimos commodities e specialties sob diferentes modalidades: para outras companhias, para fundos/fideicomissos; através de um esquema de semeaduras associadas.',
          'Brindamos un servicio integral de administración agropecuaria. Sumamos más de 1 millón y medio de Hectáreas Sembradas y 1.300 millones de usd de valor de producto vendido.':
            'Oferecemos um serviço integral de administração agropecuária. Totalizamos mais de um milhão e meio de hectares semeados e 1300 milhões de dólares de valor de produto vendido.',
          'Alquilá tu Campo': 'Alugue seu Campo',
          'Invertir en Fideicomiso': 'Investir em Fideicomisso',
          'de Siembras': 'de Semeaduras',
          'Nuestros Ingenieros': 'Nossos Engenheiros',
          'Originación de Granos y Especialidades':
            'Originação de Grãos e Especialidades',
          'Es un programa de cultivos especiales donde asociamos productores y empresas. Para la producción de trigo candeal y semilla, trigos especiales de alta calidad, cebada cervecera y semilla, girasol Alto Oleico, maíz y maíz pisingallo.':
            'Trata-se de um programa de cultivos especiais onde associamos produtores e empresas. Para a produção de trigo duro e semente, trigos especiais de alta qualidade, cevada cervejeira e semente, girassol Alto Oleico, e milho de pipoca.',
          'Todos estos cultivos se desarrollan en diferentes modelos que van desde la siembras 100% por cuenta y orden de cada cliente hasta las siembras asociadas con productores, todo bajo nuestra dirección y supervisión.':
            'Todos esses cultivos são desenvolvidos em diferentes modelos que vão da semeadura 100% por conta e ordem de cada cliente até as semeaduras associadas com produtores, tudo sob nossa direção e supervisão.',
          'Estamos innovando para incorporar a todos estos cultivos en un sistema de Blockchain, utilizando la agtech Ucrop.it  tanto para su certificación futura como también para eficientizar los procesos y toma de decisiones. Dicha certificación de los eventos realizados a campo, en forma simple y con uso de IOT. Los consumidores de nuestro país y del mundo entero están demandando cada vez alimentos sanos, que ayuden a una buena nutrición y que se produzcan protegiendo los recursos naturales y el medioambiente.  Los sistemas productivos de nuestro país se encuentran entre los más sustentables de todo el mundo y nos encontramos trabajando en este tema hace más de 30 años utilizando producción con siembra directa, rotación de cultivos y hoy también cultivos de servicios y agricultura de precisión.  Certificar todo esto utilizando Ucrop.it es el desafío que hemos incorporado a los  sistemas productivos dirigidos por Cazenave. Seguimos creciendo en los servicios de originación de granos  y especialidades incorporando nuevos clientes e inversores':
            'We are innovating to incorporate all these crops in a Blockchain system, using the agtech Ucrop.it both for their future certification as well as to streamline processes and decision-making. Said certification of the events carried out in the field, in a simple way and with the use of IOT. Consumers in our country and around the world are increasingly demanding healthy foods, which support good nutrition and are produced while protecting natural resources and the environment. The productive systems of our country are among the most sustainable in the world and we have been working on this issue for more than 30 years using production with direct sowing, crop rotation and today also service crops and precision agriculture. Certifying all this using Ucrop.it is the challenge that we have incorporated into the production systems managed by Cazenave. We continue to grow in grain origination services and specialties, incorporating new clients and investors.',
          'Hasta el momento en los últimos años, hemos totalizado  más de 2 millones de Hectáreas Originadas 1.200MM de has de originación, en los distintos programas.':
            'Nos últimos anos, totalizamos mais de 2 milhões de Hectares Originados, 1,200 milhões de hectares de originação, nos diferentes programas.',
          'Negocios Inmobiliarios': 'Negócios Imobiliários',
          'negocios-inmobiliarios-primer-parrafo_html':
            'Prestamos serviços profissionais em todo o processo de compra, venda e arrendamento de importantes estabelecimentos agropecuários.',
          'Ofrecemos desde el análisis del mercado, valuación, proyectos de inversión, detección de potenciales contrapartes, negociación hasta el cierre del acuerdo.':
            'Oferecemos da análise do mercado, avaliação, projetos de investimento, identificação de potenciais contrapartes e negociação até o encerramento do acordo.',
          'Hacemos tasaciones de propiedades rurales para distintos fines y divisiones especialmente de empresas familiares.':
            'Fazemos avaliações de imóveis rurais para diversos fins e divisões, principalmente empresas familiares.',
          'Realizamos el trabajo utilizando las mejores y más modernas tecnologías disponibles, con imágenes satelitales, cartas de suelo, cartas topográficas, índice verde, etc.':
            'Fazemos o trabalho utilizando as melhores e mais modernas tecnologias disponíveis, com imagens de satélite, cartas de solo, cartas topográficas, índice verde, etc.',
          'Realizamos asesoramientos en inversiones inmobiliarias, su rentabilidad, evolución del precio de la tierra, aspectos impositivos, restricciones ambientales, etc.':
            'Prestamos assessoria em investimentos imobiliários, sua rentabilidade, evolução do preço dos terrenos, aspectos tributários, restrições ambientais, etc.',
          'Tenemos un equipo muy profesional, de vasta experiencia y conocimiento en la actividad.':
            'Contamos com uma equipa muito profissional, com vasta experiência e conhecimento na actividade.',
          'Quiero Vender mi Campo': 'Quero Vender meu Campo',
          'Contactate con Fernando Elizalde':
            'Entre em contato com Fernando Elizalde',
          Asunto: 'Assunto',
          'Ingresa tu descripción aquí': 'Escreva aqui sua descrição',
          'Único semillero que produce semilla fiscalizada de algodón en Argentina con el máximo estándar de calidad.  Ofrece siete variedades de diferentes ciclos adaptadas a las distintas zonas algodoneras y a diferentes condiciones de producción. Desarrolla en asociación con Bioheuris nuevas tecnologías de resistencia a herbicidas a través de la edición génica. Defendemos la Propiedad Intelectual como único camino para aumentar la competencia y lograr mejores materiales genéticos que contribuyan con el productor, el ambiente y la comunidad.':
            'Única sementeira que produz caroço de algodão certificado na Argentina com o mais alto padrão de qualidade. Oferece sete variedades de diferentes ciclos adaptadas às diferentes áreas algodoeiras e às diferentes condições de produção. Em associação com a Bioheuris, desenvolve novas tecnologias de resistência a herbicidas por meio da edição de genes. Defendemos a Propriedade Intelectual como única forma de aumentar a concorrência e obter melhores materiais genéticos que contribuam com o produtor, o meio ambiente e a comunidade.',
          'Cazenave Consultoría': 'Cazenave Consultoria',
          'Brindamos los servicios de consultoría, auditoría y análisis de negocios en distintos sectores del agribusiness.':
            'Oferecemos os serviços de consultoria, auditoria e análise de negócios em diferentes setores do agribusiness.',
          'Ofrecemos asesoramiento estratégico sobre la producción, comercialización y tecnologías exponenciales aplicadas al agro tanto a empresas argentinas y como de la región.':
            'Oferecemos assessoria estratégica sobre a produção, comercialização e tecnologias exponenciais aplicadas ao agro para empresas argentinas e da região.',

          //CasaTech POR
          CasaTech: 'CasaTech',
          'El negocio tradicional del agro se enfrenta al gran desafío de adaptarse a los constantes cambios disruptivos de la tecnología.':
            'O negócio tradicional do agro se defronta com o grande desafio de adaptar-se às permanentes mudanças disruptivas da tecnologia.',
          'Exponenciamos la industria del agro aplicando nuevas tecnologías que multiplican y mejoran los resultados de nuestro trabajo.':
            'Exponenciamos a indústria do agro aplicando novas tecnologias que multiplicam e melhoram os resultados do nosso trabalho.',
          'Somos la Plataforma sólida para empresas de modernización del Agro generando Ciencia y Tecnología.':
            'Somos a Plataforma sólida para empresas de modernização do Agro, gerando Ciência e Tecnologia.',
          'NUESTROS AG TECH': 'NOSSOS AG TECH',
          'Almacenamos y visualizamos toda la información técnica por campaña, lote y ambiente, de gestión agrícola y georreferenciada.':
            'Armazenamos e visualizamos todas as informações técnicas por campanha, lote e ambiente de gestão agrícola e georreferenciada.',
          'Ver más': 'Veja mais',
          'La plataforma digital para la producción de cultivos basados en la descentralización de productores e inversores apoyada en la tecnología Blockchain y las buenas practicas agrícolas.':
            'A plataforma digital para a produção de cultivos baseados na descentralização de produtores e investidores baseada na tecnologia Blockchain e as boas práticas agrícolas.',
          'Cazenave es la empresa líder de Argentina de servicios del Agro, con más de 50 años de historia, adaptada a las NUEVAS TECNOLOGIAS EXPONENCIALES con EXCELENCIA':
            'A Cazenave é a empresa líder da Argentina de serviços do Agro, com mais de 50 anos de história, adaptada às NOVAS TECNOLOGIAS EXPONENCIAIS com EXCELÊNCIA.',
          'Sumate a la plataforma de Cazenave Tecnológico y forma parte de nuestro equipo con tu Startup.':
            'Una-se à plataforma da Cazenave Tecnológico e forme parte da nossa equipe com sua Startup.',
          'Nombre del startup': 'Nome da Startup',
          'email@ejemplo.com': 'email@exemplo.com',
          'Problema a resolver': 'Problema a resolver',
          'Escribe una breve descripción del problema':
            'Escreva uma breve descrição do problema',
          Contexto: 'Contexto',
          'Danos un contexto que se relaciona con el proyecto':
            'Providencie um contexto que esteja relacionado com o projeto',
          '¿Cómo lo resuelven?': 'Como resolvem?',
          'Escribe un breve detalle de cómo resuelven el problema':
            'Escreva uma breve descrição sobre como resolvem o problema',

          //Unica POR
          'La REVOLUCIÓN de la agricultura por ambientes.':
            'A REVOLUÇÃO da agricultura por ambientes.',
          'Plataforma GIS con soluciones prácticas para monitoreos, muestreos georreferenciados, estimación de rindes, ambientación de suelos, prescripciones de siembra y fertilización variable, mapas de rinde, mpas de tosca, NDVI. Almacenamos y visualizamos toda la información técnica por campaña, lote y ambiente, de gestión agrícola y georreferenciada.':
            'GIS platform with practical solutions for monitoring, georeferenced sampling, yield estimation, soil setting, variable planting and fertilization prescriptions, yield maps, tosca mpas, NDVI. We store and visualize all the technical information by campaign, lot and environment, of agricultural and georeferenced management.',
          'Mejorar la logística de cosecha': 'Melhorar a logística da colheita',
          'Hacer un control efectivo de eficiencia de labores':
            'Fazer um controle efetivo de eficiência de tarefas',
          'Fidelizar Contratistas': 'Fidelizar Empreiteiros',
          'Obtener el mapa de ÚNICA con un sólo click':
            'Obter o mapa da ÚNICA com só um clique',
          Ingresar: 'Entrar',

          //Inverti POR
          'Invertí con nosotros': 'Invista conosco',
          'Aprovecha la oportunidad de invertir en los fondos del principal operador de siembras de Argentina.':
            'Aproveite a oportunidade para investir nos fundos da principal operadora de semeaduras da Argentina.',
          'Quiero alquilar mi campo': 'Quero alugar meu campo',
          'Quiero invertir en fideicomiso': 'Quero investir em fideicomisso',
          Organización: 'Organização',
          'Ingresa el nombre de tu organización aquí':
            'Insira o nome de sua organização',
          Teléfono: 'Telefone',
          '+549 11 0000 0000': 'Telefone',
          País: 'País',
          'Ingresa tu país aquí': 'Insira o seu país aqui',

          //Contacto POR
          'Contactate con nosotros': 'Entre em contato conosco',
          '¿En qué te podemos ayudar?': 'Como podemos ajudá-lo?',
          'Completa el formulario, déjanos tu mensaje junto tu correo electrónico o escribinos a info@e-cazenave.com.ar. Te responderemos en la brevedad.':
            'Preencha o formulário, deixe sua mensagem juntamente com seu e-mail ou escreva para info@e-cazenave.com.ar. Responderemos em breve.',
          Nombre: 'Nome',
          Email: 'E-mail',
          Mensaje: 'Mensagem',
          Enviar: 'Enviar',
          'Ingresa tu nombre aquí': 'Insira seu nome aqui',
          'Ingresa el nombre aquí': 'Insira o nome aqui',
          'Ingresa tu email aquí': 'Insira seu e-mail aqui',
          'Escribe aquí tu mensaje': 'Escreva aqui sua mensagem',
          '¡Dejanos tu CV!': 'Deixe-nos o seu CV!',
          'Archivo subido': 'Arquivo enviado',
          'Subi tu archivo': 'Envie seu arquivo',
        },
      },
    },
    react: {
      wait: true,
    },
    lng: 'es-Es',
    fallbackLng: 'es',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
