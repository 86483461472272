import React from 'react';
import styled from 'styled-components';
import pop from '../../../assets/images/quehacemos-pop.png';
import seda from '../../../assets/images/quehacemos-seda.png';
import molinos from '../../../assets/images/quehacemos-molinos.png';
import lagomarsino from '../../../assets/images/quehacemos-lagomarsino.png';
import boortmalt from '../../../assets/images/boortmalt.png';

const Brands = () => {
  const BrandsContainer = styled.section`
    width: 100%;
    max-width: 1105px;
    height: 326px;
    background-color: #fafafa;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-template-rows: 1fr;
    flex-wrap: wrap;
    @media (max-width: 1105px) {
      grid-template-rows: 1fr 1fr 1fr;
    }
    .imgContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .re-size {
      width: 15rem;
    }
  `;
  return (
    <BrandsContainer>
      <div className="imgContainer">
        <img src={boortmalt} alt="" className="re-size" />
      </div>
      <div className="imgContainer">
        <img src={lagomarsino} alt="" />
      </div>
      <div className="imgContainer">
        <img src={molinos} alt="" />
      </div>
      <div className="imgContainer">
        <img src={pop} alt="" />
      </div>
      <div className="imgContainer">
        <img src={seda} alt="" />
      </div>
    </BrandsContainer>
  );
};

export default Brands;
