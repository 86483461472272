import React from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

const FormTech = () => {
    const {t} = useTranslation();

    const StyledForm = styled.form`
    background-color: #131313;
    border: 0.9px solid #00958D;
    border-radius: 20.7px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.89);
    opacity: 0.8404715401785714;
    width: 921px;
    max-width: 100%;
    height: 1120px;
    margin: 63px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    @media (max-width: 1105px) {
        max-width: 90%;
    }
    h4 {
    margin: 60px;
    color: #FFFFFF;
    font-size: 1.72rem;
    font-weight: 600;
    letter-spacing: -0.11px;
    line-height: 25.99px;
    width: 496px;
    max-width: 100%;
    text-align: center;
    }

    .btnContainer {
        width: 100%;
        max-width: 615px;
        margin: 10px auto;

            button {
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #175763;
            border-radius: 1px;
            width: 86.4px;
            height: 42px;
            color: #FFFFFF;
            font-size: 1.3rem;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 16px;
            border: none;
            cursor: pointer;
            transition: all .2s ease;
            &:disabled {
                background-color: #b8b8b8;
                color: #333;
            }
        }
    }
    `;

    const FieldWrapper = styled.div`
        margin-bottom: 10px;
        border: 1.2px solid #616161;
        border-radius: 3.6px;
        width: 615px;
        max-width: 100%;
        height: 64.8px;
        display: flex;
        flex-direction: column;
        padding: 8px;
        justify-content: space-between;
        label {
            font-size: 1.3rem;
            color: #a8a8a8;
        }
        input {
            background: transparent;
            border: none;
            color: #ccc;
            font-family: inherit;
            font-size: 1.6rem;
            &::placeholder {
                color: #ccc;
                font-family: inherit;
            }
            &:focus {
                outline: none;
            }
        }
    `;

    const TextAreaWrapper = styled.div`
        margin-bottom: 10px;
        border: 1.2px solid #616161;
        border-radius: 3.6px;
        width: 615px;
        max-width: 100%;
        height: 154.8px;
        display: flex;
        flex-direction: column;
        padding: 8px;
        justify-content: space-between;
        label {
            font-size: 1.3rem;
            color: #a8a8a8;
        }
        textarea {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            color: #ccc;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.6rem;
            &::placeholder {
                color: #ccc;
                font-family: 'Open Sans', sans-serif;
            }
            &:focus {
                outline: none;
            }
        }
    `;


    const contactSchema = Yup.object().shape({

        name: Yup.string()
        .required(),

        startUpName: Yup.string()
        .required(),

        email: Yup.string()
        .email()
        .required(),

        phone: Yup.string()
        .required(),

        problem: Yup.string()
        .required(),

        context: Yup.string()
        .required(),

        solution: Yup.string()
        .required(),

    });

    const sendingForm = (values, reset) => {
        const url = 'https://api.mailing.paisanoscreando.com//public/send_mail';
        let {name, startUpName, email, phone, problem, context, solution} = values;
        const {resetForm} = reset;
        const formData = new FormData();

        formData.append('clientName', 'Cazenave');
        formData.append('senderName', name);
        formData.append('senderEmail', email);
        formData.append('message', 
        `Nombre: ${name} <br/>
        Nombre del StartUp: ${startUpName} <br/>
        Mail: ${email} <br/>Teléfono: ${phone} <br/>
        Problema a resolver: ${problem} <br/>
        Contexto: ${context} <br/>
        ¿Cómo lo resuelven?: ${solution}`);
        formData.append('senderPhone', phone);
        formData.append('receiverEmail', 'info@e-cazenave.com.ar');
        formData.append('subject', 'Cazenave Tecnológico - StartUp');

        axios.post(url, formData)
            .then(res => {
                Swal.fire({
                    type: 'success',
                    title: 'Enviado',
                    text: 'Nos contactaremos a la brevedad'
                })
            })
            .catch(err => {
                Swal.fire({
                    type: 'error',
                    title: err.message,
                    text: 'Intenta nuevamente más tarde'
                })
            })
        resetForm({
            name: '',
            startUpName: '',
            email: '',
            phone: '',
            problem: '',
            context: '',
            solution: ''
        });
    }


    return (
        <Formik
        validationSchema={contactSchema}
        initialValues={{
            name: '',
            startUpName: '',
            email: '',
            phone: '',
            problem: '',
            context: '',
            solution: ''
        }}
        onSubmit={(values, {resetForm}) => sendingForm(values, {resetForm})}
        >
            {({handleSubmit, isValid}) => (

            <StyledForm onSubmit={handleSubmit}>
                <h4>{t('Sumate a la plataforma de Cazenave Tecnológico y forma parte de nuestro equipo con tu Startup.')}</h4>
                <FieldWrapper>
                    <label htmlFor="name">{t('Nombre')}</label>
                    <Field type="text" id="name" name="name" placeholder={t("Ingresa tu nombre aquí")} />
                </FieldWrapper>
                <FieldWrapper>
                    <label htmlFor="startUpName">{t('Nombre del startup')}</label>
                    <Field type="text" id="startUpName" name="startUpName" placeholder={t("Ingresa el nombre aquí")} />
                </FieldWrapper>
                <FieldWrapper>
                    <label htmlFor="email">{t('Email')}</label>
                    <Field type="email" id="email" name="email" placeholder={t("email@ejemplo.com")} />
                </FieldWrapper>
                <FieldWrapper>
                    <label htmlFor="phone">{t('Teléfono')}</label>
                    <Field type="text" id="phone" name="phone" placeholder={t("+549 11 0000 0000")} />
                </FieldWrapper>
                <TextAreaWrapper>
                    <label htmlFor="problem">{t('Problema a resolver')}</label>
                    <Field type="textarea" id="problem" name="problem" component="textarea" placeholder={t("Escribe una breve descripción del problema")} />
                </TextAreaWrapper>
                <TextAreaWrapper>
                    <label htmlFor="context">{t('Contexto')}</label>
                    <Field type="textarea" id="context" name="context" component="textarea" placeholder={t("Danos un contexto que se relaciona con el proyecto")} />
                </TextAreaWrapper>
                <TextAreaWrapper>
                    <label htmlFor="solution">{t('¿Cómo lo resuelven?')}</label>
                    <Field type="textarea" id="solution" name="solution" component="textarea" placeholder={t("Escribe un breve detalle de cómo resuelven el problema")} />
                </TextAreaWrapper>
                <div className="btnContainer">
                    <button type="submit" disabled={!isValid}>{t('Enviar')}</button>
                </div>
            </StyledForm>
            )}
        </Formik>
        
    )
}

export default FormTech;
