import React, { useState } from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/imagen-negocios.jpg';
import QueHacemosForm from '../../QueHacemosForm/QueHacemosForm';
import { useTranslation } from 'react-i18next';

const NegociosInmobiliarios = () => {
  const { t } = useTranslation();

  const BackgroundWrapper = styled.section`
    background-image: url(${background});
    background-size: cover;
    height: 840px;
    width: 1105px;
    max-width: 100%;
    margin: 0 auto;
    padding: 48px;
    position: relative;
  `;

  const TextWrapper = styled.div`
    position: absolute;
    bottom: 48px;
    right: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 543px;
    @media (max-width: 1105px) {
      bottom: unset;
      right: unset;
      left: 50%;
      top: 50%;
      max-width: 100%;
      transform: translate(-50%, -50%);
      padding: 20px;
    }
    h2 {
      color: #facb30;
      font-size: 3.3rem;
      font-weight: 400;
      line-height: 50px;
      text-align: center;
      margin-bottom: 30px;
    }
    p {
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 24px;
      width: 560px;
      max-width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    .link {
      border: none;
      border-radius: 39.94px;
      width: 422px;
      max-width: 45%;
      height: 58px;
      font-size: 1.7rem;
      font-weight: 600;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .buttons-container {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
    .buy-button {
      color: #ffffff;
      background-color: #175763;
    }
    .sell-button {
      background-color: #ffffff;
      color: #175763;
    }
  `;

  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <BackgroundWrapper id="negocios">
        <TextWrapper>
          <h2>{t('Negocios Inmobiliarios')}</h2>
          <p>{t('negocios-inmobiliarios-primer-parrafo_html')}</p>
          <p>
            {t(
              'Ofrecemos desde el análisis del mercado, valuación, proyectos de inversión, detección de potenciales contrapartes, negociación hasta el cierre del acuerdo.'
            )}
          </p>
          <p>
            {t(
              'Hacemos tasaciones de propiedades rurales para distintos fines y divisiones especialmente de empresas familiares.'
            )}
          </p>
          <p>
            {t(
              'Realizamos el trabajo utilizando las mejores y más modernas tecnologías disponibles, con imágenes satelitales, cartas de suelo, cartas topográficas, índice verde, etc.'
            )}
          </p>
          <p>
            {t(
              'Realizamos asesoramientos en inversiones inmobiliarias, su rentabilidad, evolución del precio de la tierra, aspectos impositivos, restricciones ambientales, etc.'
            )}
          </p>
          <p>
            {t(
              'Tenemos un equipo muy profesional, de vasta experiencia y conocimiento en la actividad.'
            )}
          </p>
          <div className="buttons-container">
            <button
              onClick={() => window.open('https://argencampos.com/inicio/')}
              className="link buy-button"
            >
              {t('Quiero Comprar un Campo')}
            </button>
            <button
              onClick={() => setModalOpened(true)}
              className="link sell-button"
            >
              {t('Quiero Vender mi Campo')}
            </button>
          </div>
        </TextWrapper>
      </BackgroundWrapper>
      <QueHacemosForm
        opened={modalOpened}
        close={() => setModalOpened(false)}
      />
    </>
  );
};

export default NegociosInmobiliarios;
