import React, { useState, useEffect } from 'react';
import Header from '../../components/Historia/Header/Header';
import styled from 'styled-components';
import Line from '../../components/Historia/Line/Line';
import LineItem from '../../components/Historia/Line/LineItem/LineItem';
import LineMobile from '../../assets/images/line-mobile-historia.svg';
import { useTranslation } from 'react-i18next';

//imagenes historia
import fundacion from '../../assets/images/Fundacion.jpg';
import adopcion from '../../assets/images/1976.jpg';
import arrendamiento from '../../assets/images/1978.jpg';
import girasol from '../../assets/images/historia-girasol.jpg';
import programa from '../../assets/images/historia-programa.jpg';
import fondo from '../../assets/images/historia-fondo.jpg';
import trigo from '../../assets/images/historia-trigo.jpg';
import banco from '../../assets/images/1997.jpg';
import utilizacion from '../../assets/images/2001.jpg';
import desarrollo from '../../assets/images/2002.jpg';
import siembras from '../../assets/images/2003.jpg';
import fideicomiso from '../../assets/images/historia-fideicomiso.jpeg';
import inter from '../../assets/images/historia-inter.jpg';
import papas from '../../assets/images/2010.jpg';
import paraguay from '../../assets/images/historia-paraguay.jpg';
import produccion from '../../assets/images/historia-produccion.jpg';
import inmobiliaria from '../../assets/images/2013.jpg';
import puma from '../../assets/images/background-unica.jpg';
import cebada from '../../assets/images/2015.jpg';
import gensusBack from '../../assets/images/imagen-gensus.jpg';
import girasoles from '../../assets/images/girasoles.jpg';
import ucropIt from '../../assets/images/ucropit.svg';
import ucropBg from '../../assets/images/ucropBg.jpg';
import cebadaCervecera from '../../assets/images/cebada-cervecera.jpg';
import fondoCasaTech from '../../assets/images/fondo-casatech.jpeg';
import campos from '../../assets/images/bg-argencampos.jpg';

//imagenes brands
import dow from '../../assets/images/dow.png';
import faid from '../../assets/images/faid.png';
import ifc from '../../assets/images/LogoIFC.png';
import reciduca from '../../assets/images/Reciducafundacion.png';
import agrositio from '../../assets/images/Logoagrositio.png';
import cm from '../../assets/images/cm.png';
import logosojitz from '../../assets/images/logosojitz.png';
import unica from '../../assets/images/iunica.svg';
import cargill from '../../assets/images/CargillLogo.png';
import gensus from '../../assets/images/GensusLogo-dark.png';
import boortmart from '../../assets/images/boortmalt.png';
import casatech from '../../assets/images/logo-casatech-black.png';
import argencampos from '../../assets/images/argencampos-logo.jpeg';

import LineLeft from '../../assets/images/line-left.svg';
import LineRight from '../../assets/images/line-right.svg';
import { ReactComponent as OvalHistory } from '../../assets/images/oval-history.svg';
import { ReactComponent as OvalBig } from '../../assets/images/OvalBig.svg';
import BeforeMap from '../../components/Historia/BeforeMap/BeforeMap';
import Map from '../../components/Historia/Map/Map';
import Spinner from '../../components/UI/Spinner/Spinner';
import Empresas from '../../components/Historia/Empresas/Empresas';
import LineItemMobile from '../../components/Historia/LineItemMobile/LineItemMobile';

const Historia = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
    setIsLoading(false);
  }, []);

  const { t } = useTranslation();
  const MainWrapper = styled.main`
    width: 100%;
    max-width: 1026.4px;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    min-height: 440rem;
    position: relative;
    @media (max-width: 1105px) {
      display: none;
    }
    .fundacion {
      position: absolute;
      width: 130px;
      height: 130px;
      left: 262px;
      top: -157px;
      transform-origin: center;
      animation: 60s linear infinite;
      animation-name: rotateLeft;

      g {
        stroke-width: 5;
        stroke-dasharray: 2, 20;
      }

      @keyframes rotateLeft {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(-1turn);
        }
      }
    }
    .adopcion {
      position: absolute;
      right: 80px;
      top: -50px;
      transform-origin: center;
      animation: 100s linear infinite;
      animation-name: rotate;

      @keyframes rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(1turn);
        }
      }
    }

    .big {
      position: absolute;
      left: 50%;
      top: 50%;
      transform-origin: center;
      animation: 100s linear infinite;
      animation-name: rotate;

      @keyframes rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(1turn);
        }
      }
    }
    .bigBottom {
      position: absolute;
      left: 30%;
      bottom: 140px;
      transform: translateX(-50%);
      transform-origin: center;
      animation: 100s linear infinite;
      animation-name: rotate;

      @keyframes rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(-1turn);
        }
      }
    }
    .bigBottom-2 {
      position: absolute;
      left: 10%;
      bottom: 10%;
      width: 700px;
      height: 700px;
      transform: translateX(-50%);
      transform-origin: center;
      animation: 100s linear infinite;
      animation-name: rotateLeft;
      g {
        stroke-dasharray: 2 11;
      }

      @keyframes rotateLeft {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(-1turn);
        }
      }
    }
    .around-year {
      position: absolute;
      left: 42%;
      top: -25px;
      width: 157px;
      height: 157px;
      transform: translateX(-50%);
      transform-origin: center;
      animation: 100s linear infinite;
      animation-name: rotate;
      g {
        stroke-dasharray: 19;
        stroke-width: initial;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(1turn);
        }
      }
    }

    .big-2 {
      position: absolute;
      left: 12%;
      top: 30%;
      width: 800px;
      height: 800px;
      transform: translateX(-50%);
      transform-origin: center;
      animation: 200s linear infinite;
      animation-name: rotate;
      g {
        stroke-dasharray: 2;
      }
    }
  `;

  const MainMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 6051.83px;
    margin-top: 220px;
    margin-bottom: 40px;
    background-image: url(${LineMobile});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media (min-width: 1106px) {
      display: none;
    }
  `;

  const biogenergia = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {t('Proyecto de Intersiembras.')}{' '}
      <span>{t('Creación de Patagonia Bioenergía.')}</span>
      <span>{t('Proyecto Colza.')}</span>
    </div>
  );
  const popcorn = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {t('Desarrollo y puesta en el mercado de Agrositio.')}{' '}
      <span>{t('Producción de Popcorn.')}</span>
    </div>
  );
  const blackriver = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {t('Proyecto y Desarrollo de Producción en Colombia.')}
      <span>{t('Integración con Black River.')}</span>
      <span>{t('Consultoría en Ucrania.')}</span>
    </div>
  );
  const paraguayText = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {' '}
      {t('Proyecto y Desarrollo de Producción en Angola.')}{' '}
      <span>{t('Consultoría en Paraguay.')}</span>
    </div>
  );
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Header />
      <MainMobile>
        <LineItemMobile
          year="1969"
          image={fundacion}
          description={t('Fundación de Cazenave')}
        />
        <LineItemMobile
          year="1976"
          image={adopcion}
          description={t('Adopción de tecnología de Siembra Directa.')}
        />
        <LineItemMobile
          year="1978"
          image={arrendamiento}
          description={t('Primeras 400 has de producción en arrendamiento.')}
        />
        <LineItemMobile
          year="1986"
          image={girasol}
          description={t('Girasol en Venezuela.')}
        />
        <LineItemMobile
          year="1990"
          image={programa}
          description={t('Programa de Girasol Alto Oleico con DOW.')}
        />
        <LineItemMobile
          year="1994"
          image={fondo}
          description={t(
            '1er Fondo Agrícola con Aprobación de la CNV y Oferta Pública.'
          )}
        />
        <LineItemMobile
          year="1995"
          image={trigo}
          description={t('Programa de Trigo Candeal y Trigo Pan de Calidad.')}
        />
        <LineItemMobile
          year="1997"
          image={banco}
          description={t(
            'IFC (Banco Mundial) Inversión en FAID (Primer Fondo Plurianual).'
          )}
        />
        <LineItemMobile
          year="2001"
          image={utilizacion}
          description={t(
            'Utilización de Silo Bolsa en gran escala. Desarrollo de programa de Reciclado de Envases.'
          )}
        />
        <LineItemMobile
          year="2002"
          image={desarrollo}
          description={t(
            'Desarrollo y puesta en el mercado de Agrositio. Producción de Popcorn.'
          )}
        />
        <LineItemMobile
          year="2003"
          image={siembras}
          description={t(
            'Programa de siembras asociadas. Creación de CM para Cobro de Regalías.'
          )}
        />
        <LineItemMobile
          year="2005"
          image={fideicomiso}
          description={t(
            'Fideicomiso Arándanos en Tucumán. Proyecto Biodiesel / Proyecto Jatropha. Ante-proyecto de producción.'
          )}
        />
        <LineItemMobile
          year="2007"
          image={inter}
          description={t(
            'Proyecto de Intersiembras. Creación de Patagonia Bioenergía. Proyecto Colza.'
          )}
        />
        <LineItemMobile
          year="2008"
          image={papas}
          description={t(
            'Proyecto y Desarrollo de Producción en Colombia. Integración con Black River. Consultoría en Ucrania.'
          )}
        />
        <LineItemMobile
          year="2010"
          image={girasoles}
          description={t('Programa de producción para Sojitz Co.')}
        />
        <LineItemMobile
          year="2011"
          image={paraguay}
          description={t(
            'Proyecto y Desarrollo de Producción en Angola. Consultoría en Paraguay.'
          )}
        />
        <LineItemMobile
          year="2012"
          image={produccion}
          description={t('Proyecto de Producción en Brasil.')}
        />
        <LineItemMobile
          year="2013"
          image={inmobiliaria}
          description={t('Desarrollo de área Inmobiliaria.')}
        />
        <LineItemMobile
          year="2014"
          image={puma}
          description={t(
            'Desarrollo de Plataforma UNICA (Ex PUMA) en Asociación con CODES SA.'
          )}
        />
        <LineItemMobile
          year="2015"
          image={cebada}
          description={t('Programa de Cebada Certificada.')}
        />
        <LineItemMobile
          year="2016"
          image={gensusBack}
          description={t('Creación de GENSUS.')}
        />
        <LineItemMobile
          year="2018"
          image={ucropBg}
          description={t(
            'Una plataforma que potencia al productor agropecuario con los beneficios que genera la sustentabilidad'
          )}
        />
        <LineItemMobile
          year="2019"
          image={cebadaCervecera}
          description={t('Producción de cebada cervecera certificada.')}
        />
        <LineItemMobile
          year="2019"
          image={fondoCasaTech}
          description={t(
            'Desarrollo de Plataforma Casa Tech para empresas de modernizacion del Agro.'
          )}
        />
        <LineItemMobile
          year="2020"
          image={campos}
          description={t(
            'Servicios para la compra y venta de campos, arrendamientos, tasaciones y divisiones.'
          )}
        />

        <LineItemMobile year="2021" today />
      </MainMobile>
      <MainWrapper style={{ marginTop: '175px' }}>
        <OvalHistory className="fundacion" />
        <OvalHistory className="adopcion" />
        <OvalBig className="big" />
        <OvalBig className="bigBottom" />
        <OvalBig className="bigBottom-2" />
        <OvalBig className="around-year" />
        <OvalBig className="big-2" />
        <OvalBig className="big-3" />
        <Line/>

        <LineItem
          image={fundacion}
          line={LineLeft}
          text={t('Fundación de Cazenave')}
          top="-155px"
          textBottom="-16px"
          width="337px"
        />
        <LineItem
          className="test"
          image={adopcion}
          line={LineRight}
          text={t('Adopción de tecnología de Siembra Directa.')}
          top="15px"
          textBottom="-36px"
          width="280px"
          right
        />

        <LineItem
          image={arrendamiento}
          line={LineLeft}
          text={t('Primeras 400 has de producción en arrendamiento.')}
          top="161px"
          textBottom="-36px"
          width="337px"
        />
        <LineItem
          image={girasol}
          line={LineRight}
          text={t('Girasol en Venezuela.')}
          top="325px"
          textBottom="-16px"
          width="280px"
          right
        />

        <LineItem
          image={programa}
          line={LineLeft}
          text={t('Programa de Girasol Alto Oleico con DOW.')}
          top="478px"
          textBottom="-36px"
          width="337px"
          whiteBrandWidth="157px"
          whiteBrandHeight="67px"
          brandWidth="79px"
          brandHeight="55px"
          topImage={dow}
        />
        <LineItem
          image={fondo}
          line={LineRight}
          text={t(
            '1er Fondo Agrícola con Aprobación de la CNV y Oferta Pública.'
          )}
          top="634px"
          textBottom="-49px"
          width="337px"
          right
          whiteBrandWidth="157px"
          whiteBrandHeight="67px"
          brandWidth="99px"
          brandHeight="55px"
          topImage={faid}
        />

        <LineItem
          image={trigo}
          line={LineLeft}
          text={t('Programa de Trigo Candeal y Trigo Pan de Calidad.')}
          top="784px"
          textBottom="-36px"
          width="337px"
        />
        <LineItem
          image={banco}
          line={LineRight}
          text={t(
            'IFC (Banco Mundial) Inversión en FAID (Primer fondo Plurianual).'
          )}
          top="954px"
          textBottom="-49px"
          width="337px"
          right
          topImage={ifc}
          whiteBrandHeight="67px"
          whiteBrandWidth="217px"
          brandHeight="31px"
          brandWidth="168px"
        />

        <LineItem
          image={utilizacion}
          line={LineLeft}
          text={t(
            'Utilización de Silo Bolsa en gran escala. Desarrollo de programa de Reciclado de Envases.'
          )}
          top="1118px"
          textBottom="-56px"
          width="337px"
          topImage={reciduca}
          whiteBrandWidth="127px"
          whiteBrandHeight="67px"
          brandWidth="60px"
          brandHeight="60px"
        />
        <LineItem
          image={desarrollo}
          line={LineRight}
          text={popcorn}
          top="1296px"
          textBottom="-56px"
          width="337px"
          right
          topImage={agrositio}
          whiteBrandHeight="67px"
          whiteBrandWidth="217px"
          brandHeight="31px"
          brandWidth="120px"
        />

        <LineItem
          image={siembras}
          line={LineLeft}
          text={t(
            'Programa de siembras asociadas. Creación de CM para Cobro de Regalías.'
          )}
          top="1507px"
          textBottom="-36px"
          width="337px"
          topImage={cm}
          whiteBrandWidth="151px"
          whiteBrandHeight="67px"
          brandWidth="67px"
          brandHeight="48px"
        />
        <LineItem
          image={fideicomiso}
          line={LineRight}
          text={t(
            'Fideicomiso Arándanos en Tucumán. Proyecto Biodiesel / Proyecto Jatropha. Ante-proyecto de producción.'
          )}
          top="1708px"
          textBottom="-56px"
          width="337px"
          right
        />

        <LineItem
          image={inter}
          line={LineLeft}
          text={biogenergia}
          top="1906px"
          textBottom="-66px"
          width="337px"
          whiteBrandWidth="151px"
          whiteBrandHeight="57px"
          carousel={true}
        />
        <LineItem
          image={papas}
          line={LineRight}
          text={blackriver}
          top="2108px"
          textBottom="-76px"
          width="337px"
          right
        />

        <LineItem
          image={girasoles}
          line={LineLeft}
          text={t('Programa de producción para Sojitz Co.')}
          top="2314px"
          textBottom="-36px"
          width="337px"
          topImage={logosojitz}
          whiteBrandWidth="169px"
          whiteBrandHeight="67px"
          brandHeight="34px"
          brandWidth="112px"
        />
        <LineItem
          image={paraguay}
          line={LineRight}
          text={paraguayText}
          top="2478px"
          textBottom="-56px"
          width="337px"
          right
        />

        <LineItem
          image={produccion}
          line={LineLeft}
          text={t('Proyecto de Producción en Brasil.')}
          top="2640px"
          textBottom="-16px"
          width="337px"
        />
        <LineItem
          image={inmobiliaria}
          line={LineRight}
          text={t('Desarrollo de área Inmobiliaria.')}
          top="2810px"
          textBottom="-16px"
          width="337px"
          right
        />

        <LineItem
          image={puma}
          line={LineLeft}
          text={t(
            'Desarrollo de Plataforma UNICA (Ex PUMA) en Asociación con CODES SA.'
          )}
          top="2970px"
          textBottom="-56px"
          width="337px"
          topImage={unica}
          whiteBrandHeight="67px"
          whiteBrandWidth="169px"
          brandWidth="111px"
          brandHeight="60px"
        />

        <LineItem
          image={cebada}
          line={LineRight}
          text={t('Programa de Cebada Cerificada.')}
          top="3140px"
          textBottom="-16px"
          width="337px"
          right
          topImage={cargill}
          whiteBrandHeight="67px"
          whiteBrandWidth="177px"
          brandHeight="42px"
          brandWidth="94px"
        />

        <LineItem
          image={gensusBack}
          line={LineLeft}
          text={t('Creación de GENSUS.')}
          top="3300px"
          textBottom="-16px"
          width="337px"
          topImage={gensus}
          whiteBrandHeight="67px"
          whiteBrandWidth="169px"
          brandWidth="111px"
          brandHeight="60px"
        />

        <LineItem
          image={ucropBg}
          line={LineRight}
          text={t(
            'Una plataforma que potencia al productor agropecuario con los beneficios que genera la sustentabilidad'
          )}
          top="3460px"
          textBottom="-48px"
          width="337px"
          right
          topImage={ucropIt}
          whiteBrandHeight="67px"
          whiteBrandWidth="169px"
          brandWidth="111px"
          brandHeight="60px"
        />

        <LineItem
          image={cebadaCervecera}
          line={LineLeft}
          text={t('Producción de cebada cervecera certificada.')}
          top="3620px"
          textBottom="-30px"
          width="337px"
          topImage={boortmart}
          whiteBrandHeight="67px"
          whiteBrandWidth="177px"
          brandHeight="60px"
          brandWidth="130px"
        />

        <LineItem
          image={fondoCasaTech}
          line={LineRight}
          text={t(
            'Desarrollo de Plataforma Casa Tech para empresas de modernizacion del Agro.'
          )}
          top="3785px"
          textBottom="-24px"
          width="337px"
          right
          topImage={casatech}
          whiteBrandHeight="67px"
          whiteBrandWidth="177px"
          brandHeight="60px"
          brandWidth="130px"
        />

        <LineItem
          image={campos}
          line={LineLeft}
          text={t(
            'Servicios para la compra y venta de campos, arrendamientos, tasaciones y divisiones.'
          )}
          top="3945px"
          textBottom="-48px"
          width="337px"
          whiteBrandHeight="85px"
          whiteBrandWidth="178px"
          brandHeight="60px"
          brandWidth="130px"
          topImage={argencampos}
        />
      </MainWrapper>
      <BeforeMap />
      <Map />
      <Empresas />
    </>
  );
};

export default Historia;
