import React from 'react';
import styled from 'styled-components';
import lineTitle from '../../../assets/images/lineBtn.svg';
import NuestrosIngenierosItem from './NuestrosIngenierosItem';
import { ReactComponent as Map } from '../../../assets/images/map-quehacemos.svg';
import CarlosFerrer from '../../../assets/images/Carlos-F.png';
import AndresCueto from '../../../assets/images/Andrés-C.png';
import AlejandroMayrs from '../../../assets/images/Alejandro-M.png';
import LuisArias from '../../../assets/images/Luis-A.png';
import BernardoElgue from '../../../assets/images/Bernardo-E.png';
import GustavoConsentino from '../../../assets/images/Gustavo-C.png';
import EstebanCabrera from '../../../assets/images/Esteban-C.png';
import AlejandroCasanegra from '../../../assets/images/Alejandro-C.png';
import IgnacioBravo from '../../../assets/images/Ignacio-B.png';
import TwoCittiesIng from './TwoCittiesIng';
import { useTranslation } from 'react-i18next';

const NuestrosIngenieros = () => {
	const { t } = useTranslation();

	const Wrapper = styled.div`
		padding: 10px;
		width: 1105px;
		max-width: 100%;
		background-color: #fafafa;
		margin: 0 auto;

		h2 {
			color: #175763;
			font-size: 2.4rem;
			font-weight: 400;
			line-height: 60px;
			text-align: center;
			width: 380px;
			max-width: 100%;
			margin: 0 auto;
			text-align: center;
			position: relative;
			&::before {
				content: '';
				width: 54px;
				height: 9px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				background-image: url(${lineTitle});
				background-repeat: no-repeat;
			}
			&::after {
				content: '';
				width: 54px;
				height: 10px;
				position: absolute;
				top: 43%;
				transform: translateY(-50%);
				right: 0;
				background-image: url(${lineTitle});
				background-repeat: no-repeat;
				transform: rotate(180deg);
			}
		}
	`;

	const IngenierosWrapper = styled.div`
		margin-top: 70px;
		min-height: 500px;
		width: 100%;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		@media (max-width: 1105px) {
			padding: 0 120px;
			justify-content: space-between;
			align-items: center;
		}
		@media (max-width: 600px) {
			padding: 0;
			justify-content: center;
		}
		.left-column {
			width: 100%;
			max-width: 380px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}

		.right-column {
			width: 100%;
			max-width: 380px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
	`;

	const textoBernardo = (
		<div>
			{t('Norte de BsAs')} <br />
			{t('Sur de Sta Fé')}
		</div>
	);
	const textoConsentino = (
		<div>
			{t('Norte y Centro')} <br />
			{t('de BsAs')}
		</div>
	);
	const textoCueto = (
		<div>
			{t('Norte de la Pampa')}
			<br />
			{t('Sur de Córdoba')}
		</div>
	);
	return (
		<Wrapper>
			<h2>{t('Nuestros Ingenieros')}</h2>
			<IngenierosWrapper>
				<div className='left-column'>
					<NuestrosIngenierosItem
						photo={CarlosFerrer}
						number='1'
						name='Carlos Ferrer'
						city={t('Sudoeste de Córdoba')}
						phone='+54 911 3088-1033'
						email='cferrer@e-cazenave.com.ar'
					/>
					<NuestrosIngenierosItem
						photo={AndresCueto}
						number='2'
						name='Andrés Cueto'
						city={textoCueto}
						phone='+54 911 3088-1036'
						email='acueto@e-cazenave.com.ar'
					/>
					<NuestrosIngenierosItem
						photo={AlejandroMayrs}
						number='3'
						name='Diego Mayr'
						city={t('Sudoeste de Córdoba Noroeste de BsAs')}
						phone='+54 911 3088-1034'
						email='dmayr@e-cazenave.com.ar'
					/>
					<NuestrosIngenierosItem
						photo={LuisArias}
						number='4'
						name='Luis Arias'
						city='Gral. Pico'
						phone='+54 911 3088-1037'
						email='larias@e-cazenave.com.ar'
					/>
					<NuestrosIngenierosItem
						photo={BernardoElgue}
						number='5'
						name='Bernardo Elgue'
						city={textoBernardo}
						phone='+54 911 3088-1038'
						email='belgue@e-cazenave.com.ar'
					/>
				</div>

				<Map style={{ maxWidth: '325px' }} />

				<div className='right-column'>
					<TwoCittiesIng
						photo={GustavoConsentino}
						firstNum='6'
						secondNum='7'
						name='Gustavo Cosentino'
						city={textoConsentino}
						phone='+54 911 3088-1041'
						email='gecosentino@e-cazenave.com.ar'
					/>
					<NuestrosIngenierosItem
						photo={EstebanCabrera}
						number='8'
						name='Esteban Cabrera'
						city='Gualeguaychú'
						phone='+54 911 3088-1054'
						email='estebancabrera@arnet.com.ar'
					/>
					<NuestrosIngenierosItem
						photo={AlejandroCasanegra}
						number='9'
						name='Alejandro Casanegra'
						city='Mar del Plata'
						phone='+54 911 3088-1039'
						email='acasanegra@e-cazenave.com.ar'
					/>
					<NuestrosIngenierosItem
						photo={IgnacioBravo}
						number='10'
						name='Ignacio Bravo'
						city='Tandil'
						phone='+54 911 3405-3120'
						email='ibravo@e-cazenave.com.ar'
					/>
				</div>
			</IngenierosWrapper>
		</Wrapper>
	);
};

export default NuestrosIngenieros;
