import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Innovacion} from '../../../assets/images/innovacion.svg';
import {ReactComponent as Confianza} from '../../../assets/images/confianza.svg';
import {ReactComponent as Equipo} from '../../../assets/images/equipo.svg';
import {ReactComponent as Excelencia} from '../../../assets/images/excelencia.svg';
import titleLine from '../../../assets/images/line-title.svg';
import Fade from 'react-reveal/Fade';
import {useTranslation} from 'react-i18next';

const Wrapper = styled.div`
position: absolute;
bottom: -17%;
left: 50%;
transform: translateX(-50%);
width: 100%;
max-width: 1280px;
min-height: 280.6px;
background-color: white;
border-radius: 28px;
box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
z-index: 3;
display: flex;
justify-content: space-around;
align-items: center;
padding: 105px 0;

@media (max-width: 1105px) {
    padding: 50px 0;
    position: static;
    flex-wrap: wrap;
    transform: unset;
}
`;

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 424.65px;
    max-width: 100%;
    position: relative;
    &:not(:last-child)::after {
        position: absolute;
        content:'';
        width: 1px;
        height: 77.51px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #dbdbdb;
        @media (max-width: 1105px) {
        display: none;
    }
    }
    @media (max-width: 1105px) {
        &:not(:first-child) {
            margin-top: 60px;
        }
    }
    svg {
        max-width: 70px;
    }

    .innovacion,
    .confianza,
    .equipo,
    .excelencia {
        position: relative;
    &::after {
        content:'';
        position: absolute;
        background-image: url(${titleLine});
        background-size: cover;
        background-repeat: no-repeat;
        width: 54px;
        height: 14px;
        bottom: -20px;
        left: -20px;
    }
    }

    span {
        color: #0B162B;
        font-size: 2.25rem;
        font-weight: 300;
        line-height: 47.42px;
        width: 324.65px;
        text-align: center;
    
    }
`;

const Valores = () => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <ItemWrapper>
                <Fade bottom delay={500}>
                <Innovacion />
                <div className="innovacion">
                <span>{t('Innovación')}</span>
                </div>
                </Fade>
            </ItemWrapper>
            <ItemWrapper>
                <Fade bottom delay={800}>
                <Confianza />
                <div className="confianza">
                <span>{t('Confianza')}</span>
                </div>
                </Fade>
            </ItemWrapper>
            <ItemWrapper>
                <Fade bottom delay={1200}>
                <Equipo />
                <div className="equipo">
                <span>{t('Trabajo en equipo')}</span>
                </div>
                </Fade>
            </ItemWrapper>
            <ItemWrapper>
                <Fade bottom delay={1500}>
                <Excelencia />
                <div className="excelencia">
                <span>{t('Excelencia')}</span>
                </div>
                </Fade>
            </ItemWrapper>
        </Wrapper>
    )
}

export default Valores;
