import React from "react";
import "./section2.css";
import icon1 from "../../assets/images/onu-2.png"
import icon2 from "../../assets/images/onu-9.png"
import icon3 from "../../assets/images/onu-12.png"
import icon4 from "../../assets/images/onu-15.png"
import icon5 from "../../assets/images/onu-17.png"

const Section2 = () => {
  return (
    <div className="section2-container">
      <div className="section2-background"></div>
      <div className="section2-content">
        <p>
          <strong>Trabajamos</strong> en cada una de nuestras unidades y
          proyectos, para contribuir a los{" "}
          <strong>Objetivos de Desarrollo Sostenible</strong> que componen la
          Agenda al <strong>2030</strong> para el Desarrollo
          Sostenible aprobada.
        </p>
        <div className="section2-icons">
          <div className="section2-icon">
            <img src={icon1} width="160px" alt="onu-objetivo"/>
          </div>
          <div className="section2-icon">
            <img src={icon2} width="160px" alt="onu-objetivo"/>
          </div>
          <div className="section2-icon">
            <img src={icon3} width="160px" alt="onu-objetivo"/>
          </div>
          <div className="section2-icon">
            <img src={icon4} width="160px" alt="onu-objetivo"/>
          </div>
          <div className="section2-icon">
            <img src={icon5} width="160px" alt="onu-objetivo"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
