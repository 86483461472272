import React from 'react';
import './how.css';
import phones from '../../assets/images/ucrop-phones.png';
import ReactPlayer from 'react-player';

const How = () => {
  return (
    <div className="how-container">
      <div className="how-elements-container">
        <p className="how-title">¿Cómo funciona la plataforma?</p>
        <p className="how-body">
          Con el seguimiento de los cultivos en toda la cadena desde el
          principio hasta el final.
        </p>
        <ReactPlayer
          url="videos/ucrop-como-funciona.mp4"
          width="85vw"
          height="100%"
          playing={true}
        />
      </div>
      <div className="how-elements-container">
        <div className="how-columns">
          <div className="how-left">
            <img src={phones} alt="phones" />
          </div>
          <div className="how-right">
            <p className="how-title">Crop Story ® - Historia de cultivo</p>
            <p className="how-body">
              El registro de las actividades agrícolas del productor posibilita
              crear la historia del cultivo cripto confidencial. Todo lo
              ingresado en la plataforma está en control del productor para
              compartirlo a su elección con actores de la cadena que valoran las
              prácticas sustentables. El registro es muy simple y está asistido
              por especialistas agrónomos a campo (Centauros locales).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default How;
