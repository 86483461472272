import React from 'react'
import Modal from '../UI/Modal/Modal';
import styled from 'styled-components';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
// import cancelar from '../../Assets/images/cancelar.svg';
import {useTranslation} from 'react-i18next';


const RightWrapper = styled.div`
height: 100%;
width: 499px;
form {
    height: 100%;
    display: flex;
    flex-direction: column;
}
`;

const FieldWrapper = styled.div`
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 64.8px;
    border: 1.2px solid #f2f2f2;
    flex-direction: column;
    label {
        font-size: 1.4rem;
        font-weight: 600;
    }
    input {
        color: #000;
        border: none;
        font-size: 1.8rem;
        &:focus {
            outline: none;
        }
        &::placeholder{
            color: #ccc;
        }
    }

    textarea {
        background-color: #FFFFFF;
        border: 1px solid var(--color-main);
        color: #000;
        font-size: 1.4rem;
        font-style: italic;
        font-weight: 700;
        letter-spacing: 0.24px;
        line-height: 23px;
        width: 100%;
        height: 140px;
        padding: 13px 26px;
        font-family: 'Open Sans', sans-serif;
        &:focus {
            outline: none;
            border-bottom: 3px solid var(--color-main);
        }
            
        &::placeholder {
            color: #6D6D6D;
            font-size: 1.4rem;
            font-style: italic;
            font-weight: 700;
            letter-spacing: 0.24px;
            line-height: 23px;
            font-family: 'Open Sans', sans-serif;
        }
    }
`;

const ButtonWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: flex-end;
margin-top: auto;
button {
    border: none;
    background-color: #165763;
    border: none;
    width: 103px;
    height: 42px;
    color: white;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        cursor: not-allowed;
        background-color: #b8b8b8;
        color: #333;
    }
}
`;


const TextAreaWrapper = styled.div`
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 154.8px;
    border: 1.2px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    label {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 14px;
    }
    textarea {
        height: 100%;
        color: #000;
        border: none;
        font-size: 1.8rem;
        font-family: 'Open Sans', sans-serif;
        &:focus {
            outline: none;
        }
        &::placeholder{
            color: #ccc;
            font-family: 'Open Sans', sans-serif;
        }
    }
    `;
// const CrossWrapper = styled.div `
// position: absolute;
// top: 20px;
// right: 20px;
// width: 16px;
// height: 16px;
// .btnClose {
//     height: 100%;
//     width: 100%;
//     border: none;
//     background: none;
//     cursor: pointer;
//     img {
//         width: 16px;
//     }
// }
// `;

const QueHacemosForm = ({opened, close}) => {

    const {t} = useTranslation();

    const contactSchema = Yup.object().shape({

        name: Yup.string()
        .required(),

        email: Yup.string()
        .email()
        .required(),

        asunto: Yup.string()
        .required(),

    });

    const sendingForm = (values, reset) => {
        const url = 'https://api.mailing.paisanoscreando.com/public/send_mail';
        let {name, email, asunto} = values;
        const {resetForm} = reset;
        const formData = new FormData();

        formData.append('clientName', 'Cazenave');
        formData.append('senderName', name);
        formData.append('senderEmail', email);
        formData.append('message', `Nombre: ${name} <br/>Mail: ${email} <br/>Asunto: ${asunto}`);
        formData.append('senderPhone', '-');
        formData.append('receiverEmail', 'info@e-cazenave.com.ar');
        formData.append('subject', 'Quiero vender mi campo - Felipe Elizalde');

        axios.post(url, formData)
        .then(res => {
            Swal.fire({
                type: 'success',
                title: 'Enviado',
                text: 'Nos contactaremos a la brevedad'
              })
        })
        .catch(err => {
            Swal.fire({
                type: 'error',
                title: err.message,
                text: 'Intenta nuevamente más tarde'
              })
        })
        resetForm({
            name: '',
            email: '',
            asunto: ''
        });
        
    }

    return (
        <Modal opened={opened} close={close}>
            <RightWrapper>
                <Formik
                validationSchema={contactSchema}
                enableReinitialize
                initialValues={{
                    name: '',
                    email: '',
                    asunto: ''
                }}
                onSubmit={(values, {resetForm}) => sendingForm(values, {resetForm})}
                >
                {({handleSubmit, isValid}) => (
                    <Form onSubmit={handleSubmit}>
                        <h3 style={{display:'flex', justifyContent:'center', alignItems:'center', height:'80px', fontSize:'15px'}}>{t('Contactate con Fernando Elizalde')}</h3>
                        <FieldWrapper>
                            <label htmlFor="name">{t('Nombre')}</label>
                            <Field type="text" placeholder={t("Ingresa tu nombre aquí")} name="name" />
                        </FieldWrapper>
                        <FieldWrapper>
                            <label htmlForemail>{t('Email')}</label>
                            <Field type="email" placeholder={t("Ingresa tu email aquí")} name="email"/>
                        </FieldWrapper>
                        <TextAreaWrapper>
                            <label htmlFor="asunto">{t('Asunto')}</label>
                            <Field type="text" placeholder={t("Ingresa tu descripción aquí")} name="asunto" component="textarea"/>
                        </TextAreaWrapper>
                        <ButtonWrapper>
                            <button type="submit" disabled={!isValid}>{t('Enviar')}</button>
                        </ButtonWrapper>
                    </Form>
                )}
                </Formik>
            </RightWrapper>
        </Modal>
    )
}

export default QueHacemosForm;
