import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Header from '../../components/Contacto/Header/Header';
import ContactForm from '../../components/Contacto/ContactForm/ContactForm';
import Spinner from '../../components/UI/Spinner/Spinner';

const Contacto = () => {

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scroll({top: 0, left:0})
        setIsLoading(false);
    }, []);

    const Space = styled.div`
    position: relative;
    background-color: white;
    width: 100%;
    height: 538px;
    `;

    return (
        isLoading ?
        <Spinner /> :
        <>
            <Header />
            <Space>
                <ContactForm />
            </Space>
        </>
    )
}

export default Contacto;
