import React, { useEffect } from "react";
import styled from 'styled-components';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from "react-simple-maps"
import ReactTooltip from "react-tooltip"
import mapa from '../../../data/world-50m.json';
import {useTranslation} from 'react-i18next';

const wrapperStyles = {
  width: "100%",
  maxWidth: '100%',
  margin: "0 auto",
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#f2f2f2'
}

const Map = () => {
  
    useEffect( () => {
      setTimeout(() => {
        ReactTooltip.rebuild();
      }, 100)
    }, [])

    const {t} = useTranslation();

    const StyledTitle = styled.div`
    width: 100%;
    background-color: #f2f2f2;
    padding: 51px 0;
    @media (max-width: 1105px) {
      padding: 20px;
    }
    h2 {
      span {
        background-color: #f2f2f2;
        color: #626262;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0.43px;
        line-height: 18px;
        text-align: left;
        margin-bottom: 30px;
      }
      display: flex;
      flex-direction: column;
      color: #175763;
      font-size: 3.3rem;
      font-weight: 300;
      letter-spacing: -0.09px;
      line-height: 40px;
      text-align: left;
      max-width: 1280px;
      margin: 0 auto;
    }
    `;

    return (
      <>
      <StyledTitle>
        <h2>
          <span>{t('CONEXIÓN')}</span>
          {t('Apertura al mundo')}
        </h2>
      </StyledTitle>
      <div style={wrapperStyles}>
        <ComposableMap
          projectionConfig={{
            scale: 205,
          }}
          width={980}
          height={551}
          style={{
            width: "1180px",
            height: "auto",
          }}
          >
          <ZoomableGroup center={[0,20]} disablePanning>
            <Geographies geography={mapa}>
              {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                <Geography
                  key={i}
                  data-tip={geography.properties.name}
                  geography={geography}
                  projection={projection}
                  style={{
                    default: {
                      fill: "#ECEFF1",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                    hover: {
                      fill: "#607D8B",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                    pressed: {
                      fill: "#FF5722",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                  }}
                />
              ))}
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <ReactTooltip className="test" />
      </div>
      </>
    )
  
}

export default Map;