import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Oval} from '../../../../assets/images/tech-oval.svg';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import {useTranslation} from 'react-i18next';


const OrganizationItem = () => {
    const {t} = useTranslation();
    const ItemWrapper = styled.div`
        width: 500px;
        height: 280px;
        display: flex;
        position: absolute;
        top: 35%;
        right: 27%;
        color: #FFFFFF;
        font-size: 3.35rem;
        font-weight: 400;
        line-height: 55px;
        text-align: center;
        z-index: 0;
        width: 200px;
        height: 200px;
        @media (max-width: 1105px) {
            display: none;
        }
        span {
            width: 300px;
            position: absolute;
            right: -290px;
            top: 46px;
        }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width:40px;
                height:40px;
                background-color: #fff;
                border-radius: 50%;
                opacity: 0.12;
            }
            
        .oval {
            position: absolute;
            top: 0;
            left: 0;
            width: 200px;
            height: 200px;
            transform-origin: center;
            animation: 90s linear infinite;
            animation-name: rotateRight;
            transform: scale(0);
            
            @keyframes rotateRight {
                0% {
                    transform: rotate(0);
                }

                100% {
                    transform: rotate(-1turn);
                }
            }
        }
        .line {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 1.5px;
            animation: animate .2s linear;
            background-color: rgba(255, 255, 255, .3);
            animation-fill-mode: forwards;
            @keyframes animate {
                0% {
                    width: 0;
                }

                100% {
                    width: 400px;
                }
            }
        }
    `;

    return (
        
        <ItemWrapper>
            <Fade right delay={1000}>
            <span>{t('En Originación')}</span>
            </Fade>
            <Oval className="oval" />

            <Reveal effect="line" delay={500}>
            <div />
            </Reveal>
            
            
        </ItemWrapper>
        
    )
}

export default OrganizationItem;
