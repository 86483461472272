import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import inversion from '../../../assets/images/imagen-inversion.jpg';
import lineBtn from '../../../assets/images/lineBtn.svg';
import { useTranslation } from 'react-i18next';

const Siembra = () => {
  const { t } = useTranslation();

  const isBottom = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight;
  };

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  });

  const trackScrolling = () => {
    const siembraSection = document.getElementById('siembra');
    const originacionSection = document.getElementById('originacion');
    const negociosSection = document.getElementById('negocios');
    const gensusSection = document.getElementById('gensus');
    const consultoriaSection = document.getElementById('consultoria');
    const agriculturaSatelitalSection = document.getElementById(
      'agricultura-satelital'
    );
    const siembraLinked = document.getElementById('siembraLink');
    const originacionLinked = document.getElementById('originacionLink');
    const negociosLinked = document.getElementById('negociosLink');
    const gensusLinked = document.getElementById('gensusLink');
    const consultoriaLinked = document.getElementById('consultoriaLink');
    const agriculturaSatelitalLinked = document.getElementById(
      'agriculturaSatelitalLink'
    );
    if (isBottom(siembraSection)) {
      siembraLinked.classList.add('active');
      originacionLinked.classList.remove('active');
      negociosLinked.classList.remove('active');
      gensusLinked.classList.remove('active');
      consultoriaLinked.classList.remove('active');
      agriculturaSatelitalLinked.classList.remove('active');
    }
    if (isBottom(originacionSection)) {
      siembraLinked.classList.remove('active');
      originacionLinked.classList.add('active');
      negociosLinked.classList.remove('active');
      gensusLinked.classList.remove('active');
      consultoriaLinked.classList.remove('active');
      agriculturaSatelitalLinked.classList.remove('active');
    }
    if (isBottom(negociosSection)) {
      siembraLinked.classList.remove('active');
      originacionLinked.classList.remove('active');
      negociosLinked.classList.add('active');
      gensusLinked.classList.remove('active');
      consultoriaLinked.classList.remove('active');
      agriculturaSatelitalLinked.classList.remove('active');
    }
    if (isBottom(gensusSection)) {
      siembraLinked.classList.remove('active');
      originacionLinked.classList.remove('active');
      negociosLinked.classList.remove('active');
      gensusLinked.classList.add('active');
      consultoriaLinked.classList.remove('active');
      agriculturaSatelitalLinked.classList.remove('active');
    }
    if (isBottom(consultoriaSection)) {
      siembraLinked.classList.remove('active');
      originacionLinked.classList.remove('active');
      negociosLinked.classList.remove('active');
      gensusLinked.classList.remove('active');
      consultoriaLinked.classList.add('active');
      agriculturaSatelitalLinked.classList.remove('active');
    }
    if (isBottom(agriculturaSatelitalSection)) {
      siembraLinked.classList.remove('active');
      originacionLinked.classList.remove('active');
      negociosLinked.classList.remove('active');
      gensusLinked.classList.remove('active');
      consultoriaLinked.classList.remove('active');
      agriculturaSatelitalLinked.classList.add('active');
    }
  };

  const handleScrollSiembra = () => {
    window.scrollTo(0, 800);
  };
  const handleScrollOriginacion = () => {
    window.scrollTo(0, 2550);
  };
  const handleScrollNegocios = () => {
    window.scrollTo(0, 3850);
  };
  const handleScrollGensus = () => {
    window.scrollTo(0, 4750);
  };
  const handleScrollConsultoria = () => {
    window.scrollTo(0, 5550);
  };
  const handleScrollAgriculturaSatelital = () => {
    window.scroll(0, 6350);
  };

  const ImageWrapper = styled.div`
    margin: 0 auto;
    width: 1105px;
    max-width: 100%;
    height: 800px;
    position: relative;
    background-image: url(${inversion});
    background-size: cover;
    background-position: center;
    @media (max-width: 1105px) {
      height: 840px;
    }
  `;

  const TextWrapper = styled.div`
    position: absolute;
    right: 70px;
    bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1105px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      right: unset;
      bottom: unset;
      width: 100%;
      padding: 20px;
    }
    h2 {
      color: #facb30;
      font-size: 3.3rem;
      font-weight: 600;
      line-height: 60px;
      margin-bottom: 10px;
      @media (max-width: 1105px) {
        font-size: 2.4rem;
      }
    }
    p {
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 24px;
      width: 550px;
      max-width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      a:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 39.94px;
        width: 232px;
        height: 58px;
        color: #175763;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 19px;
      }
      a:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 39.94px;
        width: 278px;
        height: 58px;
        color: #175763;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 19px;
        text-align: center;
      }
    }
  `;

  const StyledNavContainer = styled.div`
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    width: 100%;
    padding: 16px 0;
    position: sticky;
    top: 122px;
    z-index: 10;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    @media (max-width: 1105px) {
      display: none;
    }
  `;

  const StyledNav = styled.div`
    width: 100%;
    max-width: 970px;
    display: flex;
    justify-content: space-between;
  `;

  const StyledNavLink = styled.button`
    color: #aaa;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 19px;
    width: 155px;
    background-color: transparent;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    &:hover {
      color: #175763;
    }

    &.active {
      background-image: url(${lineBtn});
      background-repeat: no-repeat;
      background-position: center bottom;
      color: #175763;
    }
  `;
  return (
    <>
      <StyledNavContainer>
        <StyledNav>
          <StyledNavLink
            onClick={() => handleScrollSiembra()}
            id="siembraLink"
            href="#siembra"
          >
            {t('Siembra')}
          </StyledNavLink>
          <StyledNavLink
            onClick={() => handleScrollOriginacion()}
            id="originacionLink"
            href="#originacion"
          >
            {t('Originación')}
          </StyledNavLink>
          <StyledNavLink
            onClick={() => handleScrollNegocios()}
            id="negociosLink"
            href="#negocios"
          >
            Real Estate
          </StyledNavLink>
          <StyledNavLink
            onClick={() => handleScrollGensus()}
            id="gensusLink"
            href="#gensus"
          >
            Gensus
          </StyledNavLink>
          <StyledNavLink
            onClick={() => handleScrollConsultoria()}
            id="consultoriaLink"
            href="#consultoria"
          >
            {t('Consultoría')}
          </StyledNavLink>
          <StyledNavLink
            onClick={() => handleScrollAgriculturaSatelital()}
            id="agriculturaSatelitalLink"
            href="#agriculturaSatelital"
          >
            Agricultura Satelital
          </StyledNavLink>
        </StyledNav>
      </StyledNavContainer>
      <ImageWrapper id="siembra">
        {/* <img src={inversion} alt="Producción e Inversión Agrícola"/> */}
        <TextWrapper>
          <h2>{t('Producción e Inversión Agrícola')}</h2>
          <p>
            {t(
              'Empezamos en el negocio de siembras en el año 1978. Más de cuarenta campañas y un eficiente trabajo en red nos permitieron crecer hacia otros esquemas de producción y exportar el modelo hacia otros países.'
            )}
          </p>
          <p>
            {t(
              'Nuestro principal foco es lograr la plena satisfacción del cliente y/o inversor tanto en la rentabilidad como en el logro de la producción deseada en calidad y cantidad. Al mismo tiempo, respetamos y fomentamos las normas de cuidado ambiental y el cuidado de las personas involucradas en el proceso productivo. Dicha producción está basada en la alta tecnología, el conocimiento local, y una red de ingenieros capacitados para cumplimentar estos procesos y avanzar hacia la digitalización.'
            )}
          </p>
          <p>
            {t(
              'Producimos commodities y specialties bajo diferentes modalidades dando servicios para otras compañías; administrando fondos/fideicomisos públicos; a través de un esquema de siembras asociadas.'
            )}
          </p>
          <p>
            {t(
              'Brindamos un servicio integral de administración agropecuaria. Sumamos más de 1 millón y medio de Hectáreas Sembradas y 1.300 millones de usd de valor de producto vendido.'
            )}
          </p>
          <div className="buttons">
            <Link to="/inverti">{t('Alquilá tu Campo')}</Link>
            <Link to="/inverti">
              {t('Invertir en Fideicomiso')}
              <br /> {t('de Siembras')}
            </Link>
          </div>
        </TextWrapper>
      </ImageWrapper>
    </>
  );
};

export default withRouter(Siembra);
