import React from 'react';
import styled from 'styled-components';
import siembraBackground from '../../../assets/images/cosechadora.jpg';
import SiembraItem from './SiembraItem/SiembraItem';
import OrganizationItem from './OrganizationItem/OrganizationItem';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

import CountUp from 'react-countup';
import OrganizacionMobile from './OrganizationItem/OrganizacionMobile';
import SiembraMobile from './SiembraItem/SiembraMobile';
import { useTranslation } from 'react-i18next';

const StyledSection = styled.section`
  position: absolute;
  clip-path: polygon(0 14%, 100% 0, 100% 100%, 0% 100%);
  width: 100%;
  margin-top: -60px;
  z-index: 1;
  height: auto;
  img {
    width: 100%;
  }

  @media (max-width: 1105px) {
    position: relative;
    width: unset;
    height: 660px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0;
    clip-path: unset;
    img {
      height: 100%;
      width: unset;
    }
  }
  .data {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 44%;
    left: 73%;
    height: 70px;
    @media (max-width: 1105px) {
      display: none;
    }
    .count {
      height: 100%;
      position: relative;
      font-size: 6rem !important;
      color: #ffffff;
      font-size: 7.82rem;
      font-weight: 600;
      letter-spacing: 2.61px;
      text-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.5);
    }
    span {
      position: absolute;
      bottom: -25px;
      right: 5px;
      color: #ffffff;
      font-size: 1.26rem;
      font-weight: 600;
      letter-spacing: 0 !important;
      line-height: 19px;
      text-align: left;
    }
  }

  .data2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 44%;
    left: 82%;
    width: 146px;
    height: 92px;
    @media (max-width: 1440px) {
      left: 85%;
    }
    @media (max-width: 1105px) {
      display: none;
    }
    .count {
      height: 100%;
      position: relative;
      font-size: 60rem !important;
      color: #ffffff;
      font-size: 7.82rem;
      font-weight: 600;
      letter-spacing: 2.61px;
      text-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.5);
    }
    span {
      position: absolute;
      width: 100%;
      bottom: -42px;
      right: 17px;
      color: #ffffff;
      font-size: 1.26rem;
      letter-spacing: 0 !important;
      line-height: 19px;
      text-align: right;
    }
  }
`;

const Siembra = () => {
  const { t } = useTranslation();

  const StyledRandom = styled.div`
    height: 100%;
    position: relative;
    font-size: 6rem !important;
    color: #ffffff;
    font-size: 7.82rem;
    font-weight: 600;
    letter-spacing: 2.61px;
    text-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.5);
    span {
      width: 100%;
    }
    @media (max-width: 1105px) {
    }
  `;
  return (
    <StyledSection>
      <img src={siembraBackground} alt="Siembra" />
      <SiembraItem />
      <OrganizationItem />
      <OrganizacionMobile />
      <SiembraMobile />
      <div className="data">
        <Fade delay={1000}>
          <CountUp
            className="count"
            delay={2}
            start={0}
            end={2}
            decimal="."
            decimals={1}
            prefix="+"
            redraw={true}
          >
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <Fade delay={1000}>
                  <StyledRandom ref={countUpRef} />
                </Fade>
              </VisibilitySensor>
            )}
          </CountUp>
          <span>MM {t('HAS.')}</span>
        </Fade>
      </div>
      <div className="data2">
        <Fade delay={1000}>
          <CountUp
            className="count"
            delay={2}
            start={0}
            end={1.6}
            decimal="."
            decimals={1}
            prefix="+"
            redraw={true}
          >
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <StyledRandom ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span>
            B USD. <br /> {t('Valor Producto Originado')}
          </span>
        </Fade>
      </div>
    </StyledSection>
  );
};

export default Siembra;
