import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/cazenave-logo-white.svg';
import facebook from '../../assets/images/facebook-white.svg';
import twitter from '../../assets/images/twitter-white.svg';
import instagram from '../../assets/images/instagram-white.svg';
import { useTranslation } from "react-i18next";

const StyledFooter = styled.footer`
width: 100%;
height: 322px;
background-color: #1C1C1C;

.footerContainer {
    width: 1310px;
    max-width: 100%;
    margin: 0 auto;
    padding: 53px 30px 19px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media (max-width: 1105px) {
        align-items: center;
        flex-wrap: wrap;
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 1105px) {
            height: 200px;
            flex-direction: column;
            flex-wrap: wrap;
        }

        .imgContainer {
            width: 159.39px;
            height: 34.92px;

            img {
                width: 100%;
            }
        }
    }

    .copy {
        color: #FFFFFF;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 20px;
        a {
            color: #FFFFFF;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .info {
        display: flex;
        position: relative;
        @media (max-width: 1105px) {
            height: 100px;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
        }

        .tel,
        .mail {
            color: #FFFFFF;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 22px;
            margin: 0 20px;
            
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: -20px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 19.5px;
                background-color: #bababa;
                opacity: .29;
                @media (max-width: 1105px) {
                    display: none;
                }
            }
        }
    }
    .socials {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .twitter {
            background-image: url(${twitter});
            background-size: cover;
            width: 16.31px;
            height: 13.31px;
            margin: 0 10px;
        }
        .facebook {
            background-image: url(${facebook});
            background-size: cover;
            background-position: center;
            width: 7.31px;
            height: 16.31px;
            margin: 0 10px;
        }
        .instagram {
            background-image: url(${instagram});
            background-size: cover;
            width: 15.31px;
            height: 15.31px;
            margin-left: 10px;
        }
    }
} 
`;

const Footer = () => {
    const { t } = useTranslation();
    
    return (
        <StyledFooter>
            <div className="footerContainer">
                <div className="content">
                    <div className="imgContainer">
                        <img src={logo} alt="Cazenave"/>
                    </div>
                    <div className="info">
                        <div className="tel">{t('Tel +54 11 4763-5500')}</div>
                        <div className="mail">info@cazenave.com.ar</div>
                        <div className="socials">
                            <a className="twitter" href="https://twitter.com/cazenaveok" target="_blank" rel="noopener noreferrer"> </a>
                            <a className="facebook" href="https://www.facebook.com/cazenaveyasociados/" target="_blank" rel="noopener noreferrer"> </a>
                            <a className="instagram" href="https://instagram.com/cazenaveyasociadosok?igshid=2zndymn83kmi" target="_blank" rel="noopener noreferrer"> </a>
                        </div>
                    </div>
                </div>
                <div className="copy">
                Cazenave © All Rights Reserved. Design by <a href="https://www.paisanoscreando.com.ar" target="_blank" rel="noopener noreferrer">Paisanos.io</a>
                </div>
            </div>
        </StyledFooter>
    )
}

export default Footer;
