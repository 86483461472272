import React from 'react';
import styled from 'styled-components';
import pop from '../../../assets/images/quehacemos-pop.png';
import boortmalt from '../../../assets/images/boortmalt.png';
import viterra from '../../../assets/images/viterra.png';
import bayacasal from '../../../assets/images/bayacasal.png';
import seda from '../../../assets/images/quehacemos-seda.png';
import bhb from '../../../assets/images/bhb.png';

const BrandsSiembra = () => {
  const BrandsContainer = styled.section`
    width: 100%;
    max-width: 1105px;
    height: 326px;
    background-color: #fafafa;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-template-rows: 1fr;
    flex-wrap: wrap;
    @media (max-width: 1105px) {
      grid-template-rows: 1fr 1fr 1fr;
    }
    .imgContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 12rem;
    }
  `;
  return (
    <BrandsContainer>
      <div className="imgContainer">
        <img src={viterra} alt="" />
      </div>
      <div className="imgContainer">
        <img src={bayacasal} alt="" />
      </div>
      <div className="imgContainer">
        <img src={seda} alt="" />
      </div>
      <div className="imgContainer">
        <img src={pop} alt="" />
      </div>
      <div className="imgContainer">
        <img src={boortmalt} alt="" className="re-size" />
      </div>
      <div className="imgContainer">
        <img src={bhb} alt="" />
      </div>
    </BrandsContainer>
  );
};

export default BrandsSiembra;
