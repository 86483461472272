import React, { useState, useEffect } from 'react';
import background from '../../../assets/images/background-header.jpg';
import { ReactComponent as Mouse } from '../../../assets/images/mouse-scroll.svg';
import { ReactComponent as TechOval1 } from '../../../assets/images/tech-oval.svg';
import { ReactComponent as TechOval2 } from '../../../assets/images/tech-oval.svg';
import { ReactComponent as LineFade } from '../../../assets/images/line-header-fade.svg';
import { ReactComponent as LineWhite } from '../../../assets/images/line-header-white.svg';
import { ReactComponent as Rect } from '../../../assets/images/rectangle-header.svg';
import { ReactComponent as RectWhite } from '../../../assets/images/rectangle-header.svg';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Jump from 'react-reveal/Jump';
import Pulse from 'react-reveal/Pulse';
import Particles from 'react-particles-js';
import { useTranslation } from 'react-i18next';

const StyledHeader = styled.header`
background-image: url(${ background });
background-size: cover;
background-position: top;
height: 100%;
min-height: 102vh;
position: relative;
@media (max-width: 1105px) {
    div {
        canvas {
            display: none;
        }
    }
}
`;

const TextContainer = styled.div`
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 15%;
@media (max-width: 1500px) {
    top: 65%;
    right: 10%;
}
`;

const StyledText = styled.div`
h1{
display: flex;
flex-direction: column;
color: var(--color-white);
font-size: 4.5rem;
font-weight: 300;
line-height: 45px;
span {
    color: var(--color-white);
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 55px;
    max-width: 400px;
}
}
`;

const StyledMouse = styled.div`
position: absolute;
bottom: 40px;
left: 50%;
transform: translateX(-50%);
@media (max-width: 1105px) {
    display: none;
}
`;



const StyledOvalContainer = styled.div`
position: absolute;
top: 50%;
left: 12%;
transform: translate(-50%, -50%);
width: 178px;
height: 178px;
@media (max-width: 1105px) {
    display: none;
}
svg {
    width: 100%;
    opacity: 1;
    transform-origin: 50% 50%;
    animation: 4s linear;
    animation-name: ovalRotate;
    animation-fill-mode: forwards;
    @keyframes ovalRotate {
        0% {
            transform: rotate(0);
            opacity: 1;
        }
        75% {
            opacity: 1;
        }
        
        100% {
            transform: rotate(1turn);
            opacity: 0;
        }
    }
}
`;

const StyledLineFade = styled.div`
position: absolute;
left:19%;
top: 58%;
transform: translate(-50%, -50%);
width: 339px;
height: 234px;
@media (max-width: 1700px) {
    width: 290px;
    height: 210px;
}
@media (max-width: 1105px) {
    display: none;
}
svg {
    width: 100%;
    height: 234px;
    transform-origin: 50% 50%;
    animation: .4s linear;
    animation-name: apearF;
    animation-fill-mode: forwards;
    transform: scale(0);
    animation-delay: 3s;
    @media (max-width: 1440px) {
    height: 180px;
    opacity: 1;
}
    @keyframes apearF {
        0% {
            transform: scale(0);
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            
            transform: scale(1);
            opacity: 0;
        }
    }
}
`;

const StyledOval2 = styled.div`
position: absolute;
left: 27.5%;
top: 69.5%;
transform: translate(-50%, -50%);
width: 312px;
height: 312px;
@media (max-width: 1440px) {
    width: 230px;
    height: 230px;
    top: 65.5%;
}
@media (max-width: 1440px) and (max-height: 766px) {
    top: 67.5%;
    left: 27.8%;
}
@media (max-width: 1105px) {
    display: none;
}
svg {
    width: 100%;
    height: 100%;
    opacity: 1;
    animation: 5s linear;
    animation-name: ovalRotateLeft;
    animation-fill-mode: forwards;
    @keyframes ovalRotateLeft {
        0% {
            transform: rotate(0);
            opacity: 1;
        }
        85%{
            opacity: 1;
        }
        100% {
            transform: rotate(-1turn);
            opacity: 0;
        }
    }
}
`;

const StyledLineWhite = styled.div`
position: absolute;
top: 42.6%;
left: 27.28%;
@media (max-width: 1700px) {
    top: 43.5%;
    left: 27.22%;
    width: 373px;
}
@media (max-width: 1440px) {
    width: 337px;
}
@media (max-width: 1440px) and (max-height: 766px) {
    top: 42.2%;
    left: 27.6%;
    width: 300px;
}
@media (max-width: 1105px) {
    display: none;
}
svg {
    width: 100%;
    transform-origin: 50% 50%;
    animation: .5s linear;
    animation-name: apear;
    animation-fill-mode: forwards;
    transform: scale(0);
    animation-delay: 4s;
    opacity: 1;
    @keyframes apear {
        0% {
            transform: scale(0);
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity:0;
        }
    }
    @media (max-width: 1440px){
        height: 207px;
    }
}
`;

const StyledRect = styled.div`
position: absolute;
top: 42.5%;
left: 49.7%;
transform: translate(-50%, -50%);
width: 314px;
height: 314px;
svg {
    width: 314px;
    height: 314px;
}
@media (max-width: 1105px) {
    display: none;
}
`;

const StyledRectWhite = styled.div`
position: absolute;
top: 27%;
left: 41.6%;
transform: translate(-50%, -50%);
width: 314px;
height: 314px;
transform: rotate(45deg);
@media (max-width: 1700px) {
    left: 40.6%;
}
@media (max-width: 1440px) {
    left: 39%;
    top: 26%;
}
@media (max-width: 1105px) {
    left: 35%;
}
@media (max-width: 1440px) and (max-height: 766px) {
    top: 21.5%;
}
@media (max-width: 1105px) {
    display: none;
}
svg {
    width: 314px;
    height: 314px;
    g {
        opacity: 1;
        
        g{
            stroke-dasharray: 1300;
            stroke-dashoffset: 1300;
            animation: lineF 1s linear;
            animation-delay: 5s;
            animation-fill-mode: forwards;
            @keyframes lineF {
                to {
                    stroke-dashoffset: 0;
                }
            }
        }
    }
}
`;

const StyledPulse = styled.div`
position: absolute;
width: 30px;
height: 30px;
top: 41.3%;
left: 48.9%;
transform: translate(-50%, -50%);
background-color: white;
border-radius: 50%;
opacity: .2;
transform-origin: 50% 50%;
animation: .3s linear;
animation-delay: 4.5s;
animation-name: pulse;
transform: scale(0);
animation-fill-mode: forwards;
@keyframes pulse {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(3);
    }
}
@media (max-width: 1105px) {
    display: none;
}
`;



const HeaderMobile = () =>
{
    const { t } = useTranslation();
    const [ isTop, setIsTop ] = useState( false );
    let animationMouse = isTop ?
        <Jump
            count={ 1 }
            when={ false }
        ><Mouse /></Jump>
        :
        <Jump
            delay={ 9000 }
            appear={ true }
            when={ true }
            count={ 2 }
        ><Mouse /></Jump>

    useEffect( () =>
    {
        document.addEventListener( "scroll", () =>
        {
            let up = window.scrollY;
            if ( up > 10 )
            {
                setIsTop( true )
            } else
            {
                setIsTop( false )
            }
        } )

    }, [] )

    const particles = <Particles
        params={ {
            "particles": {
                "number": {
                    "value": 50
                },
                "size": {
                    "value": 3
                }
            },
            "interactivity": {
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "repulse"
                    }
                }
            }
        } }
    />

    return (
        <>
            <StyledHeader>
                { particles }
                <StyledOvalContainer>
                    <Fade delay={ 2000 }>
                        <TechOval1 />
                    </Fade>
                </StyledOvalContainer>
                <StyledLineFade>
                    <LineFade />
                </StyledLineFade>
                <StyledOval2>
                    <Fade delay={ 3500 }>
                        <TechOval2 />
                    </Fade>
                </StyledOval2>
                <StyledLineWhite>
                    <LineWhite />
                </StyledLineWhite>
                <StyledRect>
                    <Pulse delay={ 6500 }>
                        <Fade delay={ 6000 }>
                            <Rect />
                        </Fade>
                    </Pulse>
                </StyledRect>
                <StyledRectWhite>
                    <Pulse delay={ 6250 }>
                        <RectWhite />
                    </Pulse>
                </StyledRectWhite>
                <StyledPulse />
                <TextContainer>
                    <Fade delay={ 6500 }>
                        <Pulse delay={ 7300 }>
                            <StyledText>
                                <h1>{ t( 'El puente hacia' ) } <span>{ t( 'el futuro del agro' ) }</span></h1>
                            </StyledText>
                        </Pulse>
                    </Fade>
                </TextContainer>

                <StyledMouse>
                    { animationMouse }
                </StyledMouse>
            </StyledHeader>
        </>
    )
}

export default HeaderMobile;