import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/background-prensa.jpg';

const Header = () => {

    const StyledHeader = styled.header`
    position: relative;
    min-height: 378px;
    background-image: url(${background});
    background-size: cover;
    background-position-y: 40%;
    background-position-x: center;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 1280px;
        max-width: 100%;
        height: 36.5px;
        background-color: white;
    }

    /* h2 {
        color: #FFFFFF;
        font-size: 82px;
        font-weight: 600;
        line-height: 98px;
        width: 376px;
        max-width: 100%;
        text-align: center;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
    } */

    `;

    return (
        <StyledHeader />
    )
}

export default Header;
