import React from 'react';
import './offer.css';
import simplificamos from '../../assets/images/ucrop-simplificamos.png';

const Offer = () => {
  return (
    <div className="how-container">
      <div className="how-elements-container">
        <p className="how-title">¿Qué ofrecemos?</p>
        <div className="offer-body">
          <div className="leaf-offer">
            <p className="leaf-text">
              Plataforma
              <br />
              gratuita
            </p>
          </div>
          <div className="leaf-offer">
            <p className="leaf-text">
              Plataforma que <br />
              funciona a campo
            </p>
          </div>
          <div className="leaf-offer">
            <p className="leaf-text">
              Asistencia a <br />
              campo 360°
            </p>
          </div>
          <div className="leaf-offer">
            <p className="leaf-text">
              Ecosistema
              <br /> de beneficios
              <br />
              económicos
            </p>
          </div>
        </div>
        <div className="extra-offer">
          <div className="extra-offer-title">
            ¡Y además! <br />
            Ingenieros a campo (Centauros locales) <br />
            Cobertura en todas las zonas agrícolas <br />
            En ucrop.it sustentabilidad es + Rentabilidad.
          </div>
          <div>
            <div className="how-element-right">
              <img src={simplificamos} alt="simplificamos" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
