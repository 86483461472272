import React from 'react';
import './whatisit.css';
import person from '../../assets/images/ucrop-person.png';

const WhatIsIt = () => {
  return (
    <div className="wit-container">
      <div className="wit-elements-container">
        <div className="wit-element left">
          <p className="wit-subtitle">¿Qué es ucrop.it?</p>
          <p className="wit-body">
            ucrop.it es una plataforma digital gratuita que acompaña al
            productor agropecuario durante el proceso productivo, transformando
            sus prácticas agrícolas en sustentables y así obtener beneficios
            económicos adicionales por contar la historia de sus cultivos.
            <br />
            <br />
            Brindamos una solución trazable con tecnología escalable y versátil
            que se adapta a las particularidades de cada productor y actor de la
            cadena.
          </p>
        </div>
        <div className="wit-element right">
          <img src={person} alt="person" />
        </div>
      </div>
    </div>
  );
};

export default WhatIsIt;
