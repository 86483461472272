import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/imagen-originacion.jpg';
import { useTranslation } from 'react-i18next';

const Originacion = () => {
  const { t } = useTranslation();

  const BackgroundWrapper = styled.section`
    background-image: url(${background});
    height: 950px;
    background-position: center;
    background-size: cover;
    width: 1105px;
    margin: 0 auto;
    @media (max-width: 1105px) {
      width: 100%;
    }
  `;
  const TextContainer = styled.div`
    height: 100%;
    max-width: 1105px;
    margin: 0 auto;
    position: relative;
    padding: 46px;
  `;
  const TextWrapper = styled.div`
    position: absolute;
    width: 100%;
    max-width: 543px;
    bottom: 46px;
    right: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1105px) {
      bottom: unset;
      right: unset;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
    }
    h2 {
      color: #facb30;
      font-size: 3.3rem;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
      max-width: 363px;
      margin-bottom: 30px;
      @media (max-width: 1105px) {
        font-size: 2.2rem;
      }
    }
    p {
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 24px;
      width: 100%;
      text-align: center;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  `;
  return (
    <BackgroundWrapper id="originacion">
      <TextContainer>
        <TextWrapper>
          <h2>{t('Originación de Granos y Especialidades')}</h2>
          <p>
            {t(
              'Es un programa de cultivos especiales donde asociamos productores y empresas. Para la producción de trigo candeal y semilla, trigos especiales de alta calidad, cebada cervecera y semilla, girasol Alto Oleico, y maíz pisingallo.'
            )}
          </p>
          <p>
            {t(
              'Todos estos cultivos se desarrollan en diferentes modelos que van desde la siembras 100% por cuenta y orden de cada cliente hasta las siembras asociadas con productores, todo bajo nuestra dirección y supervisión.'
            )}
          </p>
          <p>
            {t(
              'Estamos innovando para incorporar a todos estos cultivos en un sistema de Blockchain, utilizando la agtech Ucrop.it  tanto para su certificación futura como también para eficientizar los procesos y toma de decisiones. Dicha certificación de los eventos realizados a campo, en forma simple y con uso de IOT. Los consumidores de nuestro país y del mundo entero están demandando cada vez alimentos sanos, que ayuden a una buena nutrición y que se produzcan protegiendo los recursos naturales y el medioambiente.  Los sistemas productivos de nuestro país se encuentran entre los más sustentables de todo el mundo y nos encontramos trabajando en este tema hace más de 30 años utilizando producción con siembra directa, rotación de cultivos y hoy también cultivos de servicios y agricultura de precisión.  Certificar todo esto utilizando Ucrop.it es el desafío que hemos incorporado a los  sistemas productivos dirigidos por Cazenave. Seguimos creciendo en los servicios de originación de granos  y especialidades incorporando nuevos clientes e inversores'
            )}
          </p>
          <p>
            {t(
              'Hasta el momento en los últimos años, hemos totalizado  más de 2 millones de Hectáreas Originadas 1.200MM de has de originación, en los distintos programas.'
            )}
          </p>
        </TextWrapper>
      </TextContainer>
    </BackgroundWrapper>
  );
};

export default Originacion;
