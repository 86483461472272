import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Mira } from '../../../../assets/images/tech-oval.svg';
import Fade from 'react-reveal/Fade';
import NumberItem from '../NumberItem/NumberItem';
import Reveal from 'react-reveal/Reveal';
import { useTranslation } from 'react-i18next';

const SiembraItem = () => {
  const { t } = useTranslation();

  const WrapperItem = styled.div`
    width: 500px;
    height: 280px;
    display: flex;
    position: absolute;
    top: 55%;
    left: 12%;
    color: #ffffff;
    font-size: 3.35rem;
    font-weight: 400;
    line-height: 55px;
    text-align: center;
    z-index: 4;
    text-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.5);
    @media (max-width: 1105px) {
      display: none;
    }
    .multi-item {
      margin-top: -35px !important;
      list-style-type: none !important;
      display: flex !important;
      flex-direction: column !important;
    }
    .multi-item li {
      float: left !important;
      font-size: 3rem;
      margin: 0 !important;
      text-align: left;
    }
    .test {
      width: 0px;
      position: absolute;
      top: 25%;
      right: 20%;
      height: 1.5px;
      background-color: white;
      animation: growIn 0.2s linear;
      animation-fill-mode: forwards;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      @keyframes growIn {
        0% {
          width: 0;
        }
        100% {
          width: 400px;
        }
      }
    }
    .mira-siembra {
      position: absolute;
      top: -30px;
      left: 59%;
      width: 200px;
      height: auto;
      animation: rotateOval 100s linear;
      @keyframes rotateOval {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(1turn);
        }
      }
    }
  `;

  // const [scroll, setScroll] = useState(0)

  // useEffect(() => {
  //     document.addEventListener("scroll", () => {
  //      const scrollCheck = window.scrollY;
  //     if (scrollCheck > 1500 && scrollCheck < 1550) {
  //         setScroll({scroll : scrollCheck})
  //         console.log(scrollCheck);
  //     }
  //     })
  // },[])
  return (
    <WrapperItem>
      <Fade left delay={500}>
        <ul className="multi-item">
          <li>{t('Fideicomisos')}</li>
          <li>{t('Fondos de Siembra')}</li>
        </ul>
      </Fade>
      <NumberItem
        num="1.5"
        text={`MM ${t(`HAS.`)}`}
        right="73%"
        bottom="40%"
        textAlign="left"
      />
      <NumberItem
        num="1.3"
        text="B USD."
        textAddition={t('Valor Producto Vendido')}
        right="39%"
        bottom="40%"
        textAlign="left"
      />
      <Mira className={`mira-siembra`} />
      <Reveal effect="test" delay={500}>
        <div />
      </Reveal>
    </WrapperItem>
  );
};

export default SiembraItem;
