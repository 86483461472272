import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';



const NumberItem = ({bottom, right, num, text, textAlign, textAddition}) => {

    const StyledNumber = styled.div`
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 162px;
        height: 60px;
        bottom: ${bottom};
        right: ${right};
        z-index: 3;
        .count {
        position: relative;
        font-size: 6rem!important;
        color: #FFFFFF;
        font-size: 7.8rem;
        font-weight: 600;
        letter-spacing: 2.61px;
        text-shadow: 0px 6px 11px 0px rgba(0,0,0,.5);
        text-align: ${textAlign}
        }

        .text {
            position: absolute;
            bottom: -40px;
            left: 19px;
            color: #FFFFFF;
            font-size: 1.26rem;
            font-weight: 400;
            letter-spacing: 0!important;
            height: 38px;
            line-height: 19px;
            text-align: left;
        }
    `;

    const StyledRandom = styled.span`
        position: relative;
        font-size: 6rem!important;
        color: #FFFFFF;
        font-size: 7.82rem;
        font-weight: 600;
        letter-spacing: 2.61px;
        text-shadow: 0px 6px 11px 0px rgba(0,0,0,.5);
        text-align: ${textAlign};
    `;

    return (
        <StyledNumber>
            <Fade delay={1000}>
            <CountUp className="count" delay={2} start={0} end={Number(num)} decimal="." decimals={1} prefix="+" redraw={true}>
                {({countUpRef, start}) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <StyledRandom ref={countUpRef} />
                    </VisibilitySensor>
                )}
            </CountUp>
            <span className="text">{text} <br /> {textAddition}</span>
            </Fade>
        </StyledNumber>
    )
}

export default NumberItem;
