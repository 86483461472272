import React, {useEffect} from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';
import Historia from './Pages/Historia/Historia';
import Unica from './Pages/Unica/Unica';
import Prensa from './Pages/Prensa/Prensa';
import Contacto from './Pages/Contacto/Contacto';
import CasaTech from './Pages/CasaTech/CasaTech';
import QueHacemos from './Pages/QueHacemos/QueHacemos';
import Inverti from './Pages/Inverti/Inverti';
import SideDrawer from './components/Nav/SideDrawer';
import { useTranslation } from "react-i18next";
import RSE from './Pages/RSE/rse';
import Ucrop from './Pages/Ucrop/Ucrop';



const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    if(window.navigator.language === 'en-US') {
      i18n.changeLanguage( 'en' );
    }else if(window.navigator.language === 'pt-PT') {
      i18n.changeLanguage('por');
    }
  }, [i18n])
  return (
     <>
      <Nav transparent tipoNav="home" />
      <SideDrawer transparente tipoNave="home" />
      <Switch>
        <Route path='/contacto' component={Contacto} />
        <Route path='/prensa' component={Prensa} />
        <Route path='/inverti' component={Inverti} />
        <Route path='/unica' component={Unica} />
        <Route path='/ucropit' component={ Ucrop } />
        <Route path='/casa-tech' component={CasaTech} />
        <Route path='/que-hacemos' component={QueHacemos} />
        <Route path='/rse' component={RSE} />
        <Route path='/historia' component={Historia} />
        <Route exact path='/' component={Home} />
      </Switch>
      <Footer />
     </>
  );
}

export default App;
