import React from 'react';
import styled from 'styled-components';
import ListaEmpresas from './ListaEmpresas';
import {useTranslation} from 'react-i18next';


const Empresas = () => {

    const {t} = useTranslation();

    const Wrapper = styled.section`
        margin-top: 60px;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        @media (max-width: 1105px) {
            padding: 20px;
        }
        h2 {
            color: #626262;
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0.43px;
            line-height: 18px;
            width: 103px;
            max-width: 100%;
            text-align: left;
        }
        h3 {
            margin-top: 20px;
            color: #175763;
            font-size: 3.3rem;
            font-weight: 300;
            letter-spacing: -0.09px;
            line-height: 40px;
            width: 386px;
            max-width: 100%;
            text-align: left;
        }
    `;

    return (
        <Wrapper>
            <h2>{t('EMPRESAS')}</h2>
            <h3>{t('Trabajamos con')}</h3>
            <ListaEmpresas />
        </Wrapper>
    )
}

export default Empresas
