import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


html {
    overflow-x: hidden;
    font-size: 62.5%;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    --color-main: ${props => props.theme.colors.main};
    --color-white: ${props => props.theme.colors.whiteColor};
    --color-black: ${props => props.theme.colors.blackColor};
    --color-text: ${props => props.theme.colors.textColor};
    --color-text-light: ${props => props.theme.colors.textColorLight};

    @media ${props => props.theme.mediaQueries.large}{
    font-size: 50%;
    }
}
body {
    background-color: white;
}
.carousel .slide {
  background: transparent!important;
}
.slide {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    img {
      width: 90%!important;
    }
  }
}
.rsm-geography {
  fill: white!important;
  stroke: rgba(0,0,0,.12)!important;
}

.rsm-geography--hover{
  fill:rgb(96, 125, 139)!important;
  stroke: rgb(96, 125, 139)!important;
}

.rsm-geography--pressed {
  fill: #175763!important;
  stroke: #175763!important;
}
.rsm-geography[data-tip="Argentina"],
.rsm-geography[data-tip="Paraguay"],
.rsm-geography[data-tip="Brazil"],
.rsm-geography[data-tip="Colombia"],
.rsm-geography[data-tip="Ukraine"],
.rsm-geography[data-tip="Angola"],
.rsm-geography[data-tip="Russia"] {
  fill: #175763!important;
  stroke: #848484!important;
}

.__react_component_tooltip.type-dark {
    color: #222;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.39);
}
.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #fff;
    border-top-style: solid;
    border-top-width: 6px;
}
.__react_component_tooltip.show{
  opacity: 1;
  font-size: 10px;
  padding: 6px 12px;
  color: #000000;
  font-weight: 400;
}
button:focus {
    outline: none;
}
a, a:visited, a:hover{
    text-decoration: none;
  }


@media ${props => props.theme.mediaQueries.smallest}{
    font-size: 55%;
}

`;