import React from 'react';
import styled from 'styled-components';
import backgroundM from '../../../assets/images/bg-contacto-sintexto.jpg';
import {useTranslation} from 'react-i18next';
import Fade from 'react-reveal/Fade'

const Header = () => {
    const {t} = useTranslation();

    const HeaderWrapper = styled.header`
    position: relative;
    min-height: 100vh;
    background-image: url(${backgroundM});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 1105px) {
        background-image: url(${backgroundM});
    }
    `;
    const StyledTitle = styled.h1`
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
        color: #FFFFFF;
        font-size: 4.2rem;
        font-weight: 600;
        line-height: 98px;
        padding: 20px;
        width: 100%;
        max-width: 1028px;
        opacity: 0.9;
        text-align: center;
        text-shadow: 0 2px 5px 5px rgba(0,0,0,.5);
        @media(max-width: 600px) {
            font-size: 3rem;
            line-height: 70px;
        }
    `;
    

    return (
        <HeaderWrapper>
            <StyledTitle>
                <Fade>
                <h1>{t('El puente hacia')} {t('el futuro del agro')}</h1>
                </Fade>
            </StyledTitle>
        </HeaderWrapper>
    )
}

export default Header;
