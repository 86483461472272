import React from "react";
import "./section3.css";

const Section3 = () => {
  return (
    <div className="section-3">
      <div className="background-img-1"></div>
      <div className="background-img-2"></div>
      <div className="text">
        <hr />
        <h1>
          Somos pioneros en desarrollar propuestas innovadoras en materia
          ambiental.
        </h1>
        <p>
          En virtud del proyecto llevado a cabo junto al IFC (Banco Mundial),
          desarrollamos uno de los primeros programas de{" "}
          <strong>
            reciclado de envases vacíos de productos fitosanitarios
          </strong>
          , garantizando el cuidado del ambiente con una fuerte participación de
          las comunidades locales en la gestión.
        </p>
      </div>
    </div>
  );
};

export default Section3;
