import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/bg-casatech.jpg';
import casaTechLogo from '../../../assets/images/logo-casatech.png';
import Particles from 'react-particles-js';
import {ReactComponent as Mouse} from '../../../assets/images/mouse-scroll.svg';
import Jump from 'react-reveal/Jump';
import { useTranslation } from 'react-i18next';

const Header = () => {

    const {t} = useTranslation();

    const StyledHeader = styled.header`
    background-image: url(${background});
    min-height: 100vh;
    background-size: cover;
    position: relative;
    background-position: center;
    .logo-img {
        width: 100%;
        max-width: 40vw;
        top: 30vh;
        position: absolute;
        left: 30vw;
    }
    `;

    const particles = <Particles 
    params={{
	    "particles": {
	        "number": {
	            "value": 100,
	            "density": {
	                "enable": true,
	                "value_area": 1500
	            }
	        },
	        "line_linked": {
	            "enable": true,
	            "opacity": 0.04
	        },
	        "move": {
	            "direction": "right",
	            "speed": 0.05
	        },
	        "size": {
	            "value": 1
	        },
	        "opacity": {
	            "anim": {
	                "enable": true,
	                "speed": 1,
	                "opacity_min": 0.05
	            }
	        }
	    },
	    "interactivity": {
	        "events": {
	            "onclick": {
	                "enable": true,
	                "mode": "push"
	            }
	        },
	        "modes": {
	            "push": {
	                "particles_nb": 1
	            }
	        }
	    },
	    "retina_detect": true
	}} 
    />

    const StyledLine = styled.div`
    position: absolute;
    top: 44%;
    left: 47%;
    transform: translateX(-50%) scale(0);
    height: 3px;
    width: 109px;
    background-color: #fff;
    animation: grow .6s ease-in-out;
    animation-fill-mode: forwards;
    transform-origin: left;
    animation-delay: 1s;
    @media (max-width: 1105px) {
        left: 35%;
    }
    @keyframes grow {
        0% {
            transform: translateX(-50%) scale(0);
        }
        100% {
            transform: translateX(-50%) scale(1);
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        background-color: #fff;
        width: 220px;
    }
    `;

    const StyledSub = styled.p`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        color: #FFFFFF;
        font-size: 17.2px;
        font-weight: 400;
        letter-spacing: -0.11px;
        line-height: 25.99px;
        width: 592px;
        max-width: 100%;
        text-align: center;
        /* animation: apear 1s ease;
        animation-fill-mode: forwards;
        opacity: 0;
        animation-delay: .3s;
        @keyframes apear {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        } */
        @media (max-width: 1105px) {
            padding: 20px;
            font-size: 15px;
        }
    `;

    const StyledMouse = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    `;

    const subtitulo = <div>{t('El negocio tradicional del agro se enfrenta al gran desafío de adaptarse a los constantes cambios disruptivos de la tecnología.')}<br/> {t('Exponenciamos la industria del agro aplicando nuevas tecnologías que multiplican y mejoran los resultados de nuestro trabajo.')} <br/>{t('Somos la Plataforma sólida para empresas de modernización del Agro generando Ciencia y Tecnología.')}</div>
    
    const [isTop, setIsTop] = useState(false);
    let animationMouse = isTop ?
     <Jump 
      count={1} 
      when={false}
      ><Mouse /></Jump>
      :  
      <Jump 
      delay={4000}
      appear={true} 
      when={true}
      count={2}
      ><Mouse /></Jump>

      useEffect(() => {
        document.addEventListener("scroll", () => {
            let up = window.scrollY;
            if(up > 10) {
                setIsTop(true)
            }else{
                setIsTop(false)
            }
            })
        
    }, [])


    return (
        <StyledHeader>
            <img src={casaTechLogo} className="logo-img" alt="casa tech logo"></img>
            {particles}
            <StyledLine />
            <StyledSub>
                {subtitulo}
            </StyledSub>
            <StyledMouse>
                {animationMouse}
            </StyledMouse>
        </StyledHeader>
    )
}

export default Header;
