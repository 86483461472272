import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import background from '../../assets/images/bg-quehacemos.jpg';
import {ReactComponent as Mouse} from '../../assets/images/mouse-scroll.svg';
import Jump from 'react-reveal/Jump';

const Header = () => {

    const StyledHeader = styled.header`
    background-image: url(${background});
    background-size: cover;
    min-height: 92vh;
    background-position: center;
    background-position-y:100%;
    `;

    const StyledMouse = styled.div`
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    `;

    

    const [isTop, setIsTop] = useState(false);
    let animationMouse = isTop ?
    <Jump 
    count={1} 
    when={false}
    ><Mouse /></Jump>
    :  
    <Jump 
    delay={4000}
    appear={true} 
    when={true}
    count={2}
    ><Mouse /></Jump>

    useEffect(() => {
        document.addEventListener("scroll", () => {
            let up = window.scrollY;
            if(up > 10) {
                setIsTop(true)
            }else{
                setIsTop(false)
            }
            })
        
    }, [])

    return (
        <StyledHeader>
            <StyledMouse>
                {animationMouse}
            </StyledMouse>
        </StyledHeader>
    )
}

export default Header
