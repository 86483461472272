import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import background from '../../../assets/images/bg-proyectos.png';
import TitleProyectos from './TitleProyectos/TitleProyectos';
import UnicaBackground from '../../../assets/images/background-unica.jpg';
import HandShake from '../../../assets/images/hand-shake.jpg';
import unica from '../../../assets/images/iunica.svg';
import UcropIT from '../../../assets/images/ucropit.svg';
import GIA from '../../../assets/images/GIA-LOGO.png';
import growersBg from '../../../assets/images/growers-bg.jpg';
import Particles from 'react-particles-js';
import { useTranslation } from 'react-i18next';

const Proyectos = () => {
  const { t } = useTranslation();

  const ProyectosWrapper = styled.section`
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.98)
      ),
      url(${background});
    background-size: cover;
    position: relative;

    .projectsContainer {
      padding: 340px 0 140px 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }
  `;

  const Unica = styled.div`
    margin-bottom: 100px;
    width: 925px;
    max-width: 100%;
    height: 352px;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.9) 100%
      ),
      url(${UnicaBackground});
    background-position-y: 30%;
    background-size: cover;
    border-radius: 40px;
    box-shadow: 0px 2px 30px 0px #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 50px 0;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
      border: 8px solid #fff;
    }
    @media (max-width: 1105px) {
      padding: 20px;
      max-width: 90%;
      &:hover {
        transform: unset;
        border: unset;
      }
    }
    .imgContainer {
      width: 254.27px;
      height: 55.77px;

      img {
        width: 100%;
      }
    }
    p {
      color: #ffffff;
      font-size: 1.46rem;
      font-weight: 400;
      letter-spacing: -0.09px;
      line-height: 20.8px;
      width: 549px;
      max-width: 100%;
      text-align: center;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.2rem;
      background-color: #00958d;
      width: 172px;
      height: 43px;
      border-radius: 100px;
    }
  `;

  const Ucrop = styled.div`
    margin-bottom: 100px;
    width: 925px;
    max-width: 100%;
    height: 352px;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.97) 100%
      ),
      url(${HandShake});
    background-position-y: 30%;
    background-size: cover;
    border-radius: 40px;
    box-shadow: 0px 2px 30px 0px #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 50px;
    transition: 0.3s ease-in-out;
    @media (max-width: 1105px) {
      background-position: center;
      padding: 20px;
      max-width: 90%;
      &:hover {
        transform: unset;
        border: unset;
      }
    }
    &:hover {
      transform: scale(1.1);
      border: 8px solid #fff;
    }
    .imgContainer {
      width: 274.27px;
      height: 55.77px;

      img {
        width: 100%;
      }
    }
    p {
      margin-top: 10px;
      color: #ffffff;
      font-size: 1.46rem;
      font-weight: 400;
      letter-spacing: -0.09px;
      line-height: 20.8px;
      width: 549px;
      max-width: 100%;
      text-align: center;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.2rem;
      background-color: #00958d;
      width: 172px;
      height: 43px;
      border-radius: 100px;
    }
  `;
  const Growers = styled.div`
    width: 925px;
    max-width: 100%;
    height: 352px;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.97) 100%
      ),
      url(${growersBg});
    background-position-y: 30%;
    background-size: cover;
    border-radius: 40px;
    box-shadow: 0px 2px 30px 0px #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 50px;
    transition: 0.3s ease-in-out;
    @media (max-width: 1105px) {
      background-position: center;
      padding: 20px;
      max-width: 90%;
      &:hover {
        transform: unset;
        border: unset;
      }
    }
    &:hover {
      transform: scale(1.1);
      border: 8px solid #fff;
    }
    .imgContainer {
      width: 274.27px;
      height: 55.77px;

      img {
        width: 100%;
      }
    }
    p {
      margin-top: 10px;
      color: #ffffff;
      font-size: 1.46rem;
      font-weight: 400;
      letter-spacing: -0.09px;
      line-height: 20.8px;
      width: 549px;
      max-width: 100%;
      text-align: center;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.2rem;
      background-color: #00958d;
      width: 172px;
      height: 43px;
      border-radius: 100px;
    }
  `;
  return (
    <ProyectosWrapper>
      <Particles
        style={{ position: 'absolute', width: '100%' }}
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: 'repulse',
              },
            },
          },
        }}
      />
      <TitleProyectos />
      <div className="projectsContainer">
        <Unica>
          <div className="imgContainer">
            <img src={unica} alt="Unica" />
          </div>
          <p>
            Plataforma GIS con soluciones prácticas para monitoreos, muestreos
            georreferenciados, estimación de rindes, ambientación de suelos,
            prescripciones de siembra y fertilización variable, mapas de rinde,
            mpas de tosca, NDVI. Almacenamos y visualizamos toda la información
            técnica por campaña, lote y ambiente, de gestión agrícola y
            georreferenciada.
          </p>
          <Link to="/unica">{t('Ver más')}</Link>
        </Unica>
        <Ucrop>
          <div className="imgContainer">
            <img src={UcropIT} alt="UcropIT" />
          </div>
          <p>
            Plataforma de uso libre que brinda trazabilidad de la producción y
            certeza agronómica mediante tecnología & blockchain. Combinamos
            evidencias de ejecución en el campo con firmas digitales de los
            actores que las producen, registradas en blockchain, para construir
            certeza en un entorno de cooperación. Generamos premios y beneficios
            comerciales para los productores que quieran mostrar a quien él
            decida su manera de realizar cultivos sustentables.
          </p>
          <a href="http://ucrop.it" target="_blank" rel="noopener noreferrer">
            {t('Ver más')}
          </a>
        </Ucrop>
        <Growers>
          <div className="imgContainer">
            <img src={GIA} alt="GiaLogo" />
          </div>
          <p>
            Growers Insight Argentina (GIA) ofrece servicios de consultoría e
            implementación junto con una herramienta GRATUITA para asesorar a
            los productores y las empresas agrícolas en su proceso de
            modernización agrícola, enfocado en la rentabilidad y la
            sostenibilidad a través de las múltiples herramientas tecnológicas
            disponibles.
          </p>
          <a
            href="https://www.growersinsight.com/farm-consulting-argentina"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Ver más')}
          </a>
        </Growers>
      </div>
    </ProyectosWrapper>
  );
};

export default Proyectos;
