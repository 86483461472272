import styled from 'styled-components';

export const AgriculturaSatelitalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  min-height: 800px;
  background-image: ${({ backgroundImage }) =>
    `radial-gradient(55.89% 94.49%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  margin-bottom: 50px;

  @media (min-width: 1105px) {
    width: 1105px;
  }

  div {
    width: 100%;
    margin: 6rem 5rem 0 5rem;

    @media (min-width: 1105px) {
      width: 50%;
      margin-right: 8rem;
    }
  }

  h2 {
    color: #facb30;
    font-size: 3.3rem;
    margin-bottom: 16px;
    font-weight: 400;
    text-align: center;
  }

  .quote-text {
    text-align: center;
    color: white;
    font-size: 1.6rem;
    line-height: 24px;
  }

  .subtitle-text {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  ol {
    list-style: none;
  }

  p,
  li {
    text-align: center;
    color: white;
    font-size: 1.6rem;
    line-height: 24px;
  }
`;
