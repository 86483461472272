import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

const TitleProyectos = () => {

    const {t} = useTranslation();

    const StyledTitle = styled.h2`
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 30px;
    color: #000;
    font-size: 1.8rem;
    width: 268px;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: .5s;
    background-color: white;
    text-align: center;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: transparent;
        rect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            fill: transparent;
            stroke-width: 6;
            stroke: #000;
            stroke-dasharray: 108;
            animation: animate 2s linear infinite;
            @keyframes animate {
                0% {
                    stroke-dashoffset: 217;
                }

                100% {
                    stroke-dashoffset: 0;
                }
            }
        }
    }

    &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: -20px;
        left: -25px;
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        opacity: .4;
    }
    &::after {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: -20px;
        right: -25px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        opacity: .4;
    }
    .border {
            &::before {
            display: block;
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: -20px;
            left: -25px;
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
            opacity: .4;
        }
        &::after {
            display: block;
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: -20px;
            right: -25px;
            opacity: .4;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
        }
    }
    .line {
        @media(max-width: 600px) {
            display: none;
        }
        &::before {
            content: '';
            position: absolute;
            height: 1px;
            width: 202px;
            top: 50%;
            transform: translateY(-50%);
            left: -260px;
            background-color: #fff;
            opacity: .4;
        }
        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 202px;
            top: 50%;
            transform: translateY(-50%);
            right: -260px;
            background-color: #fff;
            opacity: .4;
        }
    }
    `;
    return (
        <>
        <StyledTitle>
            <svg><rect></rect></svg>
            {t('NUESTROS AG TECH')}
            <span className="border"></span>
            <span className="line"></span>
        </StyledTitle>
        </>
    )
}

export default TitleProyectos;
