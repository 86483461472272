const theme = {
    colors : {
      main: '#175763',
      whiteColor: '#fff',
      textColor: '##182B2B',
      textColorLight: '#8A8B8A',
      blackColor: '#000',
    },
    mediaQueries: {
      smallest: `only screen and (max-width:26em)`,
      small: `only screen and (max-width: 37.5em)`,
      medium: `only screen and (max-width: 56em)`,
      large: `only screen and (max-width: 80em)`,
      largest: `only screen and (max-width: 90em)`,
    },
    
  
  }
  
  
  export default theme;