import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import {useTranslation} from 'react-i18next';

const Subtitle = () => {
    const {t} = useTranslation();

    const StyledSection = styled.section`
    width: 100%;
    background-color: #FFF;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    @media (max-width: 1105px) {
        max-width: 100%;
    }
    p { 
        &:not(:first-child) {
            margin-top: 40px;
        }
        color: #2C2C2C;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: -0.1px;
        line-height: 24.88px;
        width: 711px;
        max-width: 100%;
        text-align: center;
    }
    `;


    return (
        <StyledSection>
            <Fade>
            <p>{t('En Cazenave sabemos que el mundo necesita un cambio de consciencia y compromiso ambiental, social y económico de todos los sectores para mantener nuestro planeta habitable. Entre todos debemos disminuir al máximo los impactos negativos de nuestras actividades. Si lo hacemos podremos revertir, en parte, el cambio climático que tanto daño produce al planeta que habitamos.')}</p>
            </Fade>
            <Fade>
            <p>{t('El agro puede colaborar mucho en este proceso de reducción del impacto ambiental negativo a través de las Buenas Prácticas Agrícolas y Ganaderas, reduciendo la emisión de GEI y promoviendo la captura de Carbono en nuestros suelos.')}</p>
            </Fade>
        </StyledSection>
    )
}

export default Subtitle;
