import React, {useState, useEffect} from 'react';
import Header from '../../components/Home/Header/Header';
import NuestroObjetivo from '../../components/Home/NuestroObjetivo/NuestroObjetivo';
import styled from 'styled-components';
import Valores from '../../components/Home/Valores/Valores';
import Siembra from '../../components/Home/Siembra/Siembra';
import NuestroEquipo from '../../components/Home/NuestroEquipo/NuestroEquipo';
import Spinner from '../../components/UI/Spinner/Spinner';

const MainWrapper = styled.main`
width: 100%;
height: 100%;
position: relative;
`;


const Home = () => {

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scroll({top: 0, left:0})
        setIsLoading(false);
    }, [])


    return (
        isLoading ? 
        <Spinner />:
        <>
            <Header />
            <MainWrapper>
                <NuestroObjetivo />
                <Valores/>
                <Siembra />
            </MainWrapper>
            <NuestroEquipo />
        </>
    )
}

export default Home;
