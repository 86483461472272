import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Header from '../../components/Prensa/Header/Header';
import News from '../../components/Prensa/News/News';
import Spinner from '../../components/UI/Spinner/Spinner';
import { GoogleSpreadsheet } from "google-spreadsheet";


const Prensa = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [news, setNews] = useState([])

    useEffect(() => {
        window.scroll({top: 0, left:0})
        const doc = new GoogleSpreadsheet( process.env.REACT_APP_SPREADSHEET_ID );

        doc.useServiceAccountAuth( {
            client_email: process.env.REACT_APP_GOOGLE_CLIENT_EMAIL,
            private_key: process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY.replace( /\\n/g, '\n' ),
        }).then(() => {
            doc.loadInfo().then(() => {
                const SHEET_ID = process.env.REACT_APP_SHEET_ID;
                const sheet = doc.sheetsById[ SHEET_ID ];
                sheet.getRows().then(res => {
                    setNews( res );
                    setIsLoading( false );
                });
            });
        });
    }, []);

    const MainWrapper = styled.main`
    display: flex;
    flex-direction: column;
    width: 1120px;
    max-width: 100%;
    margin: 0 auto;
    `;

    return (
        isLoading ?
        <Spinner /> :
        <>
            <Header />
            <MainWrapper>
                { 
                    news.map( (nota, index) => (
                        <News
                        key={index}
                        imagen={nota.imagen ? nota.imagen : ""}
                        author={nota.autor}
                        link={nota.link}
                        titulo={nota.titulo}
                        description={nota.descripcion}
                        date={nota.fecha}
                        />
                    ))
                }
            </MainWrapper>
        </>
    )
}

export default Prensa
