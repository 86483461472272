import React from 'react';
import styled from 'styled-components';
import logoUcrop from '../../assets/images/ucropit.svg';
import ReactPlayer from 'react-player';

const Header = () => {
  const StyledHeader = styled.header`
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    .logo-img {
      max-width: 20vw;
      margin-top: 15vh;
      margin-bottom: 10px;
    }
    .ucrop-title {
      font-size: 2.5rem;
      color: #212529;
      text-align: center;
      margin-bottom: 20px;
    }
  `;

  return (
    <StyledHeader>
      <img src={logoUcrop} className="logo-img" alt="logo ucropit"></img>
      <div className="ucrop-title">
        Una plataforma gratuita que potencia al productor agropecuario,
        <br />
        <strong>con los beneficios que genera la sustentabilidad.</strong>
      </div>
      <ReactPlayer
        url="https://www.youtube.com/embed/zLVKR94bFmk"
        loop={true}
        width="58vw"
        height="60vh"
        playing={true}
      />
    </StyledHeader>
  );
};

export default Header;
