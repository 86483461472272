import React from 'react';
import styled from 'styled-components';

const Brand = ({width, height, image}) => {

    const ImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${width};
    height: ${height};
    img {
        width: 100%;
    }
    `;

    return (
        <ImgWrapper>
            <img src={image} alt="Marca"/>
        </ImgWrapper>
    )
}

export default Brand
