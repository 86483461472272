import React from 'react';
import './section1.css';
import item1 from '../../assets/images/section1item1.svg';
import item2 from '../../assets/images/section1item2.svg';

const Section1 = () => {
  return (
    <div className="container-s1">
      <div className="items-container">
        <div className="icon left">
          <img src={item1} alt="icono" />
        </div>
        <div className="icon right">
          <img src={item2} alt="icono" />
        </div>
      </div>
      <div className="items-container">
        <div className="item left">
          <p>
            Desarrollamos la{' '}
            <strong>primera empresa argentina con tecnología blockchain</strong>
            , para el registro de todos los eventos agronómicos y ambientales de
            los cultivos,{' '}
            <strong>garantizando la transparencia y trazabilidad</strong> de los
            procesos.
          </p>
        </div>
        <div className="item right">
          <p>
            Comprometidos con la{' '}
            <strong>Agenda Ambiental Local y Global</strong>, estamos en proceso
            de tener todos nuestros manejos productivos, registrados con
            trazabilidad, para ir hacia una{' '}
            <strong>agricultura certificada</strong>. Trabajamos con total
            cumplimiento de las{' '}
            <strong>
              buenas prácticas agrícolas, y cuidado del medio ambiente.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section1;
