import React, { useState, useEffect } from "react";
import Spinner from "../../components/UI/Spinner/Spinner";
import HeaderRSE from "../../components/RSE/header";
import Section1 from "../../components/RSE/section1";
import Section2 from "../../components/RSE/section2";
import Section3 from "../../components/RSE/section3";
import Section4 from "../../components/RSE/section4";
import Section5 from "../../components/RSE/section5";

const RSE = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <HeaderRSE />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </>
  );
};

export default RSE;
