import React from 'react';
import argentinaMap from '../../assets/images/ucrop-mapa.png';
import pinCordobaSanLuis from '../../assets/images/pinCordoba.png';
import pinBuenosAires from '../../assets/images/pinBuenosAires.png';
import pinBsAsSur from '../../assets/images/pinBsAsSur.png';
import pinLitoral from '../../assets/images/pinLitoral.png';
import pinNea from '../../assets/images/pinNea.png';
import './centauros.css';

const Centauros = () => {
  return (
    <div className="cent-container">
      <div className="cent-elements-container">
        <div className="cent-element left">
          <ul>
            <li>
              <img
                className="cent-pin-img"
                src={pinCordobaSanLuis}
                alt="Cordoba y San Luis"
              />
            </li>
            <li>
              <img
                className="cent-pin-img"
                src={pinBuenosAires}
                alt="Buenos Aires Centro y Oeste"
              />
            </li>
          </ul>
        </div>
        <div className="cent-element middle">
          <img src={argentinaMap} alt="Mapa" />
        </div>
        <div className="cent-element right">
          <ul>
            <li>
              <img className="bigger-cent-pin-img" src={pinNea} alt="Nea" />
            </li>
            <li>
              <img
                className="cent-pin-img"
                src={pinLitoral}
                alt="Núcleo y Litoral"
              />
            </li>
            <li>
              <img
                className="smaller-cent-pin-img"
                src={pinBsAsSur}
                alt="Buenos Aires Sur"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Centauros;
