import React from 'react';
import styled from 'styled-components';
import rombo from '../../../assets/images/rect-quehacemos.svg';

const NuestrosIngenierosItem = ({number, photo, name, city, phone, email}) => {

    const IngenieroWrapper = styled.div`
    width: 370px;
    max-width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 1105) {
        width: 160px;
    }
    .rombo {
        position: relative;
        background-image: url(${rombo});
        background-repeat: no-repeat;
        background-size: cover;
        width: 40px;
        height: 40px;
        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            color: #FACB30;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 19px;
        }
        &:after{
            position: absolute;
            content: '';
            top: 50%;
            right: -16px;
            transform: translateY(-50%);
            width: 17px;
            height: 1px;
            background-color: #979797;
        }
    }

    .imgContainer {
        margin-left: 14px;
        margin-right: 16px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;
        img {
            height: 100%;
        }
    }
    .textoContainer {
        display: flex;
        flex-direction: column;
        max-width: 233px;
        .nombre {
            color: #175763;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 19px;
        }
        .ciudad {
            color: #a8a8a8;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 19px;
        }
        .telefono {
            color: #eea863;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 19px;
        }
    }
    `;
    return (
        <IngenieroWrapper>
            <div className="rombo">
                <div className="number">{number}</div>
            </div>
            <div className="imgContainer">
                <img src={photo} alt={name}/>
            </div>
            <div className="textoContainer">
                <div className="nombre">{name}</div>
                <div className="ciudad">{city}</div>
                <div className="telefono">{phone}</div>
                <div className="telefono">{email}</div>
            </div>
        </IngenieroWrapper>
    )
}

export default NuestrosIngenierosItem
