import React from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import emailjs from 'emailjs-com';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';

const UcropForm = () => {
  const FormWrapper = styled.form`
    padding: 67px 140px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
    color: #19a255;
    .yellow-underline {
      text-decoration: underline;
      text-decoration-color: #ffd200;
    }
    h2 {
      color: black !important;
      font-size: 4rem;
      font-weight: bolder;
      text-align: center;
      line-height: 1.2;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 2.5rem;
      font-weight: thin;
      text-align: center;
      margin-bottom: 30px;
    }
    h3 a {
      color: #ffce00;
    }
  `;

  const FieldWrapper = styled.div`
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    width: 615px;
    max-width: 100%;
    height: 64.8px;
    border: 1.2px solid #f2f2f2;
    flex-direction: column;
    label {
      font-size: 1.4rem;
      font-weight: 600;
    }
    input {
      border: none;
      font-size: 1.8rem;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #ccc;
      }
    }
  `;

  const TextAreaWrapper = styled.div`
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    width: 615px;
    max-width: 100%;
    height: 154.8px;
    border: 1.2px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    label {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 14px;
    }
    textarea {
      height: 100%;
      border: none;
      font-size: 1.8rem;
      font-family: 'Open Sans', sans-serif;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #ccc;
        font-family: 'Open Sans', sans-serif;
      }
    }
  `;
  const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    button {
      background-color: #175763;
      border-radius: 1px;
      width: 86.4px;
      height: 42px;
      border: none;
      color: #fff;
      font-size: 1.3rem;
      font-weight: 600;
      letter-spacing: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
      transition: all 0.2s ease;
      &:disabled {
        cursor: not-allowed;
        background-color: #b8b8b8;
        color: #333;
      }
    }
  `;

  const sendMail = () => {
    emailjs
      .sendForm(
        'default_service',
        process.env.REACT_APP_EMAIL_TEMPLATE,
        '#contact-form',
        process.env.REACT_APP_EMAIL_USER,
        process.env.REACT_APP_EMAIL_TOKEN
      )
      .then(
        (result) => {
          alert('Tu mensaje ha sido enviado!');
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const contactSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.number().required(),
    message: Yup.string().required(),
  });

  return (
    <Formik
      validationSchema={contactSchema}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: '',
      }}
      onSubmit={sendMail}
    >
      {({ handleSubmit, isValid }) => (
        <FormWrapper
          onSubmit={handleSubmit}
          id="contact-form"
          name="contact-form"
        >
          <Fade className="green-box" bottom delay={500}>
            <h2>
              ¿Querés saber <span className="yellow-underline">más?</span>
            </h2>
          </Fade>
          <Fade bottom>
            <FieldWrapper>
              <label htmlFor="name">Nombre</label>
              <Field
                type="text"
                placeholder="Ingresa tu nombre aquí"
                name="name"
              />
            </FieldWrapper>
            <FieldWrapper>
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                placeholder="Ingresa tu email aquí"
                name="email"
              />
            </FieldWrapper>
            <FieldWrapper>
              <label htmlFor="phone">Teléfono</label>
              <Field
                type="number"
                placeholder="Ingresa tu teléfono aquí"
                name="phone"
                max="99999999999999"
              />
            </FieldWrapper>
            <TextAreaWrapper>
              <label htmlFor="message">Consulta</label>
              <Field
                type="text"
                placeholder="Escribe aquí tu consulta"
                name="message"
                component="textarea"
              />
            </TextAreaWrapper>
            <ButtonWrapper>
              <button type="submit" disabled={!isValid}>
                Enviar
              </button>
            </ButtonWrapper>
            <h3>
              <a href="mailto:hello@ucrop.it">hello@ucrop.it</a>
            </h3>
          </Fade>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default UcropForm;
