import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/imagen-gensus.jpg';
import gensus from '../../../assets/images/GensusLogo-dark-bg.png';
import {useTranslation} from 'react-i18next';

const Gensus = () => {

    const {t} = useTranslation();
    const BackgroundWrapper = styled.section`
    background-image:url(${background});
    background-size: cover;
    background-position: center;
    height: 709px;
    width: 1105px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    `;

    const InfoWrapper = styled.div`
    position: absolute;
    bottom: 57px;
    right: 57px;
    width: 487px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1105px) {
        padding: 20px;
        bottom: unset;
        right: unset;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        
    }
    .imgContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 330px;
        img {
            width: 100%;
        }
    }
    p {
        margin-top: 40px;
        color: #FFFFFF;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 24px;
        max-width: 487px;
        text-align: center;
    }
    `;
    return (
        <BackgroundWrapper id="gensus">
            <InfoWrapper>
            <div className="imgContainer">
                <img src={gensus} alt="Gensus"/>
            </div>
                <p>{ t('Único semillero que produce semilla fiscalizada de algodón en Argentina con el máximo estándar de calidad.  Ofrece siete variedades de diferentes ciclos adaptadas a las distintas zonas algodoneras y a diferentes condiciones de producción. Desarrolla en asociación con Bioheuris nuevas tecnologías de resistencia a herbicidas a través de la edición génica. Defendemos la Propiedad Intelectual como único camino para aumentar la competencia y lograr mejores materiales genéticos que contribuyan con el productor, el ambiente y la comunidad.')}</p>
            </InfoWrapper>
        </BackgroundWrapper>
    )
}

export default Gensus;
