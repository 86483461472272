import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import PressImage from '../../../assets/images/default-press.jpg';


const News = ({imagen, description, titulo, author, date, link}) => {

    const NewWrapper = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 50px 0;
        &:not(:last-of-type) {
            border-bottom: 1.05px solid #ECECEC;
        }
        @media (max-width: 992px) {
            justify-content: center;
        }
    `;
    const ImgWrapper = styled.div`
        width: 564px;
        height: 336px;
        max-width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 992px) {
            display: none;
        }
        img {
            width: 100%;
        }
    `;
    const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 470px;
    max-width: 100%;
    padding: 20px;
    h3 {
        color: #000000;
        font-size: 2.83rem;
        font-weight: 600;
        line-height: 37.8px;
        width: 446.99px;
        max-width: 100%;
        text-align: left;
    }
    .author-date {
        display: flex;
        margin-top: 30px;

        .author {
            color: #8A8B8A;
            font-size: 1.51rem;
            font-weight: 400;
            line-height: 22.68px;
            width: 86px;
            text-align: left;
        }
        .date {
            color: #175763;
            font-size: 1.51rem;
            font-weight: 400;
            line-height: 22.68px;
            width: 86px;
            text-align: left;
        }
    }
    .description {
        margin-top: 35px;
        color: #000000;
        font-size: 1.51rem;
        font-weight: 400;
        line-height: 22.68px;
        width: 100%;
        text-align: left;
    }
    .more {
        font-size: 1.6rem;
        margin-top: 30px;
        text-decoration: underline;
        font-weight: bold;

        a {
            color: #175763;
        }
    }
    `;
    return (   
        <NewWrapper>
            <ImgWrapper>
                <Fade left>
                    <img src={ imagen ? imagen : PressImage} alt={description}/>
                </Fade>
            </ImgWrapper>
            <InfoWrapper>
                <Fade bottom delay={500}>
                <h3>
                    {titulo}
                </h3>
                </Fade>
                
                <div className="author-date">
                    <div className="author">
                        <Fade bottom delay={750}>
                        {author}
                        </Fade>
                    </div>
                    <div className="date">
                        <Fade bottom delay={750}>
                        {date}
                        </Fade>
                    </div>
                </div>
                <div className="description">
                    <Fade bottom delay={1000}>
                    {description}
                    </Fade>
                </div>
                <div className="more">
                    <Fade bottom delay={1250}>
                    <a href={link} target="_blank" rel="noopener noreferrer">Ver más</a>
                    </Fade>
                </div>
            </InfoWrapper>
        </NewWrapper>
    )
}

export default News
