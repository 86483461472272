import React from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';
import { Trans, useTranslation } from 'react-i18next';
import PesosAhorrados from './PesosAhorrados';
import BgImage from '../../assets/images/section4bg3.gif';

const InvertiForm = () => {
  const StyledForm = styled.form`
    position: absolute;
    margin-top: -25vh;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(${BgImage});
    /*background-color: #fff;*/
    background-size: cover;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
    width: 975px;
    max-width: 100%;
    height: 2300px;
    max-width: 100%;
    padding: 73px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .react-reveal {
      width: 100%;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
    @media (max-width: 1105px) {
      .react-reveal {
        margin: 0 auto;
        width: 100%;
      }
    }
    h3 {
      color: #175763;
      font-size: 3.2rem;
      font-weight: 600;
      line-height: 44px;
      text-align: center;
    }
    p {
      margin-top: 20px;
      color: #000000;
      font-size: 2.5rem;
      font-weight: 400;
      line-height: 33px;
      width: 80% !important;
      text-align: center;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .fieldsCheck {
      margin-top: 60px;
      margin-bottom: 18px;
      width: 617px;
      max-width: 100%;
      height: 57px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .fieldWrapper {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 57px;
        input {
          width: 100%;
          height: 100%;
          background-color: white;
          border: 2px solid #175763;
          border-radius: 100px;
          -webkit-appearance: none;
          &:checked {
            background-color: #175763;
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
        label {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          color: #175763;
          font-size: 1.5rem;
          text-align: center;
          width: 100%;
        }

        input:checked + label {
          color: white;
          font-size: 1.5rem;
          transition: 0.2s;
        }
      }
    }
    .btnContainer {
      width: 100%;
      max-width: 615px;
      margin: 10px auto;

      button {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #175763;
        border-radius: 1px;
        width: 86.4px;
        height: 42px;
        color: #ffffff;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
        &:disabled {
          background-color: #b8b8b8;
          color: #333;
        }
      }
    }
  `;

  const FieldsWrapper = styled.div`
    border: 1.2px solid #f2f2f2;
    border-radius: 3.6px;
    max-width: 615px;
    margin: 0 auto;
    width: 100%;
    height: 64.8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 12px;

    label {
      font-size: 1.3rem;
      color: #fff;
      font-weight: 600;
    }
    input {
      width: 100%;
      border: none;
      background: transparent;
      font-size: 1.8rem;
      color: #b8b8b8;

      &:focus {
        outline: none;
      }
      &::placeholder {
        font-size: 1.8rem;
        color: #b8b8b8;
      }
    }
  `;

  const contactSchema = Yup.object().shape({
    name: Yup.string().required(),

    email: Yup.string().email().required(),

    tipo: Yup.string().required(),

    organization: Yup.string().required(),

    phone: Yup.string().required(),

    country: Yup.string().required(),
  });

  const sendingForm = (values, reset) => {
    const url = 'https://api.mailing.paisanoscreando.com/public/send_mail';
    let { tipo, name, email, organization, phone, country } = values;
    const { resetForm } = reset;
    const formData = new FormData();

    formData.append('clientName', 'Cazenave');
    formData.append('senderName', name);
    formData.append('senderEmail', email);
    formData.append(
      'message',
      `Nombre: ${name} <br/>Organización: ${organization} <br/>Mail: ${email} <br/>Teléfono: ${phone} <br/>País: ${country}`
    );
    formData.append('senderPhone', phone);
    formData.append('receiverEmail', 'info@e-cazenave.com.ar');
    formData.append('subject', tipo);

    axios
      .post(url, formData)
      .then((res) => {
        Swal.fire({
          type: 'success',
          title: 'Enviado',
          text: 'Nos contactaremos a la brevedad',
        });
      })
      .catch((err) => {
        Swal.fire({
          type: 'error',
          title: err.message,
          text: 'Intenta nuevamente más tarde',
        });
      });
    resetForm({
      tipo: 'Quiero alquilar mi campo',
      name: '',
      organization: '',
      email: '',
      phone: '',
      country: '',
    });
  };

  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={contactSchema}
      enableReinitialize={true}
      initialValues={{
        tipo: 'Quiero alquilar mi campo',
        name: '',
        organization: '',
        email: '',
        phone: '',
        country: '',
      }}
      onSubmit={(values, { resetForm }) => sendingForm(values, { resetForm })}
    >
      {({ handleSubmit, isValid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Fade bottom delay={500}>
            <h3>{t('Invertí con nosotros')}</h3>
          </Fade>
          <Fade bottom delay={1000}>
            <p>
              <Trans>
                {t(
                  'Aprovecha la oportunidad de INVERTIR en los <br/> Fondos del principal operador de <br/> Siembras de Argentina.'
                )}
              </Trans>
            </p>
          </Fade>
          <Fade bottom delay={1000}>
            <PesosAhorrados />
          </Fade>
          <div className="fieldsCheck">
            <Fade left>
              <div className="fieldWrapper">
                <Field
                  type="radio"
                  id="alquilar"
                  name="tipo"
                  value="Quiero alquilar mi campo"
                  defaultChecked="Quiero alquilar mi campo"
                />
                <label htmlFor="alquilar">
                  {t('Quiero alquilar mi campo')}
                </label>
              </div>
            </Fade>
            <Fade right>
              <div className="fieldWrapper">
                <Field
                  type="radio"
                  id="invertir"
                  name="tipo"
                  value="Quiero invertir en fideicomiso"
                />
                <label htmlFor="invertir">
                  {t('Quiero invertir en fideicomiso')}
                </label>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            <FieldsWrapper>
              <label htmlFor="name">{t('Nombre')}</label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder={t('Ingresa tu nombre aquí')}
              />
            </FieldsWrapper>
            <FieldsWrapper>
              <label htmlFor="organization">{t('Organización')}</label>
              <Field
                type="text"
                id="organization"
                name="organization"
                placeholder={t('Ingresa el nombre de tu organización aquí')}
              />
            </FieldsWrapper>
            <FieldsWrapper>
              <label htmlFor="email">{t('Mail')}</label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder={t('Ingresa tu email aquí')}
              />
            </FieldsWrapper>
            <FieldsWrapper>
              <label htmlFor="phone">{t('Teléfono')}</label>
              <Field
                type="text"
                id="phone"
                name="phone"
                placeholder={t('+549 11 0000 0000')}
              />
            </FieldsWrapper>
            <FieldsWrapper>
              <label htmlFor="country">{t('País')}</label>
              <Field
                type="text"
                id="country"
                name="country"
                placeholder={t('Ingresa tu país aquí')}
              />
            </FieldsWrapper>
            <div className="btnContainer">
              <button type="submit" disabled={!isValid}>
                {t('Enviar')}
              </button>
            </div>
          </Fade>
        </StyledForm>
      )}
    </Formik>
  );
};

export default InvertiForm;
