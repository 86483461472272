import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import LineRight from '../../../../assets/images/line-right.svg';
import LineLeft from '../../../../assets/images/line-left.svg';

const Year = ({top, year, outline, end}) => {

    const StyledYear = styled.div`
        background-color: #FFFFFF;
        border: ${({outline}) => outline ? '0.77px solid #175763' : 'none'};
        border-radius: 17.21px;
        width: 90.27px;
        height: 34.42px;
        color: ${({outline}) => outline ? '#175763' : 'white'};
        font-size: 1.53rem;
        font-weight: 600;
        letter-spacing: 0.23px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: ${top};
        background-color: ${({outline}) => outline ? 'white' : '#175763'};

        &:nth-child(odd)::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -465px;
            width: 454px;
            height: 9px;
            display: ${end ? "none" : "flex"};
            justify-content: center;
            align-items: center;
            background-image: url(${LineRight});
            background-size: cover;
        }

        &:not(:last-child):nth-child(even)::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -470px;
            width: 459px;
            height: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(${LineLeft});
            background-size: cover;
        }
    `;

    console.log(end);
    return (
        <StyledYear outline={outline} >
        <Fade>
           {year}
        </Fade>
        </StyledYear>
        
    )
}

export default Year
