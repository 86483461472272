import React from 'react';
import styled from 'styled-components';


const StyledSpinner = styled.div`
#loadingPage{
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 9999999;
background-color: #ffffff;
}
#loading-center-page{
width: 100%;
height: 100%;
position: relative;
}
#loading-center-absolute {
position: absolute;
left: 50%;
top: 50%;
height: 150px;
width: 150px;
margin-top: -75px;
margin-left: -75px;
}

.loader {
width: 56px;
height: 56px;
border: 8px solid #1758633f;
border-top-color: #175763;
border-radius: 50%;
position: relative;
animation: rotate 1s linear infinite;
top: 50%;
margin: -28px auto 0 auto;
}
.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
}
.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s linear;
}
@keyframes rotate {
0% {
    transform: rotate(0); }
    100% {
    transform: rotate(360deg); } }
`;
const Spinner = () => {
    return (
        <StyledSpinner>
        <div id="loadingPage" className="loadingPage">
            <div id="loading-center-page">
                <div id="loading-center-absolute">
                    <div className="loader"></div>
                </div>
            </div>
        </div>
        </StyledSpinner>
        
    )
}

export default Spinner;
