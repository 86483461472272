import React, {useState} from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";




const ContactForm = () => {

    const { t } = useTranslation();
    const [archivo, setArchivo] = useState(null);
    const [setFileName] = useState('');


    const FormWrapper = styled.form`
    padding: 67px 140px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 975px;
    max-width: 100%;
    min-height: 742px;
    background-color: white;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
    @media (max-width: 1105px) {
        padding: 20px;
        
    }
    @media (max-width: 600px) {
        .react-reveal {
        width: 100%;
        }
    }
    h2 {
        color: #175763;
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 44px;
        width: 522px;
        max-width: 100%;
        text-align: center;
    }
    p {
        margin-top: 25px;
        color: #000000;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 33px;
        width: 694px;
        max-width: 100%;
        text-align: center;
        &:last-of-type{
            margin-bottom: 80px;
        }
    }
    
    `;

    const FieldWrapper = styled.div`
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 615px;
    max-width: 100%;
    height: 64.8px;
    border: 1.2px solid #f2f2f2;
    flex-direction: column;
    label {
        font-size: 1.4rem;;
        font-weight: 600;
    }
    input {
        border: none;
        font-size: 1.8rem;
        &:focus {
            outline: none;
        }
        &::placeholder{
            color: #ccc;
        }
    }
    `;

    const TextAreaWrapper = styled.div`
    margin-bottom: 10px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    width: 615px;
    max-width: 100%;
    height: 154.8px;
    border: 1.2px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    label {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 14px;
    }
    textarea {
        height: 100%;
        border: none;
        font-size: 1.8rem;
        font-family: 'Open Sans', sans-serif;
        &:focus {
            outline: none;
        }
        &::placeholder{
            color: #ccc;
            font-family: 'Open Sans', sans-serif;
        }
    }
    `;

    const FileWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    .cv {
        font-size: 1.4rem;
        font-weight: 600;
        width: 100%;
        
    }
    
    label {
        width: 200px;
        height: 42px;
        border: 1px solid #175763;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        color: #175763;
        margin-right: 30px;
    }
    input {
        display: none;
    }
    `;
    const ButtonWrapper = styled.div`

    width: 615px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    button {
        background-color: #175763;
        border-radius: 1px;
        width: 86.4px;
        height: 42px;
        border: none;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
        transition: all .2s ease;
        &:disabled {
            cursor: not-allowed;
            background-color: #b8b8b8;
            color: #333;
        }  
    
    }
    `;

    const contactSchema = Yup.object().shape({

        name: Yup.string()
        .required(),

        email: Yup.string()
        .email()
        .required(),

        message: Yup.string()
        .required(),

    });

    

    const sendingForm = (values, reset, file) => {
        const url = 'https://api.mailing.paisanoscreando.com/public/send_mail';
        let {name, email, message} = values;
        debugger
        console.log('desde sending', file);
        const {resetForm} = reset;
        const formData = new FormData();

        formData.append('clientName', 'Cazenave');
        formData.append('senderName', name);
        formData.append('senderEmail', email);
        formData.append('message', `Nombre: ${name} <br/>Mail: ${email} <br/>Mensaje: ${message}`);
        formData.append('senderPhone', '-');
        formData.append('file', file);
        formData.append('receiverEmail', 'info@e-cazenave.com.ar');
        formData.append('subject', 'Contacto desde la web Cazenave');

        axios.post(url, formData)
        .then(res => {
            Swal.fire({
                type: 'success',
                title: 'Enviado',
                text: 'Nos contactaremos a la brevedad'
              })
        })
        .catch(err => {
            Swal.fire({
                type: 'error',
                title: err.message,
                text: 'Intenta nuevamente más tarde'
              })
        })
        resetForm({
            name: '',
            email: '',
            message: '',
            file: ''
        });
    }

    const imageChangeHandler = (event) =>{
        console.log('adentro dle handler', event.target.files[0]);
        if (event.target.files && event.target.files[0] && event.target.files[0].size < 500000) {
            if(event.target.files[0].type === 'application/pdf' || event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || event.target.files[0].type === 'application/msword') {
                
                setArchivo(event.target.files[0]);
                setFileName(event.target.files[0].name.slice(0,10) + '...')
            } else {
                setFileName('pdf/doc unicamente')
            } 
        }else {
            setFileName('No puede superar 500kb')
        }
        
    }


    
    return (
        <Formik
        validationSchema={contactSchema}
        initialValues={{
            name: '',
            email: '',
            message: '',
        }}
        onSubmit={(values, {resetForm}) => sendingForm(values, {resetForm}, archivo)}
        >
        {({handleSubmit, isValid}) => (
            <FormWrapper onSubmit={handleSubmit}>
                <Fade bottom delay={500}>
                <h2>{t('Contactate con nosotros')}</h2>
                </Fade>
                <Fade bottom delay={1000}>
                <p>{t('¿En qué te podemos ayudar?')}</p>
                </Fade>
                <Fade bottom delay={1500}>
                <p>{t('Completa el formulario, déjanos tu mensaje junto tu correo electrónico o escribinos a info@e-cazenave.com.ar. Te responderemos en la brevedad.')}</p>
                </Fade>
                <Fade bottom>
                <FieldWrapper>
                    <label htmlFor="name">{t('Nombre')}</label>
                    <Field type="text" placeholder={t("Ingresa tu nombre aquí")} name="name" />
                </FieldWrapper>
                <FieldWrapper>
                    <label htmlFor="email">{t('Email')}</label>
                    <Field type="email" placeholder={t("Ingresa tu email aquí")} name="email" />
                </FieldWrapper>
                <TextAreaWrapper>
                    <label htmlFor="message">{t('Mensaje')}</label>
                    <Field type="text" placeholder={t("Escribe aquí tu mensaje")} name="message" component="textarea"/>
                </TextAreaWrapper>
                <ButtonWrapper>
                    <FileWrapper>
                        <h5 className="cv">{t('¡Dejanos tu CV!')}</h5>
                        <label htmlFor="file">{archivo ? t('Archivo subido') : t('Subi tu archivo')}</label>
                        <Field type="file" name="file" id="file" onChange={imageChangeHandler} />
                    </FileWrapper>
                <button type="submit" disabled={!isValid}>{t('Enviar')}</button>
                </ButtonWrapper>
                </Fade>
            </FormWrapper>
        )}
            
        </Formik>
        
    )
}

export default ContactForm;
