import React from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/images/singleLogo.svg';
import background from '../../../assets/images/background-form-tech.png';
import FormTech from '../FormTech/FormTech';
import { useTranslation } from 'react-i18next';

const FormSection = () =>
{

    const { t } = useTranslation();
    const BackgroundWrapper = styled.section`
    width: 100%;
    background-image: radial-gradient(48.49% 30%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.96) 100%), url(${ background });
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imgContainer {
        width: 166px;
        height: 166px;
        border: 1px solid rgba(255,255,255,.2);
        border-radius: 50%;
        padding: 30px;
        img {
            width: 100%;
        }
    }

    .description {
        margin: 60px;
        max-width: 578px;
        color: #018079;
        font-size: 2rem;;
        line-height: 40px;
        text-align: center;
        position: relative;

        &::before {
        display: block;
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        top: -30px;
        left: -25px;
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        opacity: .2;
    }
    &::after {
        display: block;
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        top: -30px;
        right: -25px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        opacity: .2;
    }
    .border {
            &::before {
            display: block;
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: -30px;
            left: -25px;
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
            opacity: .2;
        }
        &::after {
            display: block;
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: -30px;
            right: -25px;
            opacity: .2;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
        }
    }
    }
    `;

    return (
        <BackgroundWrapper>
            <div className="imgContainer">
                <img src={ Logo } alt="Cazenave" />
            </div>
            <div className="description">
                { t( 'Cazenave es la empresa líder de Argentina de servicios del Agro, con más de 50 años de historia, adaptada a las NUEVAS TECNOLOGIAS EXPONENCIALES con EXCELENCIA' ) }
                <span className="border"></span>
            </div>
            <FormTech />
        </BackgroundWrapper>
    )
}

export default FormSection;
