import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import titleLine from '../../../assets/images/line-title.svg';
import objetivoBackground from '../../../assets/images/objetivo-background.jpg';
import { ReactComponent as OvalTop } from '../../../assets/images/objetivo-oval-top.svg';
import { ReactComponent as ObjPag } from '../../../assets/images/objetivo-pag.svg';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';


const StyledSection = styled.section`
display: flex;
width: 100%;
max-width: 1280px;
margin: 0 auto;
position: relative;
min-height: 800px;

@media (max-width: 1105px) {
    min-height: 500px;
    flex-wrap: wrap;
    padding: 20px;
}
`;

const ObjectiveWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding: 40px;
width: 50%;
@media (max-width: 1105px) {
    width: 100%;
    padding: unset;
}
h2 {
color: var(--color-main);
font-size: 2.8rem;
font-weight: 400;
line-height: 27px;
position: relative;

&::after {
    content:'';
    position: absolute;
    background-image: url(${ titleLine });
    background-size: contain;
    background-repeat: no-repeat;
    width: 93px;
    height: 14px;
    bottom: -22.5px;
    left: 0;
}
}
p {
margin-top: 46px;
color: var(--color-text);
font-size: 2.2rem;
font-weight: 300;
line-height: 45px;
width: 500px;
max-width: 100%;
text-align: left;
}
`;

const ImgWrapper = styled.div`
position: absolute;
width: 50%;
height: 100%;
top: -20px;
right: 0;
padding-right: 320px;
z-index: 1;
background-image:url(${ objetivoBackground });
background-size: cover;
background-position: center;
display: flex;
align-items: center;
background-color: black;
@media (max-width: 1700px) {
    padding: 80px;
}
@media (max-width: 1105px) {
    position: static;
    width: 100%;
    min-height: 390px;
    padding: 20px;
}
.obj-oval-top {
    position: absolute;
    top: 10%;
    left: 40%;
    transform: translateX(-50%);

    transform-origin: center;
    animation: 70s linear infinite;
    animation-name: rotate;
    @media (max-width: 1105px) {
        display: none;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(1turn);
        }
    }
}
.obj-pag {
    position: absolute;
    top: 15%;
    right: 44px;
    width: 127px;
    height: 74px;
} 
span {
    color: #F3C52F;
}


p {
margin: 0 auto;
margin-top: 40px;
color: #FFFFFF;
font-size: 2.2rem;
font-weight: 400;
line-height: 45px;
width: 503px;
text-align: right;
}
`;

const LinkContainer = styled.div`
margin-top: 20px;

a {
display: flex;
justify-content: center;
align-items: center;
border: 1px solid var(--color-main);
color: var(--color-main);
font-size: 1.2rem;
border-radius: 52px;
width: 130px;
height: 38px;
}
`;

const DivisionWrapper = styled.div`
position: absolute;
top: 67%;
width: 100%;
height: 663.81px;
transform: skewY(-8deg);
background-color: #fafafa;

@media (max-width: 1105px) {
    display: none;
}

`;

const NuestroObjetivo = () =>
{
    const { t } = useTranslation();
    return (
        <>
            <StyledSection>
                <ObjectiveWrapper>
                    <Fade delay={ 300 }>

                        <h2>{ t( 'Nuestro Objetivo' ) }</h2>
                    </Fade>
                    <Fade delay={ 500 }>
                        <p>{ t( 'Desarrollamos negocios en la cadena de la agroindustria, buscando promover el crecimiento económico y humano en un marco sustentable.' ) }</p>
                    </Fade>
                    <LinkContainer>
                        <Fade bottom delay={ 700 }>
                            <Link to='/historia'>{ t( 'Nuestra Historia' ) }</Link>
                        </Fade>
                    </LinkContainer>
                </ObjectiveWrapper>
            </StyledSection>
            <ImgWrapper>
                <OvalTop className="obj-oval-top" />
                <ObjPag className="obj-pag" />

                <Fade delay={ 1200 }>
                    <p>{ t( 'Somos la empresa líder de Argentina de servicios del Agro, con más de 50 años de historia, adaptada a las ' ) }<span>{ t( 'NUEVAS TECNOLOGíAS EXPONENCIALES' ) }</span> { t( 'con ' ) }<span>{ t( 'EXCELENCIA' ) }</span>
                    </p>
                </Fade>
            </ImgWrapper>
            <DivisionWrapper />
        </>
    )
}

export default NuestroObjetivo;
