import React, {useState, useEffect} from 'react';
import Header from '../../components/CasaTech/Header/Header';
import Spinner from '../../components/UI/Spinner/Spinner';
import Proyectos from '../../components/CasaTech/Proyectos/Proyectos';
import FormSection from '../../components/CasaTech/FormSection/FormSection';

const CasaTech = () => {

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scroll({top: 0, left:0})
        setIsLoading(false);
    }, []);

    return (
        
        isLoading ?
        <Spinner /> :
        <>
            <Header />
            <Proyectos />
            <FormSection />
        </>
    )
}

export default CasaTech;
