import React from 'react';
import AgriculturaSatelitalbg from '../../../assets/images/AgriculturaSatelitalbg.png';
import { AgriculturaSatelitalWrapper } from './AgriculturaSatelital.styles';

function AgriculturaSatelital() {
  return (
    <AgriculturaSatelitalWrapper
      id="agricultura-satelital"
      backgroundImage={AgriculturaSatelitalbg}
    >
      <div>
        <h2>Agricultura Satelital</h2>
        <p className="quote-text">
          En nuestras recorridas de lotes siempre hemos querido "verle la cara
          al cultivo". Una imagen satelital nos da la información que devuelven
          las plantas de un cultivo, dándonos <b>una idea</b> acerca de su
          <b> estado de salud</b>, y nos presenta esa "cara" en forma más
          <b> nítida y cuantificada</b> para poder
          <b> ir a verla en forma ordenada y corregirla</b> si fuera posible
        </p>
        <br />
        <p>
          Empezamos por clasificar las peores y mejores partes de un cultivo
          dentro de un lote por medio de información satelital. En 2016
          iniciamos nuestra propia plataforma a la que subimos información, más
          precisa, en dos formatos: <b>mapas</b> de tosca, altimetría,
          rendimiento y ambientes; e <b>imágenes</b> satelitales procesadas como
          indicadores que están relacionados a la biomasa fotosintéticamente
          activa a nivel de lote.
        </p>
        <br />
        <p className="subtitle-text">
          Principales objetivos de la observación de imágenes satelitales
        </p>
        <ol>
          <li>
            1. Observar el cultivo de un lote <b>en el espacio</b> por medio del
            NDVI clasificado en rangos nos ayudará a:
            <b> ubicar y cuantificar</b>
            partes más y menos
            <b> saludables dentro del lote, recorrer y verificar</b> a campo
            esas
            <b> diferencias y decidir</b> si merecen algún
            <b> tratamiento diferencial</b>
          </li>
          <li>
            2. Observar la <b>evolución del ndvi a lo largo del tiempo</b>, nos
            puede dar una idea aproximada del <b>volumen de producción</b>
          </li>
          <li>
            3. Observar <b>anomalías</b> en el comportamiento de la curva nos
            muestra ocurrencia de <b>contingencias</b> climáticas o de otra
            causa y poder mapear su incidencia
          </li>
          <li>
            4. La información satelital es parte de la requerida para la
            <b>TRAZABILIDAD</b> del producto. Por eso será útil guardarla
          </li>
        </ol>
        <p className="subtitle-text">Servicios que se prestan</p>
        <ol>
          <li>
            1. Seguimiento de cultivo. Se comparte a ingenieros y coordinación
            de Cazenave o terceros el lote o campo en la plataforma propia o
            contratada, y se envía informe con evolución de ndvi graficada, y
            últimas imágenes en forma periódica con objetivo de organizar
            recorrida. Para trigos de calidad o candeal, se adjuntan al informe
            imágenes de indicadores de clorofila que dan orientación a los
            monitoreos de nutrición de N del cultivo
          </li>
          <li>
            2. Imágenes históricas. Se elaboran informe con imágenes de interés
            que permitan una "pre-visualización" de lotes a revisar con vistas a
            arrendamiento
          </li>
          <li>
            3. Contingencias. Ante ocurrencia de helada, granizo, exceso de agua
            u otra causa se analiza la posibilidad de elaborar un mapa de
            incidencia, con la ubicación de zonas más y menos afectadas
          </li>
          <li>4. Estimación de superficie sembrada de un cultivo.</li>
          <li>5. Mapas de rendimiento: limpieza y procesamiento</li>
          <li>
            6. Mapas de pre-ambientación de campos con fines inmobiliarios
          </li>
          <li>
            7. Mapas de pre-ambientación de lotes para prescripción de insumos
          </li>
          <li>
            8. Evolución de ndvi y marcado de puntos representativos de
            distintos ambientes por lote para la de estimación de rindes
          </li>
        </ol>
      </div>
    </AgriculturaSatelitalWrapper>
  );
}

export default AgriculturaSatelital;
