import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Backdrop from './Backdrop/Backdrop';


const WrapperModal = styled.div`
position: fixed;
top: 0;
right: 0;
left: 0;
bottom: 0;
background-color: #fff;
margin: 50px auto 0 auto;
overflow: auto;
border-radius: 4px;
z-index: 101;
width: 500px;
max-width: 100vw;
height: ${({person}) => person ? '270px' : '500px'};
max-height: 90vh;
/* padding: 143px 69px 64px 68px; */
display: ${({opened}) => opened ? 'flex':'none'};
transition: all .2s;
`;

const InsideWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1105px) {
        padding: 20px;
    }
`;


const Modal = ({opened, close, children, person}) => {
    return ReactDOM.createPortal (
        <>
            <Backdrop close={close} opened={opened} />
            <WrapperModal person={person} opened={opened}>
                <InsideWrapper>
                    {children}
                </InsideWrapper>
            </WrapperModal>
        </>, document.getElementById('root-modal')
    )
}

export default Modal;
