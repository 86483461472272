import React, { useState, useEffect } from 'react';

import { NavLink, withRouter } from 'react-router-dom';
import Logo from '../../assets/images/cazenave-newLogo.svg';
import LogoWhite from '../../assets/images/cazenave-logo-white.png';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import arrow from '../../assets/images/arrow-down.svg';

const WrapperNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 122px;
  background-color: ${({ transparent }) =>
    transparent ? 'transparent' : '#fff'};
  border-bottom: ${({ transparent }) =>
    transparent ? 'none' : '1px solid #F7F4F4'};
  box-shadow: ${({ transparent }) => (transparent ? 'transparent' : '#fff')};
  transition: all 0.3s;
  @media (max-width: 1105px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1310px;
  padding: 0 10px;
  align-items: center;
`;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 200.7px;
  margin-right: 50px;
  margin-bottom: 25px;
  img {
    width: 100%;
  }
`;

const NavWrapper = styled.ul`
  display: flex;
  align-items: center;
  li {
    list-style: none;
    position: relative;
    &:not(:last-child) {
      margin-right: 44px;
    }
    &:not(:first-child)::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -22px;
      width: 1px;
      height: 4.06px;
      background-color: rgba(0, 0, 0, 0.2);
    }
    a {
      font-size: 1.28rem;
      color: ${({ color }) => (color ? '#FFF' : 'rgba(0,0,0, .56)')};
    }
    .select-language {
      position: relative;
      border-bottom: 1px solid;
      border-bottom-color: ${({ color }) =>
        color ? '#FFF' : 'rgba(0,0,0, .56)'};

      .language-container {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        transform: scale(0);
        transition: 1s ease;
        transform-origin: top;
        z-index: 10;
        &.active {
          display: flex;
          transform-origin: top;
          transform: scale(1);
        }

        button {
          color: ${({ color }) => (color ? '#FFF' : 'rgba(0,0,0, .56)')};
          font-size: 1.28rem;
          background: none;
          border: none;
          text-align: left;
          margin-top: 10px;
          cursor: pointer;
        }
      }

      font-weight: 600;
      font-size: 1.28rem;
      color: ${({ color }) => (color ? '#FFF' : 'rgba(0,0,0, .56)')};
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -14px;
        mask-image: url(${arrow});
        mask-size: cover;
        mask-position: center;
        background: ${({ color }) => (color ? '#FFF' : 'rgba(0,0,0, .56)')};
        width: 10px;
        height: 10px;
      }
    }
  }
`;

const StyledLink = styled(NavLink)`
  &.active {
    font-weight: 600;
    color: ${({ color }) => (color ? '#FFF' : '#000')};
    padding-bottom: 11px;
    border-bottom: 1px solid var(--color-main);
  }
`;

const Nav = ({ transparent, tipoNav, history }) => {
  const [scroll, setScroll] = useState(false);
  const [toggleLan, setToggleLan] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleLanguage = () => {
    setToggleLan(!toggleLan);
  };

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 2;
      // console.log(window.scrollY)
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  if (tipoNav === 'home') {
    scroll ? (transparent = false) : (transparent = true);
  }
  let colorTexto = history.location.pathname === '/casa-tech' && transparent;
  return (
    <WrapperNav transparent={transparent}>
      <Wrapper>
        <ImgWrapper>
          <NavLink to="/">
            <img src={colorTexto ? LogoWhite : Logo} alt="Logo" />
          </NavLink>
        </ImgWrapper>
        <NavWrapper transparent={transparent} color={colorTexto}>
          <li>
            <StyledLink exact to="/">
              Home
            </StyledLink>
          </li>
          <li>
            <StyledLink to="/historia">{t('Historia')}</StyledLink>
          </li>
          <li>
            <StyledLink to="/que-hacemos">{t('¿Qué hacemos?')}</StyledLink>
          </li>
          <li>
            <StyledLink to="/inverti">{t('Invertí')}</StyledLink>
          </li>
          <li>
            <StyledLink to="/ucropit">ucrop.it</StyledLink>
          </li>
          <li>
            <StyledLink to="/prensa">{t('Prensa')}</StyledLink>
          </li>
          <li>
            <StyledLink to="/rse">{t('RSE')}</StyledLink>
          </li>
          <li>
            <StyledLink to="/contacto">{t('Contacto')}</StyledLink>
          </li>
          <li>
            <div className={`select-language`} onClick={() => toggleLanguage()}>
              {t('Seleccioná idioma')}
              <div
                className={`language-container ${toggleLan ? 'active' : null}`}
              >
                <button onClick={() => changeLanguage('es')}>ESPAÑOL</button>
                <button onClick={() => changeLanguage('en')}>ENGLISH</button>
                <button onClick={() => changeLanguage('por')}>PORTUGUÊS</button>
              </div>
            </div>
          </li>
        </NavWrapper>
      </Wrapper>
    </WrapperNav>
  );
};

export default withRouter(Nav);
