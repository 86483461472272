import React from "react";
import "./section5.css";

const Section5 = () => {
  return (
    <div className="section-5">
      <hr />
      <p>
        <strong>Trabajamos</strong> en cada una de nuestras unidades y
        proyectos, para contribuir a los{" "}
        <strong>Objetivos de Desarrollo Sostenible</strong> que componen la
        Agenda al <strong>2030</strong> para el Desarrollo Sostenible aprobada.
      </p>
    </div>
  );
};

export default Section5;
