import React from 'react';
import styled from 'styled-components';
import Background from '../../assets/images/sustentabilidad-background.jpg';
import curve from '../../assets/images/curve-history.png';
import Fade from 'react-reveal/Fade';
import {useTranslation} from 'react-i18next';
import Subtitle from './subtitle';
const HeaderRSE = () => {

    const {t} =  useTranslation();
    const StyledHeader = styled.header`
    background-image: url(${Background});
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 0;
        mask-image: url(${curve});
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: top center;
        background: #FFF;
        animation: apear 1s ease;
        animation-fill-mode: forwards;
        animation-delay: .7s;
        @keyframes apear {
            0% {
                height: 0;
            }
            50% {
                height: 225px;
            }
            80% {
                height: 215px;
            }
            100% {
                height: 220px;
            } 
        }
    }
    `;
    const StyledTitle = styled.h1`
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        font-size: 6rem;
        font-weight: 600;
        top: 43%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        /* text-shadow: 0px 2px 10px #000000; */
        @media (max-width: 1105px) {
            font-size: 4rem;
        }
    `;

    const StyledSubtitle = styled.h2`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 20px;
    span {
        font-size: 1.7rem;
        color: gray;
        font-weight: 100;
        margin-bottom: 8px;
    }
    font-size: 3.3rem;
    font-weight: 300;
    color: #2c2c2c;
    position: absolute;
    bottom: -23%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    .react-reveal {
        max-width: 100%;
    }
    @media (max-width: 600px) {
        bottom: -26%;
    }
    `;


    return (
        <StyledHeader>
            <StyledTitle>
                <Fade delay={300}>
                    {t('Sustentabilidad')}
                </Fade>
            </StyledTitle>
            <StyledSubtitle>
                <Fade bottom delay={1500}>
                    <Subtitle />
                </Fade>
            </StyledSubtitle>
        </StyledHeader>
    )
}

export default HeaderRSE
