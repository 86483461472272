import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/bg-image-consultoria.jpg';
import { useTranslation } from 'react-i18next';

const Consultoria = () => {
  const { t } = useTranslation();

  const BackgroundWrapper = styled.section`
    background-image: radial-gradient(
        55.89% 94.49%,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url(${background});
    background-size: cover;
    background-position: center;
    width: 1105px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 80px;
    @media (max-width: 1105px) {
      padding: 0;
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 1105px) {
          max-width: 100%;
          padding: 20px;
        }
        h2 {
          color: #facb30;
          font-size: 3.3rem;
          font-weight: 400;
          line-height: 50px;
          text-align: center;
          margin-bottom: 10px;
        }
        p {
          color: #ffffff;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 24px;
          width: 487px;
          max-width: 100%;
          text-align: center;
          margin-bottom: 30px;
          &:last-of-type {
            width: 400px;
          }
        }
      }

      @media (min-width: 1105px) {
        align-items: flex-end;
      }
    }
  `;

  return (
    <BackgroundWrapper id="consultoria">
      <div className="textContainer">
        <div className="text">
          <h2>{t('Cazenave Consultoría')}</h2>
          <p>
            {t(
              'Brindamos los servicios de consultoría, auditoría y análisis de negocios en distintos sectores del agribusiness.'
            )}
          </p>
          <p style={{ maxWidth: '100%' }}>
            {t(
              'Ofrecemos asesoramiento estratégico sobre la producción, comercialización y tecnologías exponenciales aplicadas al agro tanto a empresas argentinas y como de la región.'
            )}
          </p>
        </div>
      </div>
    </BackgroundWrapper>
  );
};

export default Consultoria;
