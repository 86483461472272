import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const ObjetivoItem = ({title, color}) => {

    const StyledTitle = styled.h3`
        position: relative;
        color: ${color};
        font-size: 1.7rem;
        font-weight: 400;
        letter-spacing: -0.14px;
        line-height: 31.58px;
        width: 268px;
        text-align: center;

        &:nth-child(1)::after {
            content: '';
            position: absolute;
            top: -10px;
            right: -10px;
            width: 1px;
            height: 60px;
            background-color: #DBDBDB;
            @media (max-width: 1105px) {
                display:none;
            }
        }
        &:nth-child(2)::after {
            content: '';
            position: absolute;
            top: 0;
            right: -40px;
            width: 1px;
            height: 60px;
            background-color: #DBDBDB;
            @media (max-width: 1105px) {
                display:none;
            }
        }
        &:nth-child(3)::after {
            content: '';
            position: absolute;
            top: -14px;
            right: -10px;
            width: 1px;
            height: 60px;
            background-color: #DBDBDB;
            @media (max-width: 1105px) {
                display:none;
            }
        }
    `;

    return (

        <StyledTitle>
        <Fade bottom>
        {title}
        </Fade>
        </StyledTitle>

        
    )
}

export default ObjetivoItem
