import React, {useState} from 'react';
import styled from 'styled-components';
import {NavLink, withRouter} from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/images/cazenave-newLogo.svg';
import Hamburguesa from './Hamburguesa/Hamburguesa';
import { useTranslation } from "react-i18next";

const FixedWrapper = styled.nav`
    position: fixed;
    background-color: #fff;
    height: 9rem;
    top: 0;
    left: 0;
    padding: 0 2rem;
    width: 100%;
    display: none;
    z-index: 6;
    box-shadow: #fff;
    border-bottom: 1px solid #F7F4F4;
    @media (max-width: 1105px) {
        display: flex;
        width: 100%;
    }
`;

const Wrapper = styled.div`
display: flex;
height: 100%;
width: 100%;
justify-content: space-between;
align-items: center;
.logo {
    max-width: 25rem;
    margin-left: 2.5rem;
}
`;

const Menu = styled.div`
width: 100%;
height: 100vh;
visibility: ${props => (props.opened ? 'visible' : 'hidden')};
background-color: white;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
top: 0;
left: 0;
z-index: 5;
margin-top: 9rem;
transform: translateY(${props => (props.opened ? '0%' : '-100%')});
transition: all .2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
display: none;

@media (max-width: 1105px) {
    display: flex;
    width: 100%;
}
`;


const NavWrapper = styled.ul`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    li {
        margin-top: 30px;
        list-style: none;
        position: relative;
        a {
            font-size: 1.6rem;
            color: ${({color}) => color ? '#FFF': 'rgba(0,0,0, .56)'};
        }

        button {
            color: ${({color}) => color ? '#FFF': 'rgba(0,0,0, .56)'};
            font-size: 1.4rem;
            background: none;
            border: none;
            cursor: pointer;
        }
    }
    .language-buttons {
        width: 60%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 50px;
    }
`;

const StyledLink = styled(NavLink)`
width: 100%;
&.active{
    font-weight: 600;
    color: ${({color}) => color ? '#FFF': '#000'};
    padding-bottom: 11px;
    border-bottom: 1px solid var(--color-main);
}
`;
const SideDrawer = () => {

    const [isOpened, setIsOpened] = useState(false);
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    return (
        <>
            <FixedWrapper>
                <Wrapper>
                    <Logo className="logo"/>
                    <Hamburguesa opened={isOpened} clicked={() => setIsOpened(!isOpened)} />
                </Wrapper>
            </FixedWrapper>
            <Menu opened={isOpened}>
                <NavWrapper>
                    <li>
                        <StyledLink onClick={() => setIsOpened(!isOpened)} exact to='/'>Home</StyledLink>
                    </li>
                    <li>
                        <StyledLink onClick={() => setIsOpened(!isOpened)} to='/historia'>{t('Historia')}</StyledLink>
                    </li>
                    <li>
                        <StyledLink onClick={() => setIsOpened(!isOpened)} to='/que-hacemos'>{t('¿Qué hacemos?')}</StyledLink>
                    </li>
                    <li>
                        <StyledLink onClick={() => setIsOpened(!isOpened)} to='/inverti'>{t('Invertí')}</StyledLink>
                    </li>
                    <li>
                        <StyledLink onClick={ () => setIsOpened( !isOpened ) } to='/ucropit'>ucrop.it</StyledLink>
                    </li>
                    <li>
                        <StyledLink onClick={() => setIsOpened(!isOpened)} to='/prensa'>{t('Prensa')}</StyledLink>
                    </li>
                    <li>
                        <StyledLink onClick={() => setIsOpened(!isOpened)} to='/rse'>{t('RSE')}</StyledLink>
                    </li>
                    <li>
                        <StyledLink onClick={() => setIsOpened(!isOpened)} to='/contacto'>{t('Contacto')}</StyledLink>
                    </li>
                    <li className="language-buttons">
                        <button onClick={() => changeLanguage('es')}>ESPAÑOL</button>
                        <button onClick={() => changeLanguage('en')}>ENGLISH</button>
                        <button onClick={() => changeLanguage('por')}>PORTUGUÊSE</button>
                    </li>
                </NavWrapper>
            </Menu>
        </>
    )
}

export default withRouter(SideDrawer)
