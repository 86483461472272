import React from 'react';
import styled from 'styled-components';


const WrapperItem = styled.div`
    width: 365px;
    max-width: 100%;
    height: ${({today}) => today ? 'auto' : '285px'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: ${({today}) => today ? 'none' : '60px 0'};
`;

const StyledYear = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 80px;
height: 31.64px;
border: 1px solid #175763;
background-color: white;
border-radius: 100px;
font-size: 1.4rem;
color: #175763;
`;
const ImgWrapper = styled.div`
    display: ${({today}) => today ? 'none' : 'flex'};
    height: 182px;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    img {
        width: 100%;
        max-height: 160px;
        background-size: contain contain;
    }
    `;
    ;
const TextWrapper = styled.div`
background-color: #f2f2f2;
width: 100%;
height: 80px;
font-size: 14px;
text-align: center;
`;
const LineItemMobile = ({year, image, description, today}) => {
    return (
        <WrapperItem today={today}>
            <StyledYear>
                {year}
            </StyledYear>
            <ImgWrapper today={today}>
            <img src={image} alt="Fundacion"/>
            <TextWrapper>
                {description}
            </TextWrapper>
            </ImgWrapper>
            
        </WrapperItem>
    )
}

export default LineItemMobile;
