import React from 'react';
import styled from 'styled-components';
import Modal from '../../../UI/Modal/Modal';
import {ReactComponent as LinkedIn} from '../../../../assets/images/linkedInIcon.svg';



const ModalPersona = ({opened, close, name, image, title, description}) => {

    const ImgWrapper = styled.div`
    width: 140px;
    max-width: 100%;
    height: 140px;
    overflow: hidden;
    clip-path: circle(50% at 50% 50%);
    @media(max-width: 1105px) {
        width: 100px;
        height: 100px;
    }
    img {
        width: 100%;
    }
    `;

    const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 245px;
    max-width: 100%;

    h4 {
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        span {
            margin-top: 14px;
            font-size: 1.3rem;
            font-weight: 300;
        }
    }
    p {
        margin-top: 40px;
        font-size: 1.3rem;
    }
    @media(max-width: 1105px) {
            padding: 10px;
        }
    .linkedIn {
    margin-top: 10px;

        a {

            svg {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;

                g{
                    g{
                        rect {
                            fill: #dadada;
                            transition: .2s;
                        }
                    }
                }
            }
        }

        &:hover {
            a {
                svg {
                    g{
                        g{
                            rect {
                                fill: #5498CC;
                            }
                            
                        }
                    }
                }
            }
        }
    }
    `;

    return (
        <Modal person opened={opened} close={close}>
            <ImgWrapper>
            <img src={image} alt={name}/>
            </ImgWrapper>

            <TextWrapper>
                <h4>{name} <span>{title}</span></h4>
                <p>{description}</p>
                <div className="linkedIn">
                    <a href="!#">
                        <LinkedIn />
                    </a>
                </div>
            </TextWrapper>
        </Modal>
    )
}

export default ModalPersona;
