import React from 'react';
import background from '../../../assets/images/background-header.jpg';
import logoGensus from '../../../assets/images/GensusLogo-dark.png';
import bgGensus from '../../../assets/images/bg-gensus.jpg';
import logoUcrop from '../../../assets/images/ucropit.svg';
import bgUcrop from '../../../assets/images/backgroundUcrop.jpg';
import logoFideicomisos from '../../../assets/images/fideicomisos-siembra.png';
import bgInverti from '../../../assets/images/backgroundInverti.jpg';
import logoArgencampos from '../../../assets/images/argencampos.png';
import bgArgencampos from '../../../assets/images/argencampos-bg.jpg';
import logoCasatech from '../../../assets/images/logo-casatech.png';
import bgCasatech from '../../../assets/images/backgroundCasatech.jpg';
import jorgeHCazenave from '../../../assets/images/jorgeHCazenave.webp';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import HeaderMobile from './HeaderMobile';
import InitialSlide from './InitialSlide';
import { Link } from 'react-router-dom';

const StyledHeader = styled.header`
  height: 100%;
  position: relative;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    display: none;
  }
  .selected .carousel-background {
    width: 100vw !important;
  }
  .behind {
    z-index: -10;
  }
  .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.45) 100%
    ) !important;
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.45) 100%
    ) !important;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.45) 100%
    ) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#cc000000',GradientType=1 ) !important;
  }
  .carousel-background {
    object-fit: cover;
    height: 100vh !important;
    width: 99vw !important;
    z-index: -1;
  }
  .carousel-link {
    height: 100vh !important;
    width: 100vw !important;
    position: absolute;
    z-index: 1;
  }
  .carousel-logo-img {
    height: auto !important;
    width: 80% !important;
  }
  .carousel-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 16vw !important;
    width: 30vw !important;
    border-radius: 30px;
    margin-top: -300px;
    font-familiy: 'Open Sans', sans-serif !important;
    font-size: 65px;
    font-weight: 600;
  }
  .bigger {
    height: 20vw !important;
    width: 40vw !important;
  }
  .legend {
    max-width: 80vw;
    height: 30vh !important;
    padding: 30px 40px !important;
    opacity: 1 !important;
    font-size: 1.15vw !important;
    left: 55% !important;
    bottom: 30px !important;
    border-radius: 30px !important;
    color: white !important;
    background-color: transparent !important;
    hr {
      margin: 20px 15vw;
      border-color: white !important;
    }
    h2 {
      margin-bottom: 10px;
    }
  }
  li:hover {
    filter: none !important;
    transform: none !important;
  }
  .link {
    border: none;
    border-radius: 39.94px;
    width: 422px;
    max-width: 45%;
    height: 58px;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .buttons-container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: 5vh;
    z-index: 1;
  }
  .buy-button {
    color: #ffffff;
    background-color: #175763;
  }
  .sell-button {
    background-color: #ffffff;
    color: #175763;
  }
`;

const StyledMobileHeader = styled.header`
  display: none;

  @media (max-width: 720px) {
    display: block;
  }
`;

const Header = () => {
  return (
    <>
      <StyledHeader>
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          interval={8500}
          showStatus={false}
          showThumbs={false}
          autoFocus={true}
          stopOnHover={false}
          useKeyboardArrows={true}
        >
          {}
          {/* <div className="carousel-item">
            <a
              href="https://www.linkedin.com/feed/update/urn:li:activity:6953445242029146112/"
              target="_blank"
              rel="noopener noreferrer"
              className="carousel-link"
            >
              {' '}
            </a>
            <img
              src={jorgeHCazenave}
              alt="Jorge H Cazenave"
              className="carousel-background"
            />
            <p className="legend">
              <h2>Despedimos a Jorge H. Cazenave</h2>
              Jorge revolucionó el concepto de ingeniero agrónomo. Lo hizo sumar
              a la investigación y el escritorio, la extensión, el
              asesoramiento. <br />Y no solo a productores sino a todos los que
              estaban relacionados con la producción agropecuaria: bancos,
              empresas proveedoras de maquinaria y de insumos, compañías de
              seguro, exportadores, molinos, fábricas.
            </p>
          </div> */}
          {/* Puente hacia el Futuro */}
          <div className="carousel-item">
            <InitialSlide />
            <img
              src={background}
              alt="Fondo ucrop"
              className="carousel-background behind"
            />
          </div>
          {/* UCROPIT */}
          <div className="carousel-item">
            <a
              href="https://ucrop.it"
              target="_blank"
              rel="noopener noreferrer"
              className="carousel-link"
            >
              {' '}
            </a>
            <div className="carousel-logo">
              <img
                src={logoUcrop}
                alt="Logo ucrop"
                className="carousel-logo-img"
              />
            </div>
            <img
              src={bgUcrop}
              alt="Fondo ucrop"
              className="carousel-background"
            />
            <p className="legend">
              Una plataforma de libre acceso que potencia e incentiva al
              productor agropecuario con los beneficios que genera la
              sustentabilidad. A través del registro simple de las operaciones a
              campo en la app y su validación por firmas electrónicas de sus
              responsables, verificación de Certificadoras internacionales y la
              participación en licencias de empresas de insumos, semillas,
              exportadoras, alimenticias y financieras. El productor accede a
              los incentivos por su digitalización y a diferentes beneficios
              ofrecidos por las empresas, mostrando sus prácticas sustentables
              que realiza habitualmente para la producción agrícola.
            </p>
          </div>
          {/* GENSUS */}
          <div className="carousel-item">
            <Link
              to={{
                pathname: '/que-hacemos',
              }}
              className="carousel-link"
            />
            {/* <a
              href="/que-hacemos"
              rel="noopener noreferrer"
              className="carousel-link"
            >
              {' '}
            </a> */}
            <div className="carousel-logo">
              <img
                src={logoGensus}
                alt="Logo gensus"
                className="carousel-logo-img"
              />
            </div>
            <img
              src={bgGensus}
              alt="Fondo gensus"
              className="carousel-background"
            />
            <p className="legend">
              Gensus junto a la Experimental INTA Sáenz Peña, lanzaron 3 nuevas
              variedades de semillas de algodón. Se trata de GUAZUNCHO 4 INTA
              BGRR, GUARANÍ INTA BGRR y PORÁ INTA BGRR, variedades de ciclo
              intermedio-corto que se suman a la familia tradicional de Gensus.
              <hr />
              Gensus y Bioheuris se asocian para poner en marcha un programa de
              desarrollo de variedades de algodón resistentes a herbicidas.
              Harán una inversión local en tecnologías de punta para ofrecer
              nuevas técnicas adaptadas al mercado local.
            </p>
          </div>
          {/* INVERTI */}
          <div className="carousel-item">
            <Link
              to={{
                pathname: '/inverti',
              }}
              className="carousel-link"
            />
            {/* <a
              href="/inverti"
              rel="noopener noreferrer"
              className="carousel-link"
            >
              {' '}
            </a> */}
            <div className="carousel-logo">
              <img
                src={logoFideicomisos}
                alt="Logo Fideicomisos"
                className="carousel-logo-img"
              />
            </div>
            <img
              src={bgInverti}
              alt="Fondo inverti"
              className="carousel-background"
            />
            <p className="legend">
              <h2>
                ¿TENÉS PESOS AHORRADOS?
                <br />
                ¿QUERÉS INVERTIR EN UN NEGOCIO LINKEADO AL DÓLAR OFICIAL?
              </h2>
              Podés invertir participando en el Fideicomiso Cazenave Siembras
              (FCS) que exclusivamente produce granos en distintas zonas de la
              pradera pampeana.
              {/* <div>
                                <ul>
                                    <li>La rentabilidad estimada para esta campaña 2020/21 es 18%</li>
                                    <li>La rentabilidad histórica del FCS (2016-2020) es 15%</li>
                                    <li>La duración de la inversión es de 12 a 15 meses</li>
                                </ul>
                            </div> */}
            </p>
          </div>
          {/* ARGENCAMPOS */}
          <div className="carousel-item">
            <a
              href="https://argencampos.com/inicio/"
              target="_blank"
              rel="noopener noreferrer"
              className="carousel-link"
            >
              {' '}
            </a>
            <div className="carousel-logo bigger">
              <img
                src={logoArgencampos}
                alt="Logo argencampos"
                className="carousel-logo-img"
              />
            </div>
            <img
              src={bgArgencampos}
              alt="Fondo argencampos"
              className="carousel-background"
            />
            <p className="legend">
              <h2>
                Compra y Venta de Campos, Arrendamientos, Tasaciones y
                Divisiones Familiares.{' '}
              </h2>
              Ofrecemos desde el análisis del mercado, valuación, proyectos de
              inversión, detección de potenciales contrapartes y negociación
              hasta el cierre del acuerdo.
              <br />
              Utilizamos tecnologías modernas potenciando el uso de plataformas
              satelitales y todas las teconologías disponibles de agtechs.
            </p>
            <div className="buttons-container">
              <button
                onClick={() => window.open('https://argencampos.com/inicio/')}
                className="link buy-button"
              >
                Quiero Comprar un Campo
              </button>
              <button
                onClick={() => window.open('https://argencampos.com/inicio/')}
                className="link sell-button"
              >
                Quiero Vender mi Campo
              </button>
            </div>
          </div>
        </Carousel>
      </StyledHeader>
      <StyledMobileHeader>
        <HeaderMobile />
      </StyledMobileHeader>
    </>
  );
};

export default Header;
