import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/before-map-background.png';

const BeforeMap = () => {

    const StyledBackground = styled.section`
        width: 100%;
        height: auto;
        background-color: #f2f2f2;
        @media(min-width: 1106px) and (max-width: 1280px) {
            margin-top: 800px;
        }
        img {
            width: 100%;
        }
        @media (max-width: 1105px) {
            height: 100%;
            width: unset;
            overflow: hidden;
            display: flex;
            justify-content: center;
            img {
                width: unset;
                height: 100%;
            }
        }
    `;

    return (
        <StyledBackground>
            <img src={background} alt="Background"/>
        </StyledBackground>
    )
}

export default BeforeMap;
