import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import CarouselHistory from '../../../UI/Carousel/CarouselHistory';

const LineItem = ({image, line, text, top, right, width, textBottom, topImage, whiteBrandWidth, whiteBrandHeight, brandWidth, brandHeight, carousel}) => {

    const StyledLineItem = styled.div`
    width: 339.3px;
    height: 228px;
    position: absolute;
    left: ${({right}) => right ? 'none' : '0'};
    top: ${top};
    right: ${({right}) => right ? '0' : 'none'};

    `;

    const ImgWrapper = styled.div`
    height: 192px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:hover .test {
        opacity: 0;
        transition: .2s;
    }
    img {
        width: 100%;
    }
    `;

    const BrandWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${whiteBrandWidth};
    height: ${whiteBrandHeight};
    background-color: white;
    border-radius: 39.5px;
    transition: .2s;
    
    .img-container {
        width: ${brandWidth};
        height: ${brandHeight};
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
        }
    }
    `;
    const Description = styled.div`
        color: #000000;
        font-size: 1.65rem;
        font-weight: 400;
        letter-spacing: 0.25px;
        line-height: 20px;
        width: ${width};
        text-align: ${({right}) => right ? 'left' : 'right'};
        position: absolute;
        bottom: ${textBottom};
        left: 0;
        transform: ${({right}) => right ? 'rotate(0deg)' : 'none'};
    `;
    return (
        <StyledLineItem right={right}>
            <ImgWrapper>
                <Fade>
                <img src={image} alt="Item"/>
                </Fade>
                
                <BrandWrapper className="test">
                    <div className="img-container">
                        {
                            !topImage ? null : <img src={topImage} alt="Brand"/>
                        }
                    </div>
                    {
                        carousel && <CarouselHistory />
                    }
                </BrandWrapper>
            
            </ImgWrapper>
            <Description right={right}>
                <Fade bottom delay={300}>
                {text}
                </Fade>
            </Description>
        </StyledLineItem>
    )
}

export default LineItem;
