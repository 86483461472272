import React from "react";
import "./section4.css";

const Section4 = () => {
  return (
    <div className="section-4">
      <div className="text">
        <hr />
        <h1>
          Promovemos una relación comercial justa entre productores y
          consumidores.
        </h1>
        <p>
          Trabajamos cuidando las condiciones laborales y con respeto al
          ambiente. Certificamos en forma voluntaria nuestra producción de{" "}
          <strong>arándanos</strong> bajo el sistema “Comercio Justo”, contando
          también con certificación en buenas prácticas agrícolas (Global Gap),
          certificación orgánica y biodinámica.
        </p>
      </div>
      <div className="background-img-1"></div>
      <div className="background-img-2"></div>
    </div>
  );
};

export default Section4;
