import React from 'react';
import styled from 'styled-components';
import cargill from '../../../assets/images/CargillLogo.png';
import syngenta from '../../../assets/images/Syngenta_Logo.png';
import monsanto from '../../../assets/images/Monsanto_logo.svg.png';
import basf from '../../../assets/images/BASF-Logo_bw.svg.png';
import nidera from '../../../assets/images/Nidera.png';
import nitragin from '../../../assets/images/Nitragin.png';
import sursem from '../../../assets/images/Sursem.png';
import LogoIFC from '../../../assets/images/LogoIFC.png';
import logosojitz from '../../../assets/images/logosojitz.png';
import SPS from '../../../assets/images/SPS.png';
import patagoniaenergia from '../../../assets/images/patagoniaenergia.png';
import lartirigoyen from '../../../assets/images/lartirigoyen.png';
import Viterra from '../../../assets/images/viterra.png';
import Logoagrositio from '../../../assets/images/Logoagrositio.png';
import bayercropscience from '../../../assets/images/bayercropscience.png';
import PUMA from '../../../assets/images/PUMA.png';
import AdvantaLogo from '../../../assets/images/AdvantaLogo.png';
import dow from '../../../assets/images/dow.png';
import LogoBlackRiver from '../../../assets/images/LogoBlackRiver.png';
import GensusLogo from '../../../assets/images/GensusLogo.png';
import cm from '../../../assets/images/cm.png';
import Reciducafundacion from '../../../assets/images/Reciducafundacion.png';
import bayacasal from '../../../assets/images/bayacasal.png';
import galicia from '../../../assets/images/galicia.png';
import GlencoreAcopio from '../../../assets/images/GlencoreAcopio.png';
import hsbc from '../../../assets/images/hsbc.png';
import jd from '../../../assets/images/jd.png';
import faid from '../../../assets/images/faid.png';
import luchetti from '../../../assets/images/lucchetti.png';
import munich from '../../../assets/images/munich.png';
import ucropit from '../../../assets/images/ucropit.svg';
import pop from '../../../assets/images/quehacemos-pop.png';
import seda from '../../../assets/images/quehacemos-seda.png';
import molinos from '../../../assets/images/quehacemos-molinos.png';
import lagomarsino from '../../../assets/images/quehacemos-lagomarsino.png';
import granosparana from '../../../assets/images/Granosparana.jpg';
import unica from '../../../assets/images/iunica.svg';

import Brand from './Brand';

const ListaEmpresas = () => {
  const ListWrapper = styled.section`
    margin: 60px 0;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    @media (max-width: 1105px) {
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  `;
  const WrapperItems = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <>
      <ListWrapper>
        <WrapperItems>
          <Brand image={AdvantaLogo} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={basf} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={bayacasal} width="70%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={bayercropscience} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={LogoBlackRiver} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={cm} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={Logoagrositio} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={cargill} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={dow} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={faid} width="50%" />
        </WrapperItems>
        <WrapperItems>
          <Brand width="70%" image={galicia} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={GensusLogo} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={Viterra} width="70%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={GlencoreAcopio} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={granosparana} width="50%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={hsbc} width="40%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={LogoIFC} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={jd} width="50%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={lagomarsino} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={lartirigoyen} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={luchetti} width="50%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={molinos} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={monsanto} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={munich} width="70%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={nidera} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={nitragin} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={patagoniaenergia} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={pop} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={PUMA} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={Reciducafundacion} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={seda} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={logosojitz} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={SPS} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={sursem} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={syngenta} />
        </WrapperItems>
        <WrapperItems>
          <Brand image={ucropit} width="70%" />
        </WrapperItems>
        <WrapperItems>
          <Brand image={unica} width="70%" />
        </WrapperItems>
      </ListWrapper>
    </>
  );
};

export default ListaEmpresas;
