import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/bg-inverti.jpg';

const Header = () => {
    const BackgroundWrapper = styled.header`
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;

    /* h2 {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        font-size: 82px;
        font-weight: 600;
        line-height: 98px;
    } */
    `;

    return (
        <BackgroundWrapper />
    )
}

export default Header;
