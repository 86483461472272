import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Oval } from '../../../../assets/images/tech-oval.svg';
import Reveal from 'react-reveal/Reveal';
import { useTranslation } from 'react-i18next';
const OrganizacionMobile = () => {
  const { t } = useTranslation();
  const Wrapper = styled.div`
    position: absolute;
    top: 10%;
    left: 0;
    color: white;
    width: 100%;
    height: 200px;
    @media (min-width: 1106px) {
      display: none;
    }
  `;

  const OvalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);

    svg {
      width: 110px;
      height: 110px;
      transform-origin: center;
      animation: 30s linear infinite;
      animation-name: rotateRight;
      transform: scale(0);

      @keyframes rotateRight {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(-1turn);
        }
      }
    }

    .line {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 1.5px;
      animation: animate 0.2s linear;
      background-color: rgba(255, 255, 255, 0.8);
      animation-fill-mode: forwards;
      @keyframes animate {
        0% {
          width: 0;
        }

        100% {
          width: 200px;
        }
      }
    }
  `;

  const StyledTitle = styled.div`
    position: absolute;
    top: 42%;
    left: 60%;
    transform: translate(-50%, -50%);
    font-size: 2.6rem;
    color: white;
    text-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.5);
  `;

  const StyledNumber = styled.div`
    position: absolute;
    bottom: 14px;
    left: 45%;
    transform: translateX(-50%);
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    span {
      color: white;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
    }
  `;
  return (
    <Wrapper>
      <OvalWrapper>
        <Oval />
        <Reveal effect="line" delay={500} />
      </OvalWrapper>
      <StyledTitle>{t('En Originación')}</StyledTitle>
      <StyledNumber>
        2.0
        <span>MM {t('HAS.')}</span>
      </StyledNumber>
      <StyledNumber style={{ left: '75%', bottom: '0', width: '125px' }}>
        1.6
        <span>
          B USD. <span>{t('Valor Producto Originado')}</span>
        </span>
      </StyledNumber>
    </Wrapper>
  );
};

export default OrganizacionMobile;
