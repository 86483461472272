import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import cargill from '../../../assets/images/CargillLogo.png';
import dow from '../../../assets/images/dow.png';
import Viterra from '../../../assets/images/viterra.png';
import SPS from '../../../assets/images/SPS.png';
import sursem from '../../../assets/images/Sursem.png';
import nidera from '../../../assets/images/Nidera.png';
import bayercropscience from '../../../assets/images/bayercropscience.png';
import advanta from '../../../assets/images/AdvantaLogo.png';
import syngenta from '../../../assets/images/Syngenta_Logo.png';
import monsanto from '../../../assets/images/Monsanto_logo.svg.png';
import basf from '../../../assets/images/BASF-Logo_bw.svg.png';
import nitragin from '../../../assets/images/Nitragin.png';
import lartirigoyen from '../../../assets/images/lartirigoyen.png';

const CarouselHistory = () => {
  const CarouselContainer = styled.div`
    max-width: 70%;
  `;

  return (
    <CarouselContainer>
      <Carousel
        interval={3000}
        infiniteLoop={true}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={true}
      >
        <div>
          <img src={dow} alt="Dow" />
        </div>
        <div>
          <img src={Viterra} alt="Viterra" />
        </div>
        <div>
          <img src={SPS} alt="SPS" />
        </div>
        <div>
          <img src={cargill} alt="Cargill" />
        </div>
        <div>
          <img src={sursem} alt="Sursem" />
        </div>
        <div>
          <img src={nidera} alt="Nidera" />
        </div>
        <div>
          <img src={bayercropscience} alt="Bayer" />
        </div>
        <div>
          <img src={advanta} alt="Advanta" />
        </div>
        <div>
          <img src={syngenta} alt="Syngenta" />
        </div>
        <div>
          <img src={monsanto} alt="Monsanto" />
        </div>
        <div>
          <img src={basf} alt="BASF" />
        </div>
        <div>
          <img src={nitragin} alt="Nitragin" />
        </div>
        <div>
          <img src={lartirigoyen} alt="Lartirigoyen" />
        </div>
      </Carousel>
    </CarouselContainer>
  );
};

export default CarouselHistory;
