import React from "react";
import styled from "styled-components";
import titleLine from "../../../assets/images/line-title.svg";
import PersonItem from "./PersonItem/PersonItem";
import SantiagoCasares from "../../../assets/images/santiago_casares_presidente-min.jpg";
import EduardoSerantes from "../../../assets/images/eduardo_serantes_director-min.jpg";
import FernandoElizalde from "../../../assets/images/Fernando_Elizalde_Director.jpg";
import LuisGonzalez from "../../../assets/images/luis_gonzalez_victorica_director.jpg";
import MarcosBotta from "../../../assets/images/marcos_botta_CEO-min.jpg";
import JoseMendivil from "../../../assets/images/Jose_R_Mendivil-min.jpg";
import JulioRanceze from "../../../assets/images/Julio_A_Ranceze-min.jpg";
import GabrielaTambussi from "../../../assets/images/gabrielatambussi.jpg";
import luisbianchi from "../../../assets/images/luisbianchi.jpg";
import andressilveyra from "../../../assets/images/andressilveyra.jpg";
import MariaVargasDuran from "../../../assets/images/MariaVargas.jpg";
import CarlaEstevez from "../../../assets/images/CarlaEstevez.jpg";
import LuisArias from "../../../assets/images/LuisArias.jpg";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const NuestroEquipo = () => {
  const { t } = useTranslation();

  const Wrapper = styled.section`
    margin: 1380px auto 140px auto;

    @media (min-width: 2560px) {
      margin-top: 2000px;
    }
    @media (max-width: 1440px) {
      margin: 850px auto 140px auto;
    }
    width: 1280px;
    max-width: 100%;
    @media (max-width: 1105px) {
      margin: 60px auto;
      padding: 20px;
    }

    h2 {
      color: #175763;
      font-size: 2.8rem;
      font-weight: 400;
      line-height: 27px;
      width: 405.42px;
      max-width: 100%;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        background-image: url(${titleLine});
        background-size: contain;
        background-repeat: no-repeat;
        width: 93px;
        height: 14px;
        bottom: -22.5px;
        left: 0;
      }
    }
  `;

  const TeamContainerDirectorio = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1105px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  `;

  const TeamContainerEjecutivo = styled.div`
    width: 100%;
    max-width: 966px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    @media (max-width: 1105px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  `;

  const SubTitle = styled.h3`
    width: 100%;
    text-align: center;
    color: #565656;
    font-size: 2rem;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    :not(:first-child) {
      margin-top: 60px;
    }
  `;

  return (
    <>
      <Wrapper>
        <Fade delay={400}>
          <h2>{t("Nuestro equipo")}</h2>
        </Fade>
        <SubTitle>{t("BOARD OF DIRECTORS")}</SubTitle>
        <TeamContainerDirectorio>
          <PersonItem
            image={JulioRanceze}
            name={`${t(`Ing.`)} Julio Ranceze`}
            charge={t("Presidente")}
            link="https://www.linkedin.com/in/julio-ranceze-6b227834/"
            title={t("Ing. en Producción Agropecuaria")}
            description={t("Dirección de los programas de Siembras.")}
          />
          <PersonItem
            image={SantiagoCasares}
            name={`${t(`Ing.`)} Santiago Casares`}
            charge={t("Director")}
            link="https://www.linkedin.com/in/santiago-casares-b50a1436/"
            title={t("Ing. en Producción Agropecuaria")}
            description={t(
              "Director y Presidente de Cazenave. Director de Gensus S.A. Responsable de Dirección de NUEVOS NEGOCIOS DE INNOVACIÓN."
            )}
          />
          <PersonItem
            image={JoseMendivil}
            name={`${t(`Ing.`)} José Mendivil`}
            charge={t("Director")}
            link="https://www.linkedin.com/company/cazenaveyasociados/"
            title={t(
              "Ing. en Producción Agropecuaria. M.Sc. en Agronegocios y Alimentos (2001)"
            )}
            description={t("Dirección de los programas de Siembras.")}
          />
          <PersonItem
            image={LuisArias}
            name={`${t(`Ing.`)} Luis Arias`}
            charge={t("Director")}
            link="https://www.linkedin.com/company/cazenaveyasociados/"
            title={
              "Ingeniero Agrónomo. Ex Presidente de ASAGIR. Responsable de zona Oeste de Bs. AS. Y Este de la Pampa."
            }
            description={t("Director.")}
          />
        </TeamContainerDirectorio>

        <SubTitle>{t("EXECUTIVE TEAM")}</SubTitle>
        <TeamContainerEjecutivo>
          <PersonItem
            image={luisbianchi}
            name={`${t(`Ing.`)} Luis Bianchi`}
            charge={t("Gerente de Comercialización y Logística")}
            link="https://www.linkedin.com/company/cazenaveyasociados/"
            description={t("Área de Comercialización y Logística.")}
          />
          <PersonItem
            image={andressilveyra}
            name={`${t(`Ing.`)} Andrés Silveyra`}
            charge={t("Gerente de Originación")}
            link="http://www.linkedin.com/pub/andres-silveyra/29/303/319"
            description={t("Área de Originación. Cereales de invierno.")}
          />
          <PersonItem
            image={GabrielaTambussi}
            name="Gabriela Tambussi"
            charge={t("Gerente de Administración y Finanzas")}
            link="https://www.linkedin.com/in/mariagabrielatambussi"
            description={t(
              "Gerente de Administración y Finanzas de Cazenave y Asociados y de Gensus."
            )}
          />
        </TeamContainerEjecutivo>

        {/* <SubTitle>
                Ejecutivo
            </SubTitle> */}
        <TeamContainerEjecutivo>
          <PersonItem
            image={MarcosBotta}
            name={`${t(`Ing.`)} Marcos Botta`}
            charge="CIO de ucrop.it"
            link="https://www.linkedin.com/in/marcos-botta-ba948630/"
            title={t("CEO de Cazenave")}
            description={t(
              "Alta Dirección Regional y Global; Managing Director en Fondos de inversión de Private Equity en toda la cadena de Agribusiness. Founder de Startups en proyectos greenfields con innovación tecnológica disruptiva."
            )}
          />
          <PersonItem
            image={FernandoElizalde}
            name={`${t(`Ing.`)} Fernando Elizalde`}
            charge={t("Argen Campos")}
            link="https://www.linkedin.com/company/cazenaveyasociados/"
            title={t("Ing. Agrónomo")}
            description={t(
              "Director de Cazenave. Asesor de Negocios inmobiliarios."
            )}
          />
          <PersonItem
            image={LuisGonzalez}
            name={`${t(`Ing.`)} Luis Gonzalez Victorica`}
            charge={t("Siembras")}
            link="https://www.linkedin.com/company/cazenaveyasociados/"
            title={t("Ing. en Producción Agropecuaria")}
            description={t(
              "Director y Ex Presidente de Cazenave. Ex Presidente de ASAGIR. Responsable del programa de SIEMBRAS."
            )}
          />
          <PersonItem
            image={EduardoSerantes}
            name={`${t(`Ing.`)}  Eduardo Serantes`}
            charge={t("Gensus, Candeal, GPS")}
            link="https://www.linkedin.com/company/cazenaveyasociados/"
            title={t("Ing. Agrónomo")}
            description={t(
              "Director y Ex Presidente de Cazenave. Director de Gensus SA. Responsable del programa de ORIGINACIÓN."
            )}
          />
        </TeamContainerEjecutivo>
        <TeamContainerEjecutivo style={{ justifyContent: "center" }}>
          <PersonItem
            image={MariaVargasDuran}
            name="María Vargas Duran"
            charge="Social Media Manager"
            link="https://www.linkedin.com/in/mar%C3%ADa-vargas-25401541/"
            description={t("Comunicación y Prensa de Cazenave.")}
            title={t("Lic. Comunicación Social")}
          />
          <PersonItem
            image={CarlaEstevez}
            name="Carla Estevez"
            charge={t("Recursos Humanos")}
            link="https://www.linkedin.com/in/carla-estevez-55343611/"
            // description="Comunicación y Prensa de Cazenave."
            title={t("Recursos Humanos")}
          />
        </TeamContainerEjecutivo>
      </Wrapper>
    </>
  );
};

export default NuestroEquipo;
