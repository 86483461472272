import React from 'react';
import styled from 'styled-components';
import {ReactComponent as TimeLine} from '../../../assets/images/Line.svg';
import Year from './Year/Year';

const Line = ({mobile}) => {

    const LineWrapper = styled.div`
        position: ${({mobile}) => mobile ? 'relative': 'absolute'};
        top: ${({mobile}) => mobile ? 'unset': '-200px'};
        left: ${({mobile}) => mobile ? 'unset': '50%'};
        transform: ${({mobile}) => mobile ? 'unset': 'translateX(-50%)'};

        svg {
            height: 5220px;
        }
        &::before {
            content: '';
            position: absolute;
            background-color: #000;
            opacity: 0.4099702380952381;
            top: -28.5px;
            left: 50%;
            transform: translateX(-50%);
            width: 46px;
            height: 1px;
        }
    `;

    return (
        <LineWrapper mobile={mobile}>
            <TimeLine />
            <Year top="238px" year="1969" outline />
            <Year top="409px" year="1976" />
            <Year top="555.56px" year="1978" outline/>
            <Year top="718.55px" year="1986"/>
            <Year top="871.54px" year="1990" outline/>
            <Year top="1026.53px" year="1994" />
            <Year top="1176.52px" year="1995" outline/>
            <Year top="1347.51px" year="1997" />
            <Year top="1512.5px" year="2001" outline />
            <Year top="1689.49px" year="2002" />
            <Year top="1902.48px" year="2003" outline/>
            <Year top="2102.49px" year="2005" />
            <Year top="2300.46px" year="2007" outline/>
            <Year top="2503.45px" year="2008" />
            <Year top="2709.44px" year="2010" outline/>
            <Year top="2870px" year="2011" />
            <Year top="3030px" year="2012" outline />
            <Year top="3200px" year="2013" />
            <Year top="3360px" year="2014" outline />
            <Year top="3530px" year="2015" />
            <Year top="3690px" year="2016" outline/>
            <Year top="3850px" year="2018" />
            <Year top="4010px" year="2019" outline/>
            <Year top="4170px" year="2019" />
            <Year top="4330px" year="2020" />
            <Year top="4490px" year="2021" end />
        </LineWrapper>
    )
}

export default Line;
