import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Spinner from "../../components/UI/Spinner/Spinner";
import Header from "../../components/Unica/Header/Header";
import ObjetivoItem from "../../components/Unica/ObjetivoItem/ObjetivoItem";
import background from "../../assets/images/unica-background-footer.jpg";
import { useTranslation } from "react-i18next";

const Unica = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
    setIsLoading(false);
  }, []);

  const Space = styled.div`
    position: relative;
    height: 372px;
    background-color: #fbfbfb;
    display: flex;
    justify-content: center;
  `;

  const ObjetivosContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 1280px;
    min-height: 239px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 28px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 1105px) {
      flex-wrap: wrap;
      min-height: 320px;
      padding: 45px;
    }
  `;

  const StyledBtn = styled.a`
    background-color: #175763;
    border: 0.77px solid #175763;
    border-radius: 39.94px;
    width: 150.64px;
    height: 46px;
    z-index: 3;
    position: absolute;
    bottom: -23px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 1.54rem;
    line-height: 22px;
    font-weight: 100;
  `;

  const StyledBackgroundFooter = styled.div`
    width: 100%;
    height: 838px;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    margin-top: -220px;
  `;
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Header />

      <Space>
        <ObjetivosContainer>
          <ObjetivoItem
            title={t("Mejorar la logística de cosecha")}
            color="#EA8C2E"
          />

          <ObjetivoItem
            title={t("Hacer un control efectivo de eficiencia de labores")}
            color="#175763"
          />

          <ObjetivoItem title={t("Fidelizar Contratistas")} color="#EA8C2E" />

          <ObjetivoItem
            title={t("Obtener el mapa de ÚNICA con un sólo click")}
            color="#175763"
          />
        </ObjetivosContainer>
        <StyledBtn
          href="https://www.agriunica.com/"
          target="_blank"
          rel="no-openner no-referrer"
        >
          {t("Ingresar")}
        </StyledBtn>
      </Space>
      <StyledBackgroundFooter />
    </>
  );
};

export default Unica;
