import React from 'react';
import styled from 'styled-components';
import InvestChart from '../../assets/images/invest-chart-2.png';

const Container = styled.div`
  margin-bottom: 5rem;
  margin-left: 4rem;
  mark {
    background-color: black; /*#175762;*/
    color: white;
  }
  img {
    width: 100%;
  }
  h1 {
    font-size: 2.6rem;
  }
  .white-text {
    color: white !important;
  }
`;

const Separator = styled.div`
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 20px;
`;

const SmallDivider = styled.div`
  border-bottom: ${({ color }) =>
    color ? `4px solid ${color}` : '4px solid black'};
  margin: 20px 90% 20px 0px;
`;

const TextContainer = styled.div`
  margin-top: 20px;
  font-size: 2.5rem;
  font-weight: bold;
  max-width: 100%;
  text-align: left !important;
  color: black; /*#175762;*/
  margin-left: 20px;
`;

const BoxesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: white;
  margin-top: 50px;
  font-weight: bold;
  font-size: 1.9rem;
`;

const BoxInfo = styled.div`
  background-color: black;
  height: 20rem;
  width: 20rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RentInfo = styled.div`
  text-align: right !important;
  margin-top: 10px;
  font-size: 1.3rem;
`;

const PesosAhorrados = () => {
  return (
    <Container>
      <Separator />
      <h1>
        <mark>¿TENÉS PESOS AHORRADOS?</mark>
      </h1>
      <Separator />
      <h1>
        <mark>¿QUERÉS INVERTIR EN UN NEGOCIO LINKEADO AL DÓLAR OFICIAL?</mark>
      </h1>
      <TextContainer>
        <SmallDivider />
        Podés invertir participando en el <br />
        Fideicomiso Cazenave Siembras (FCS) que <br />
        exclusivamente produce granos en <br />
        distintas zonas de la pradera pampeana.
        <SmallDivider />
      </TextContainer>
      <BoxesContainer>
        <BoxInfo>
          La rentabilidad* estimada para esta campaña 2023/24 es 14%.
        </BoxInfo>
        <BoxInfo>
          La rentabilidad* histórica promedio del FCS (2016-2023) 18%.
        </BoxInfo>
        <BoxInfo>La duración de la inversión 12-15 meses.</BoxInfo>
      </BoxesContainer>
      <RentInfo>
        *Rentabilidad expresada en dólares oficiales antes de IGG.
      </RentInfo>
      <Separator />
      <h1>
        <mark>RENTABILIDAD ANUAL DEL FIDEICOMISO DE SIEMBRAS</mark>
      </h1>
      <Separator />
      <img src={InvestChart} alt="InvestChart" />
      <TextContainer className="white-text">
        <SmallDivider color="white" />
        Cazenave y Asociados, una empresa de más 50 años, <br />
        profesional, innovadora y referente del sector <br />
        agroindustrial, pionera en negocios de siembras <br />
        en campos de terceros, con 1,7MM de has <br />
        sembradas desde 1978.
        <SmallDivider color="white" />
      </TextContainer>
    </Container>
  );
};

export default PesosAhorrados;
