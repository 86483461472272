import React from 'react';
import styled from 'styled-components';
import Jump from 'react-reveal/Jump';
import UnicaBackground from '../../../assets/images/background-unica.jpg';
import {ReactComponent as LineTitle} from '../../../assets/images/line-title.svg';
import unica from '../../../assets/images/iunica.svg';
import {ReactComponent as Mouse} from '../../../assets/images/mouse-scroll.svg';
import Fade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';
import Roll from 'react-reveal/Roll';
import {useTranslation} from 'react-i18next';

const Header = () => {

    const StyledHeader = styled.header`
    background-image: url(${UnicaBackground});
    background-size: cover;
    background-position-y: 25%;
    background-position-x: center;
    min-height: 100vh;
    position: relative;

    .iunica {
        width: 423.8px;
        max-width: 100%;
        height: 92.95px;
    }
    `;

    const StyledRevolution = styled.div`
    width: 693px;
    height: 700px;
    max-width: 100%;
    padding: 95px 88px;
    background-color: #175763;
    z-index: 2;
    @media (max-width: 1105px) {
        margin-top: 70px;
        padding: 20px;
        height: 438px;
    }
    h2 {
        color: #FFFFFF;
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 44px;
        width: 422px;
        max-width: 100%;
        text-align: left;
    }
    svg {
        margin: 30px 0;
    }
    p {
        color: #FFFFFF;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 33px;
        width: 522px;
        max-width: 100%;
        text-align: left;
    }
    `;

    const StyledMouse = styled.div`
    position: absolute;
    bottom: 10px;
    left: 51%;
    transform: translateX(-50%);
    z-index: 3;
    @media (max-width: 1105px) {
        display: none;
    }
    `;

    const Wrapper = styled.div`
    height: 700px;
    width: 100%;
    bottom: -132px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    display: flex;
    justify-content:center;
    @media (max-width: 1105px) {
        flex-direction: column;
        align-items: center;
    }

    `;

    const {t} = useTranslation();
    return (
        <StyledHeader>
            <Wrapper>
                <Roll left>
                <img src={unica} className="iunica" alt="Unica"/>
                </Roll>
            <StyledRevolution>
                <Fade bottom delay={800}>
                <h2>{t('La REVOLUCIÓN de la agricultura por ambientes.')}</h2>
                </Fade>
                <Rotate top left delay={500}>
                <LineTitle />
                </Rotate>
                <Fade bottom delay={1000}>
                        <p>{ t('Plataforma GIS con soluciones prácticas para monitoreos, muestreos georreferenciados, estimación de rindes, ambientación de suelos, prescripciones de siembra y fertilización variable, mapas de rinde, mpas de tosca, NDVI. Almacenamos y visualizamos toda la información técnica por campaña, lote y ambiente, de gestión agrícola y georreferenciada.')}</p>
                </Fade>
                
            </StyledRevolution>
            </Wrapper>
            
            <StyledMouse>
                <Jump delay={2000}>
                    <Mouse />
                </Jump>
            </StyledMouse>
        </StyledHeader>
    )
}

export default Header;
