import React, { useState, useEffect } from 'react';
import Header from '../../components/Ucrop/Header';
import How from '../../components/Ucrop/How';
import WhatIsIt from '../../components/Ucrop/WhatIsIt';
import Offer from '../../components/Ucrop/Offer';
import Centauros from '../../components/Ucrop/Centauros';
import Form from '../../components/Ucrop/UcropForm';
import Spinner from '../../components/UI/Spinner/Spinner';
import styled from 'styled-components';

const Ucrop = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
    setIsLoading(false);
  }, []);

  const StyledContainer = styled.div`
    background-color: #fff !important;
  `;
  // background-color: #fcfaf8 !important;

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <StyledContainer>
        <Header />
        <WhatIsIt />
        <How />
        <Offer />
        <Centauros />
        <Form />
      </StyledContainer>
    </>
  );
};

export default Ucrop;
