import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '../../components/UI/Spinner/Spinner';
import Header from '../../components/QueHacemos/Header';
import Siembra from '../../components/QueHacemos/Siembra/Siembra';
import NuestrosIngenieros from '../../components/QueHacemos/NuestrosIngenieros/NuestrosIngenieros';
import Originacion from '../../components/QueHacemos/Originacion/Originacion';
import Brands from '../../components/QueHacemos/Brands/Brands';
import BrandsSiembra from '../../components/QueHacemos/Brands/BrandsSiembra';
import NegociosInmobiliarios from '../../components/QueHacemos/NegociosInmobiliarios/NegociosInmobiliarios';
import { ReactComponent as Line } from '../../assets/images/lineBtn.svg';
import Gensus from '../../components/QueHacemos/Gensus/Gensus';
import Consultoria from '../../components/QueHacemos/Consultoria/Consultoria';
import AgriculturaSatelital from '../../components/QueHacemos/AgriculturaSatelital/AgriculturaSatelital';

const QueHacemos = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
    setIsLoading(false);
  }, []);

  const Space = styled.div`
    width: 1105px;
    max-width: 100%;
    margin: 0 auto;
    height: 98px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    .otherLine {
      transform: rotate(180deg);
      margin-left: -2px;
    }
  `;

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Header />
      <Siembra />
      <BrandsSiembra />
      <NuestrosIngenieros />
      <Originacion />
      <Brands />
      <NegociosInmobiliarios />
      <Space>
        <Line />
        <Line className="otherLine" />
      </Space>
      <Gensus />
      <Space>
        <Line />
        <Line className="otherLine" />
      </Space>
      <Consultoria />
      <Space>
        <Line />
        <Line className="otherLine" />
      </Space>
      <AgriculturaSatelital />
    </>
  );
};

export default QueHacemos;
