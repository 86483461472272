import React, { useState, useEffect } from 'react';
import Spinner from '../../components/UI/Spinner/Spinner';
import Header from '../../components/Inverti/Header';
import styled from 'styled-components';
import InvertiForm from '../../components/Inverti/InvertiForm';

const Inverti = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
    setIsLoading(false);
  }, []);

  const Space = styled.div`
    position: relative;
    background-color: white;
    width: 100%;
    height: 2300px;
  `;

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Header />
      <Space>
        <InvertiForm />
      </Space>
    </>
  );
};

export default Inverti;
