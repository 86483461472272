import React, {useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as LinkedIn} from '../../../../assets/images/linkedInIcon.svg';
import ModalPersona from '../ModalPersona/ModalPersona';

const PersonWrapper = styled.div`
margin-top: 40px;
display: flex;
flex-direction: column;
align-items: center;
width: 322px;
max-width: 100%;
height: auto;
position: relative;
&:not(:last-child)::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 31px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #dbdbdb;
    @media (max-width: 1105px) {
    display: none;
    }
}

.imgContainer {
    cursor: pointer;
    width: 150px;
    height: 150px;
    overflow: hidden;
    clip-path: circle(50% at 50% 50%);
    img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: grayscale(100%);
    }
}

.nameContainer {
    margin-top: 16px;
    color: #000000;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 32px;
    text-align: right;
    transition: .2s;
}
.charge {
    color: #8A8B8A;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 11px;
}
.linkedIn {
    margin-top: 10px;

    a {

        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;

            g{
                g{
                    rect {
                        fill: #dadada;
                        transition: .2s;
                    }
                }
            }
        }
    }
}

&:hover {
    .imgContainer {
        img {
            filter: grayscale(0);
        }
    }

    .nameContainer {
        color: #175763
    }
    .linkedIn {

        a {

            svg {
                g{
                    g{
                        rect {
                            fill: #5498CC;
                        }
                    }
                }
            }
        }
    }
}
`;

const PersonItem = ({image, name, charge, link, title, description}) => {

    const [modalOpen, setModalOpen] = useState(false);
    return (
        <>
        <PersonWrapper>
            <div className="imgContainer"  onClick={() => setModalOpen(!modalOpen)}>
                <img src={image} alt="Perfil"/>
            </div>
            <div className="nameContainer">
                {name}
            </div>
            <div className="charge">
                {charge}
            </div>
            <div className="linkedIn">
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <LinkedIn />
                </a>
            </div>
        </PersonWrapper>
        <ModalPersona opened={modalOpen} close={() => setModalOpen(!modalOpen)} name={name} image={image} charge={charge} title={title} description={description}/>
        </>
    )
}

export default PersonItem;
